<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
</head>

<div class="full-width-wrapper">
    <div class="container-fluid border-1">

        <!--
        <div class="row pt-3">
            <div class="col-md-12">
                <span class="flex justify-center soulsWedHeadingFont">Manage Users</span>
            </div>
        </div>
    
        -->
    
        <div class="row py-1 position-relative pl-8" >        
    
            <div class=" d-none d-md-block">
    
                <!-- Fixed header row -->
                <div class="row pt-4 pb-1 fixed-header">
                    <div class="col-md-3 soulsWedSubTitleFont"> Name</div>
                    <div class="col-md-2 soulsWedSubTitleFont"> Email</div>
                    <div class="col-md-2 soulsWedSubTitleFont"> Phone Code</div>
                    <div class="col-md-2 soulsWedSubTitleFont"> Phone Number</div>
                    <div class="col-md-1 soulsWedSubTitleFont"> User Type</div>
                </div>
                
                <div class="scrollable-content">
    
                    <div class="row pb-1 flex items-center" *ngFor="let user of usersList">
                        <div class="col-md-3 "> {{user.username}}</div>
                        <div class="col-md-2 "> {{user.email}}</div>
                        <div class="col-md-2 text-center"> {{user.phonecountrycode}}</div>
                        <div class="col-md-2 text-center"> {{user.phonenumber}}</div>
                        <div class="col-md-1 text-center"> {{user.usertype}}</div>
        
                        <div class="col-md-1">
                            <button class="border button-style-3 " type="button" (click)="editUser(user)" >Edit</button>
                        </div>
        
                        <div class="col-md-1 flex justify-items-center">
                            <button class="border button-style-3 " type="button" (click)="deleteUser(user)">Delete</button>
                        </div>
        
                        <br><br>
        
                    </div>
    
                </div>
    
                
    
            </div>
            
       </div>
               
    
     </div>
    
</div>
