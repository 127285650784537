import { Routes } from '@angular/router';
import { AddUserComponent } from '../app/usermgmt/add-user/add-user.component';
import { CaterersComponent } from './caterers/caterers.component';
import { ChartersComponent } from './charters/charters.component';
import { DecoratorsComponent } from './decorators/decorators.component';
import { EditvendorComponent } from './editvendor/editvendor.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { HomeComponent } from './home/home.component';
import { HotelregistrationComponent } from './hotelregistration/hotelregistration.component';
import { HotelsComponent } from './hotels/hotels.component';
import { MakeupartistsComponent } from './makeupartists/makeupartists.component';
import { ManageusersComponent } from './manageusers/manageusers.component';
import { PhotographersComponent } from './photographers/photographers.component';
import { PlannersComponent } from './planners/planners.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { RefundypolicyComponent } from './refundypolicy/refundypolicy.component';
import { RegistervendorComponent } from './registervendor/registervendor.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SakhiservicedisplayComponent } from './sakhiservicedisplay/sakhiservicedisplay.component';
import { TermsnconditionsComponent } from './termsnconditions/termsnconditions.component';
import { ToppicksComponent } from './toppicks/toppicks.component';
import { UserdeletionpolicyComponent } from './userdeletionpolicy/userdeletionpolicy.component';
import { UserfavouritesComponent } from './userfavourites/userfavourites.component';
import { EdituserComponent } from './usermgmt/edituser/edituser.component';
import { UservendorComponent } from './uservendor/uservendor.component';
import { ManagevendorcalendarComponent } from './uservendorcalendar/managevendorcalendar/managevendorcalendar.component';
import { UservendorcalendarComponent } from './uservendorcalendar/uservendorcalendar.component';
import { PaynbookComponent } from './vendordetails/paynbook/paynbook.component';
import { VendordetailsComponent } from './vendordetails/vendordetails.component';
import { VendormgmtComponent } from './vendormgmt/vendormgmt.component';
import { VenuesComponent } from './venues/venues.component';
import { VerifyemailComponent } from './verifyemail/verifyemail.component';
import { ViewreceiptComponent } from './viewreceipt/viewreceipt.component';
import { ViewtransactionsComponent } from './viewtransactions/viewtransactions.component';
 

export const routes: Routes = [

{
    path:"",
    component:HomeComponent
},

{
    path:"home",
    component:HomeComponent
},

{
    path:"vendor",
    component:VendormgmtComponent
},

{
    path:"vendordetails",
    component:VendordetailsComponent
},
{
    path:"vendordetails/:idvendor",
    component:VendordetailsComponent
},
{
    path:"registervendor",
    component:RegistervendorComponent
},
{
    path:"listhotel",
    component:HotelregistrationComponent
},
{
    path:"register",
    component:AddUserComponent
},
{
    path:"venues",
    component:VenuesComponent
},
{
    path:"hotels",
    component:HotelsComponent
}
,
{
    path:"caterers",
    component:CaterersComponent
},
{
    path:'charters',
    component:ChartersComponent
},
{
    path:'planners',
    component:PlannersComponent
},
{
    path:'decorators',
    component:DecoratorsComponent
},
{
    path:'photographers',
    component:PhotographersComponent
},
{
    path:'userfavourites',
    component:UserfavouritesComponent
}
,
{
    path:'uservendor',
    component:UservendorComponent
},
{
    path:'editvendor',
    component:EditvendorComponent
},
{
    path:'makeupartists',
    component:MakeupartistsComponent
}

,
{
    path:'manageusers',
    component:ManageusersComponent
}

,
{
    path:'edituser/:iduser',
    component:EdituserComponent
}
,
{
    path:'viewtransactions',
    component:ViewtransactionsComponent
},
{
    path:'tnc',
    component:TermsnconditionsComponent
},
{
    path:'privacypolicy',
    component:PrivacypolicyComponent
},
{
    path:'refundpolicy',
    component:RefundypolicyComponent
},
{
    path:'buddyservice',
    component:SakhiservicedisplayComponent
},
{
    path:'uservendorcalendar',
    component:UservendorcalendarComponent
},
{
    path:'managevendorcalendar',
    component:ManagevendorcalendarComponent
},
{
    path:'viewreceipt',
    component:ViewreceiptComponent
},
{
    path:'paynbook',
    component:PaynbookComponent
},
{
    path:'verify-email',
    component:VerifyemailComponent
},
{
    path:'forgot-password',
    component:ForgotpasswordComponent
}
,
{
    path:'change-password',
    component:ForgotpasswordComponent
},
{
    path:'reset-password',
    component:ResetpasswordComponent
},
{
    path:'userdeletionpolicy',
    component:UserdeletionpolicyComponent
},
{
    path:'toppicks',
    component:ToppicksComponent
}


];

