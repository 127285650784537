<div class="full-width-wrapper">
   <div class="container-fluid ">
      <app-header2></app-header2>

   
      <div class=" d-block d-md-none">
         <div class="row pt-16 pb-3">
            <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
         </div>
      </div>
   
      <div class=" d-none d-md-block">
         <div class="row pt-28 pl-5 pb-3">
            <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
         </div>
      </div>
   
      <div class="row">
       
         <div class="col-md-12 pt-3 pb-4">
             <span class="flex justify-center soulsWedHeadingFont">Request password reset link</span>
         </div>
         <div class="col-md-1">
         </div>
     </div>
   
   
      <div class="row pl-10 pt-10">
              <div class="col-md-2 soulsWedNormalFont">
                   <label >Please enter your registered email : </label>
               </div>
               <div class="col-md-2">
                   <input type="email" id="email"  [(ngModel)]="email" class="form-control" maxlength="99">
               </div>
     
      </div>
   
      <div class="row  pl-10 pt-10">
           <div class="col-md-2 soulsWedNormalFont">
           </div>
           <div class="col-md-2">
               <button class="button-style-2" (click)="sendResetEmail()">Submit</button>
            </div>
   
       </div>
    
      <div class="row pt-10 ml-10 pb-10">
           <div class="col-md-4"></div>
           <div class="col-md-4">
               <h3>{{message}}</h3>
           </div>
   
           
      </div>
      
   
   
            
       <app-footersection></app-footersection>
   
</div></div>
