import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ExchangeRateData, RazorpayErrorResponse, RazorpayPaymentResponse } from '../../models/datamodels';
import { EnvironmentDataServiceService } from '../../services/environment-data-service.service';
import { MasterdataserviceService } from '../../services/masterdataservice.service';
import { VendorDataServiceService } from '../../services/vendor-data-service.service';
import { GobackComponent } from '../goback/goback.component';
import { SignInComponent } from '../usermgmt/sign-in/sign-in.component';

declare var Razorpay:any;



@Component({
  selector: 'app-sakhiservice',
  standalone: true,
  imports: [MatCardModule,CommonModule,DecimalPipe,SignInComponent,GobackComponent],
  templateUrl: './sakhiservice.component.html',
  styleUrl: './sakhiservice.component.css',
  providers: [DecimalPipe]
})



export class SakhiserviceComponent implements OnInit
{

  @ViewChild('payPalRef1',{static:true})payPalRef1!:ElementRef;
  @ViewChild('payPalRef2',{static:true})payPalRef2!:ElementRef;
  @ViewChild('payPalRef3',{static:true})payPalRef3!:ElementRef;
  @ViewChild('payPalRef11',{static:true})payPalRef11!:ElementRef;
  @ViewChild('payPalRef22',{static:true})payPalRef22!:ElementRef;
  @ViewChild('payPalRef33',{static:true})payPalRef33!:ElementRef;

  titleText:string='Buddy Service';
   idvendor:number=0;
  iduser:number=0;
  vendorname:string='';
  username:string='';
  useremail:string='';
  transactiondate:string='';
  finalamount:number=0;
  isOnPage:boolean=false;

  defaultCurrency:string='INR';
  svc1OriginalPrice:number=399;
  //svc1DiscountPrice:number=249;
  svc1DiscountPrice:number=33;
  svc2OriginalPrice:number=999;
  svc2DiscountPrice:number=492;
  svc3OriginalPrice:number=4999;
  svc3DiscountPrice:number=2499;

  showPayment1:boolean=false;
  showPayment2:boolean=false;
  showPayment3:boolean=false;

  currency:string|null=null;

  fxList: ExchangeRateData[] = [];



  constructor(private masterDataService:MasterdataserviceService,private router:Router,public dialog: MatDialog,private vendorDataService:VendorDataServiceService,private decimalPipe: DecimalPipe,private environmentDataService:EnvironmentDataServiceService)
  {}

  ngOnInit(): void 
  {
      this.environmentDataService.currentCurrency.subscribe(data=>
      {
        this.currency=data?.currencycode!;

        this.masterDataService.getExchangeRates().subscribe(data => 
        {
            this.fxList = data;
            console.log('Exchange rates fetched:', this.fxList);
        });

      })

      



  }

  convertCurrency(fromCurrency:string,toCurrency:string,amount:number):number
   {
     // console.log('Currency conversion from ' ,fromCurrency ,' to ',toCurrency);

     if(fromCurrency==toCurrency)
        return parseFloat(amount.toFixed(2))

        const existingRatetoUSD = this.fxList.find(rate =>
          rate.fromCurrency == fromCurrency && rate.toCurrency == 'USD');


      //    console.log('existingRatetoUSD ' ,existingRatetoUSD , '. Rate :',existingRatetoUSD!.rate);
          

        if (existingRatetoUSD) 
        {
          const existingRatefromUSD = this.fxList.find(rate =>
            rate.fromCurrency == 'USD' && rate.toCurrency == toCurrency);

        //    console.log('existingRatefromUSD ' ,existingRatefromUSD , '. Rate :',existingRatefromUSD!.rate);

            if(existingRatefromUSD)
            {
              var convamt = amount*existingRatetoUSD.rate*existingRatefromUSD?.rate!;
              return parseFloat(convamt.toFixed(2));
              
            }
            else
            return parseFloat(amount.toFixed(2));
        
        } 
        else 
        {
            return parseFloat(amount.toFixed(2));
            
        }
   }

  makePayment(amount:number)
  {

      this.environmentDataService.currentUser.subscribe(user=>
        {
          if (user)
          {
              this.iduser=user.iduser;
              this.username=user.username;
              this.useremail=user.email;

              this.isOnPage=true;

              this.invokeRazorPay(amount);
          }
          else
          {
            if(!this.isOnPage)
            {
               //alert('Log in dialog invoked from ngoninit');
              //-------- If not logged in, ask for user to log-in ------------------
              const dialogRef = this.dialog.open(SignInComponent, 
              {
                width: '300px'
              });
          
              dialogRef.afterClosed().subscribe(result => 
              {
                this.router.navigate(['/home']);
                console.log('The dialog was closed');
              });

            }
            
          }
        })
          
    }

   
    invokeRazorPay(amount:number)
    {

      if(amount>0)
      {
  
            this.finalamount=amount*100*1.18;
            this.formatNumber();
            this.transactiondate=new Date().toISOString();
            
  

            const successCallBack = (paymentId: RazorpayPaymentResponse)=>
            {
              console.log('Payment successful with payment id :',paymentId.razorpay_payment_id);

              this.vendorDataService.postTransaction(
                {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.finalamount/100,vendorname:'Buddy Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:this.currency!,bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.finalamount/100,balanceamount:0,balanceduedate:this.transactiondate
                    
                }).subscribe(data=>
                {
                  console.log('Response from post transaction :',data[0]);

                  if(data[0].idtransaction>0)
                  {
                         //---------Change Transaction ----------------------
                          this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                          {
                              this.viewReceipt();

                            })

                   }
                  
                })
              
            }


            //----------------- New code ---------------------

            this.vendorDataService.createOrder(this.finalamount,this.currency!).subscribe(order=>
              {
                    const RazorpayOptions=
                    {
                        description: "Amazing Halls Payment",
                        currency:this.currency,
                        amount:this.finalamount,
                        name:'Amazing Halls',
                        key:'rzp_test_cpXOU6uqzQHCc6',   
                        //key:'rzp_live_Zcsg7ssYPTRdjB' ,// live key
                        image:'../../../assets/images/Logo/Faveicon.png',
                        order_id: order.id,
               
                        prefill:
                        {
                            name:this.username,
                            email:this.useremail
                          
                        },
                        theme:
                        {
                            color:'#ebe834'   //#f37254'
                        },
                        handler: (response: RazorpayPaymentResponse) => 
                        {
                            console.log('Payment success event:', response);
  
                            const paymentData = 
                            {
                              razorpay_order_id: response.razorpay_order_id,
                              razorpay_payment_id: response.razorpay_payment_id,
                              razorpay_signature: response.razorpay_signature,
                            };
  
                            this.vendorDataService.verifyPayment(paymentData).subscribe(
                              (verificationResponse) => 
                              {
                                if (verificationResponse.status === 'success') 
                                {
                                  alert('Payment successful');
                                  successCallBack(response);
                                } 
                                else 
                                {
                                  alert('Payment verification failed');
                                }
  
                              });
                            
                        },
                    
                        modal:
                        {
                            ondismiss:()=>
                            {
                                console.log('dismissed')
                            }
                        }
                    }
  
                    //------------ Define failure call back -----------------------
                    const failureCallBack = (error: RazorpayErrorResponse) => 
                    {
                          console.log('Payment failed');
                         // console.log(error.error);
                    };
  
                    ///Razorpay.open(RazorpayOptions,successCallBack,failureCallBack);
                    console.log('Called Razor pay for payment ....')
    
                    const rzp = new Razorpay(RazorpayOptions);
                    rzp.on('payment.error', (response: RazorpayErrorResponse) => 
                    {
                        console.log('Payment error event:', response);
                        failureCallBack(response);
                    });
    
                    //------ Open razor pay modal ---------------------------
                    rzp.open();
  
  
  
                })

            
          
        }
        else
        {
          alert('No amount to pay')
        }
    }


    formatNumber() 
    {
      this.finalamount = parseFloat(this.decimalPipe.transform(this.finalamount, '1.0-0')!.replace(/,/g, ''));
    }

    viewReceipt() 
   {
    this.router.navigate(['/viewreceipt']).then(() => 
    {
      window.location.reload();
      //this.cdr.detectChanges();
    });
  }

  enablePayment1()
  {

    this.environmentDataService.currentUser.subscribe(user=>
      {
        if (user)
        {
            this.iduser=user.iduser;
            this.username=user.username;
            this.useremail=user.email;

            this.isOnPage=true;

            window.paypal.Buttons(
              {
                style:
                {
                  layout:'horizontal',
                  color :'blue',
                  shape:'rect',
                  label:'paypal',
                  tagline:false
                },
                createOrder:(data:any,actions:any)=>
                {
                  return actions.order.create(
                            {
                              purchase_units:
                              [
                                {
                                  amount:
                                  {
                                    value:this.convertCurrency(this.defaultCurrency,'USD',this.svc1DiscountPrice).toString(),
                                    currency_code:'USD'
                                  }
                                }
              
                              ]
                            }
                          )
                          
                },
                onApprove:(data:any,actions:any)=>
                {
                    return actions.order.capture().then((details:any)=>
                    {
                      console.log(details);
                      if(details.status==='COMPLETED')
                      {
                          console.log('Transaction id :',details.id);
                          console.log('Paypal Payment successful with payment id :',details.id);
                          this.transactiondate=new Date().toISOString();

                          this.vendorDataService.postTransaction(
                          {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.convertCurrency(this.defaultCurrency,'USD',this.svc1DiscountPrice),vendorname:'Buddy Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:'USD',bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.convertCurrency(this.defaultCurrency,'USD',this.svc1DiscountPrice),balanceamount:0,balanceduedate:this.transactiondate
                    
                          }).subscribe(data=>
                          {
                              console.log('Response from post transaction :',data[0]);

                              if(data[0].idtransaction>0)
                              {
                                    //---------Change Transaction ----------------------
                                    this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                                    {
                                        this.viewReceipt();

                                    })

                                }
                  
                        })
                      }
                      else
                      {
                        console.log(details);
                        alert('Payment failed. Please try a different mode of payment');
                      }
                    }
                  )
                },
                onError:(error:any)=>
                {
                  console.log(error);
                  alert('Payment failed. Please try a different mode of payment');
                }
        
              }
            ).render(this.payPalRef1.nativeElement);


            //------FOR SMALL SCREENS ------------------------
            window.paypal.Buttons(
              {
                style:
                {
                  layout:'horizontal',
                  color :'blue',
                  shape:'rect',
                  label:'paypal',
                  tagline:false
                },
                createOrder:(data:any,actions:any)=>
                {
                  return actions.order.create(
                            {
                              purchase_units:
                              [
                                {
                                  amount:
                                  {
                                    value:this.convertCurrency(this.defaultCurrency,'USD',this.svc1DiscountPrice).toString(),
                                    currency_code:'USD'
                                  }
                                }
              
                              ]
                            }
                          )
                          
                },
                onApprove:(data:any,actions:any)=>
                {
                    return actions.order.capture().then((details:any)=>
                    {
                      console.log(details);
                      if(details.status==='COMPLETED')
                      {
                          console.log('Transaction id :',details.id);
                          console.log('Paypal Payment successful with payment id :',details.id);
                          this.transactiondate=new Date().toISOString();

                          this.vendorDataService.postTransaction(
                          {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.convertCurrency(this.defaultCurrency,'USD',this.svc1DiscountPrice),vendorname:'Buddy Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:'USD',bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.convertCurrency(this.defaultCurrency,'USD',this.svc1DiscountPrice),balanceamount:0,balanceduedate:this.transactiondate
                    
                          }).subscribe(data=>
                          {
                              console.log('Response from post transaction :',data[0]);

                              if(data[0].idtransaction>0)
                              {
                                    //---------Change Transaction ----------------------
                                    this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                                    {
                                        this.viewReceipt();

                                    })

                                }
                  
                        })
                      }
                      else
                      {
                        console.log(details);
                        alert('Payment failed. Please try a different mode of payment');
                      }
                    }
                  )
                },
                onError:(error:any)=>
                {
                  console.log(error);
                  alert('Payment failed. Please try a different mode of payment');
                }
        
              }
            ).render(this.payPalRef11.nativeElement);

            this.showPayment1=true;

        }
        else
        {
          if(!this.isOnPage)
          {
             //alert('Log in dialog invoked from ngoninit');
            //-------- If not logged in, ask for user to log-in ------------------
            const dialogRef = this.dialog.open(SignInComponent, 
            {
              width: '300px'
            });
        
            dialogRef.afterClosed().subscribe(result => 
            {
              this.router.navigate(['/home']);
              console.log('The dialog was closed');
            });

          }
          
        }
      })

      
    

    
  }

  enablePayment2()
  {

    this.environmentDataService.currentUser.subscribe(user=>
      {
        if (user)
        {
            this.iduser=user.iduser;
            this.username=user.username;
            this.useremail=user.email;

            this.isOnPage=true;

            window.paypal.Buttons(
              {
                style:
                {
                  layout:'horizontal',
                  color :'blue',
                  shape:'rect',
                  label:'paypal',
                  tagline:false
                },
                createOrder:(data:any,actions:any)=>
                {
                  return actions.order.create(
                            {
                              purchase_units:
                              [
                                {
                                  amount:
                                  {
                                    value:this.convertCurrency(this.defaultCurrency,'USD',this.svc2DiscountPrice).toString(),
                                    currency_code:'USD'
                                  }
                                }
              
                              ]
                            }
                          )
                          
                },
                onApprove:(data:any,actions:any)=>
                {
                    return actions.order.capture().then((details:any)=>
                    {
                      console.log(details);
                      if(details.status==='COMPLETED')
                      {
                          console.log('Transaction id :',details.id);
                          console.log('Paypal Payment successful with payment id :',details.id);
                          this.transactiondate=new Date().toISOString();
                          
                          this.vendorDataService.postTransaction(
                          {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.convertCurrency(this.defaultCurrency,'USD',this.svc2DiscountPrice),vendorname:'Buddy Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:'USD',bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.convertCurrency(this.defaultCurrency,'USD',this.svc2DiscountPrice),balanceamount:0,balanceduedate:this.transactiondate
                    
                          }).subscribe(data=>
                          {
                              console.log('Response from post transaction :',data[0]);

                              if(data[0].idtransaction>0)
                              {
                                    //---------Change Transaction ----------------------
                                    this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                                    {
                                        this.viewReceipt();

                                    })

                                }
                  
                        })
                      }
                      else
                      {
                        console.log(details);
                        alert('Payment failed. Please try a different mode of payment');
                      }
                    }
                  )
                },
                onError:(error:any)=>
                {
                  console.log(error);
                  alert('Payment failed. Please try a different mode of payment');
                }
        
              }
            ).render(this.payPalRef2.nativeElement);

            window.paypal.Buttons(
              {
                style:
                {
                  layout:'horizontal',
                  color :'blue',
                  shape:'rect',
                  label:'paypal',
                  tagline:false
                },
                createOrder:(data:any,actions:any)=>
                {
                  return actions.order.create(
                            {
                              purchase_units:
                              [
                                {
                                  amount:
                                  {
                                    value:this.convertCurrency(this.defaultCurrency,'USD',this.svc2DiscountPrice).toString(),
                                    currency_code:'USD'
                                  }
                                }
              
                              ]
                            }
                          )
                          
                },
                onApprove:(data:any,actions:any)=>
                {
                    return actions.order.capture().then((details:any)=>
                    {
                      console.log(details);
                      if(details.status==='COMPLETED')
                      {
                          console.log('Transaction id :',details.id);
                          console.log('Paypal Payment successful with payment id :',details.id);
                          this.transactiondate=new Date().toISOString();
                          
                          this.vendorDataService.postTransaction(
                          {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.convertCurrency(this.defaultCurrency,'USD',this.svc2DiscountPrice),vendorname:'Buddy Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:'USD',bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.convertCurrency(this.defaultCurrency,'USD',this.svc2DiscountPrice),balanceamount:0,balanceduedate:this.transactiondate
                    
                          }).subscribe(data=>
                          {
                              console.log('Response from post transaction :',data[0]);

                              if(data[0].idtransaction>0)
                              {
                                    //---------Change Transaction ----------------------
                                    this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                                    {
                                        this.viewReceipt();

                                    })

                                }
                  
                        })
                      }
                      else
                      {
                        console.log(details);
                        alert('Payment failed. Please try a different mode of payment');
                      }
                    }
                  )
                },
                onError:(error:any)=>
                {
                  console.log(error);
                  alert('Payment failed. Please try a different mode of payment');
                }
        
              }
            ).render(this.payPalRef22.nativeElement);

            this.showPayment2=true;

        }
        else
        {
          if(!this.isOnPage)
          {
             //alert('Log in dialog invoked from ngoninit');
            //-------- If not logged in, ask for user to log-in ------------------
            const dialogRef = this.dialog.open(SignInComponent, 
            {
              width: '300px'
            });
        
            dialogRef.afterClosed().subscribe(result => 
            {
              this.router.navigate(['/home']);
              console.log('The dialog was closed');
            });

          }
          
        }
      })

      
    

    
  }

  enablePayment3()
  {

    this.environmentDataService.currentUser.subscribe(user=>
      {
        if (user)
        {
            this.iduser=user.iduser;
            this.username=user.username;
            this.useremail=user.email;

            this.isOnPage=true;

            window.paypal.Buttons(
              {
                style:
                {
                  layout:'horizontal',
                  color :'blue',
                  shape:'rect',
                  label:'paypal',
                  tagline:false
                },
                createOrder:(data:any,actions:any)=>
                {
                  return actions.order.create(
                            {
                              purchase_units:
                              [
                                {
                                  amount:
                                  {
                                    value:this.convertCurrency(this.defaultCurrency,'USD',this.svc3DiscountPrice).toString(),
                                    currency_code:'USD'
                                  }
                                }
              
                              ]
                            }
                          )
                          
                },
                onApprove:(data:any,actions:any)=>
                {
                    return actions.order.capture().then((details:any)=>
                    {
                      console.log(details);
                      if(details.status==='COMPLETED')
                      {
                          console.log('Transaction id :',details.id);
                          console.log('Paypal Payment successful with payment id :',details.id);
                          this.transactiondate=new Date().toISOString();
                          
                          this.vendorDataService.postTransaction(
                          {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.convertCurrency(this.defaultCurrency,'USD',this.svc3DiscountPrice),vendorname:'Buddy Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:'USD',bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.convertCurrency(this.defaultCurrency,'USD',this.svc3DiscountPrice),balanceamount:0,balanceduedate:this.transactiondate
                    
                          }).subscribe(data=>
                          {
                              console.log('Response from post transaction :',data[0]);

                              if(data[0].idtransaction>0)
                              {
                                    //---------Change Transaction ----------------------
                                    this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                                    {
                                        this.viewReceipt();

                                    })

                                }
                  
                        })
                      }
                      else
                      {
                        console.log(details);
                        alert('Payment failed. Please try a different mode of payment');
                      }
                    }
                  )
                },
                onError:(error:any)=>
                {
                  console.log(error);
                  alert('Payment failed. Please try a different mode of payment');
                }
        
              }
            ).render(this.payPalRef3.nativeElement);

            window.paypal.Buttons(
              {
                style:
                {
                  layout:'horizontal',
                  color :'blue',
                  shape:'rect',
                  label:'paypal',
                  tagline:false
                },
                createOrder:(data:any,actions:any)=>
                {
                  return actions.order.create(
                            {
                              purchase_units:
                              [
                                {
                                  amount:
                                  {
                                    value:this.convertCurrency(this.defaultCurrency,'USD',this.svc3DiscountPrice).toString(),
                                    currency_code:'USD'
                                  }
                                }
              
                              ]
                            }
                          )
                          
                },
                onApprove:(data:any,actions:any)=>
                {
                    return actions.order.capture().then((details:any)=>
                    {
                      console.log(details);
                      if(details.status==='COMPLETED')
                      {
                          console.log('Transaction id :',details.id);
                          console.log('Paypal Payment successful with payment id :',details.id);
                          this.transactiondate=new Date().toISOString();
                          
                          this.vendorDataService.postTransaction(
                          {idtransaction:0,iduser:this.iduser,idvendor:this.idvendor,amount:this.convertCurrency(this.defaultCurrency,'USD',this.svc3DiscountPrice),vendorname:'Buddy Service',username:this.username,useremail:this.useremail,transactiondate:this.transactiondate,currency:'USD',bookingstart:this.transactiondate,bookingend:this.transactiondate,paidamount:this.convertCurrency(this.defaultCurrency,'USD',this.svc3DiscountPrice),balanceamount:0,balanceduedate:this.transactiondate
                    
                          }).subscribe(data=>
                          {
                              console.log('Response from post transaction :',data[0]);

                              if(data[0].idtransaction>0)
                              {
                                    //---------Change Transaction ----------------------
                                    this.vendorDataService.changeTransactionData(data[0]).subscribe(ts=>
                                    {
                                        this.viewReceipt();

                                    })

                                }
                  
                        })
                      }
                      else
                      {
                        console.log(details);
                        alert('Payment failed. Please try a different mode of payment');
                      }
                    }
                  )
                },
                onError:(error:any)=>
                {
                  console.log(error);
                  alert('Payment failed. Please try a different mode of payment');
                }
        
              }
            ).render(this.payPalRef33.nativeElement);

            this.showPayment3=true;

        }
        else
        {
          if(!this.isOnPage)
          {
             //alert('Log in dialog invoked from ngoninit');
            //-------- If not logged in, ask for user to log-in ------------------
            const dialogRef = this.dialog.open(SignInComponent, 
            {
              width: '300px'
            });
        
            dialogRef.afterClosed().subscribe(result => 
            {
              this.router.navigate(['/home']);
              console.log('The dialog was closed');
            });

          }
          
        }
      })

      
    

    
  }


}
