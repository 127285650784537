<div class="full-width-wrapper">
    <div class="container-fluid ">

<!-- For smaller screens -->

<div class="d-block d-md-none container-fluid  pt-16 flex">

    <div class="row">
        <div class="col-12">
            <app-header2></app-header2>
         </div>
    </div>

    <!--

    <div class="row">
        <div class="col-12">
           <img mat-card-image src="../../assets/images/PageImages/planners.jpg">
        </div>
     </div>
-->

<div class="d-block d-md-none">
    <div class="row pb-1">
       <video mat-card-image autoplay muted class="custom-video-mobile">
          <source src="../../assets/videos/PageVideos/decorators.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
    </div>
 </div>


    <div class="row">
       <div class="col-12">
            <app-vendorsummarydisplay></app-vendorsummarydisplay>
        </div>
    </div>

     <div class="row pt-16">
        <div class="col-12">
            <app-footersection></app-footersection>
        </div>
      </div>

</div>


<!-- For larger screens -->
<div class="d-none d-md-block container-fluid flex">
    
    <div class="row">
        <div class="col-md-12">
            <app-header2></app-header2>
         </div>
    </div>

     <!--
     <div class=" d-none d-md-block">
        <div class="row pb-3">
           <img mat-card-image src="../../assets/images/PageImages/planners.jpg">
        </div>
     </div>
-->

    <div class="d-none d-md-block">
        <div class="row pb-3">
            <video mat-card-image autoplay muted class="custom-video">
                 <source src="../../assets/videos/PageVideos/decorators.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>

    <div class="row">

        <div class="col-md-2">
            <app-listing-sidebar></app-listing-sidebar>
        </div>

           <div class="col-md-9 pl-4 pt-3">
                <div class="row">
                    <div class="col-md-12 py-2">
                        <app-vendorsummarydisplay></app-vendorsummarydisplay>
                    </div>
               </div>
            <div>
                
        </div>

    </div>         
        
    <div class="row flex">
        <div class="col-md-12 pt-10">
            <app-footersection></app-footersection>
        </div>
    </div>

</div>



    







 </div></div>
