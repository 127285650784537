<div class="container">
    <div class="card" (click)="goToDecorators()">
        <!-- <div class="tag">Great Offers !!!</div> -->
        <h3>Let's bring your vision to life</h3>
        <img src="../../../assets/images/newdesign/decorators.jpg" alt="Wedding Decoration">
        <button class="button" (click)="goToDecorators()">Explore Decorators →</button>
        
    </div>
    <div class="card" (click)="goToVenues()">
        <!-- <div class="tag">Great Offers !!!</div> -->
        <h3>Transforming Spaces with Elegance</h3>
        <img src="../../../assets/images/newdesign/venues.png" alt="Wedding Venue">
       
        <button class="button" (click)="goToVenues()">Explore Venues →</button>
    </div>
    <div class="card"  (click)="goToSakhi()">
        <!-- <div class="tag">Great Offers !!!</div> -->
        <h3>End-to-End Event Management</h3>
        <img src="../../../assets/images/newdesign/buddy.jpg" alt="Wedding Couple">
        <button class="button" (click)="goToSakhi()">Explore Buddy →</button>
    </div>
</div>
