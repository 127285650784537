<div class="full-width-wrapper">
    <div class="container-fluid ">
        <app-header2></app-header2>

        <div class=" d-block d-md-none">
            <div class="row pt-16 pb-1">
                <img mat-card-image src="../../assets/images/PageImages/decorators.jpg">
            </div>
         </div>
        
         <div class=" d-none d-md-block">
            <div class="row pb-3">
                <img mat-card-image src="../../assets/images/PageImages/decorators.jpg">
            </div>
         </div>
        
         <app-userfavouriteslisting></app-userfavouriteslisting>
        
        <app-footersection></app-footersection>
        
        
            
 </div></div>
