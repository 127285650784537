import { Component } from '@angular/core';
import { Router } from '@angular/router';


//----- import angular material -------
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-footersection',
  standalone: true,
  imports: [MatCardModule],
  templateUrl: './footersection.component.html',
  styleUrl: './footersection.component.css'
})
export class FootersectionComponent 
{
  constructor(private router:Router){}

  gotoTermsandConditions()
  {
    this.router.navigate(['/tnc']);
  }

  gotoPrivacyPolicy()
  {
    
    this.router.navigate(['/privacypolicy']);
  }

  gotoRefundPolicy()
  {
    this.router.navigate(['/refundpolicy']);
  }

  //----------Functions for menu items ------------------------

  goToVenues()
  {

     this.router.navigate(['/venues']);

  }

  goToHotels()
  {
       this.router.navigate(['/hotels']);
  }

  goToPlanners()
  {
    this.router.navigate(['/planners']);
  }

  goToCaterers()
  {
    this.router.navigate(['/caterers']);
  }


  goToCharters()
  {
    this.router.navigate(['/charters']);
  }

  goToDecorators()
  {
    this.router.navigate(['/decorators']);
  }

  goToPhotograhers()
  {
    this.router.navigate(['/photographers']);
  }

  goToMakeupArtists()
  {
    this.router.navigate(['/makeupartists']);

  }

  goToUserFavourites()
  {
    this.router.navigate(['/userfavourites']);
  }

  registerVendor()
  {
    this.router.navigate(['/registervendor']);
  }

  goToSakhiService()
  {
    this.router.navigate(['/buddyservice']);
  }

  gotoUserdeletionPolicy()
  {
    this.router.navigate(['/userdeletionpolicy']);
  }
}
