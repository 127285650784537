<div class="full-width-wrapper">
  <div class="container-fluid ">

    <a 
href="https://wa.me/919445465711?text=Hello%20there!%20I%20would%20like%20to%20inquire%20about..." 
target="_blank" 
class="floating-whatsapp" 
(mousedown)="startDrag($event, 'whatsapp')" 
(touchstart)="startDrag($event, 'whatsapp')" 
(mousemove)="drag($event, 'whatsapp')" 
(touchmove)="drag($event, 'whatsapp')" 
(mouseup)="stopDrag()" 
(touchend)="stopDrag()"
>
<i class="fab fa-whatsapp"></i>
</a>

    <!-- Email Button -->
    <a 
      href="mailto:info@amazinghalls.com" 
      class="floating-email" 
      (mousedown)="startDrag($event, 'email')" 
      (touchstart)="startDrag($event, 'email')" 
      (mousemove)="drag($event, 'email')" 
      (touchmove)="drag($event, 'email')" 
      (mouseup)="stopDrag()" 
      (touchend)="stopDrag()"
    >
      <i class="fas fa-envelope fa-2x email-icon" ></i>
    </a>
<div class="background-gradient-style" #homeContainer (visibilityChange)="handleVisibilityChange()">
  <app-header2></app-header2>
  <div class="slider-categories-background ">
    <div class="custom-padding">
      <app-imageslider></app-imageslider>
    </div>

 <!-- Large screen search button and component -->
<div class="d-none d-md-block categories-overlap-wrapper" style="position: relative; z-index: 2;">
  <div class="row" style="margin-top: 10px; justify-content: center;">
    <div class="col-auto" style="padding-right: 20px;">
      <button class="button-style-6" (click)="enableSearch()" style="padding: 10px 20px;">
        {{searchButtonText}}&nbsp;&nbsp;<i class="fas fa-search light-icon"></i>
      </button>
    </div>
    <div class="col-auto" style="padding-left: 550px;">
      <button class="button-style-6 px-4" (click)="goToToppicks()" style="padding: 10px 20px;">
        {{featuredButtonText}}&nbsp;&nbsp;<i class="fas fa-star light-icon"></i>
      </button>
    </div>
  </div>
  

  <!-- Conditionally show the desktop search component -->
  <div *ngIf="showSearch" class="pt-2">
    <app-search></app-search> <!-- Desktop search component -->
  </div>
</div>

<!-- Mobile search button and component -->
<div class="d-block d-md-none categories-overlap-wrapper-sm" style="position: relative; z-index: 2;">
  <div style="display: flex; justify-content: center; align-items: center; flex-wrap: nowrap;">
    <button class="button-style-4" style="display: inline-block;" (click)="enableSearch()">
      {{searchButtonText}}&nbsp;&nbsp;<i class="fas fa-search light-icon"></i>
    </button>

    <button class="button-style-4" style="display: inline-block; margin-left: 10px;" (click)="goToToppicks()">
      {{featuredButtonText}}&nbsp;&nbsp;<i class="fas fa-star light-icon"></i>
    </button>
  </div>

  <!-- Conditionally show the mobile search component -->
  <div *ngIf="showSearch" class="pt-8">
    <app-searchmobile></app-searchmobile> <!-- Mobile search component -->
  </div>
</div>

<!-- Venues listing for home page ----------------------->
<div class="row" style="position: relative; z-index: 1;">
  <div class="d-none d-md-block  categories-overlap-wrapper1" >
    <app-venueslistinghome></app-venueslistinghome>
  </div>

  <div class="d-block d-md-none categories-overlap-wrapper-sm pt-2">
    <app-venueslistinghome></app-venueslistinghome>
  </div>
</div>



    <!-- Desktop content -->
    <div class="row ml-24  mt-4 "></div>

    <!-- <div class="categories-overlap-wrapper"> -->
    <app-categories></app-categories>


    <!-- Photographers listing for home page ----------------------->

    <div class="row pt-28">
      <div class="d-block d-md-none negative-margin-top-search">
        <!--  <app-searchmobile></app-searchmobile> -->
        <app-photographerslistinghome></app-photographerslistinghome>
      </div>

      <div class="d-none d-md-block negative-margin-top">
        <app-photographerslistinghome></app-photographerslistinghome>
      </div>
    </div>

    <app-offers></app-offers>

    <!-- Makeup artists listing for home page ----------------------->
    <!-- <div class="row pt-28">
      <div class="d-block d-md-none negative-margin-top-search">
        <app-makeupartistslistinghome></app-makeupartistslistinghome>
      </div>

      <div class="d-none d-md-block negative-margin-top">
        <app-makeupartistslistinghome></app-makeupartistslistinghome>
      </div>
    </div> -->

    <!-- Rooms listing for home page ---------------------
<div class="row pt-28">
  <div class="d-block d-md-none negative-margin-top-search">
      <app-hotelslistinghome></app-hotelslistinghome>
    </div>
  
  <div class="d-none d-md-block negative-margin-top">
    <app-hotelslistinghome></app-hotelslistinghome>
  </div>
</div>
-->

    <!-- Charters listing for home page --------------------
  <div class="row pt-28">
    <div class="d-block d-md-none negative-margin-top-search"> 
        <app-charterslistinghome></app-charterslistinghome>
      </div>
    <div class="d-none d-md-block negative-margin-top">
      <app-charterslistinghome></app-charterslistinghome>
    </div>
  </div>

-->


    <!-- Decorators listing for home page ----------------------->

    <div class="row pt-28">
      <div class="d-block d-md-none negative-margin-top-search">
        <!--  <app-searchmobile></app-searchmobile> -->
        <app-decoratorslistinghome></app-decoratorslistinghome>
      </div>

      <div class="d-none d-md-block negative-margin-top">
        <app-decoratorslistinghome></app-decoratorslistinghome>
      </div>
    </div>


    <!--  Accordian for home page --------------------- -->

    <div class="row pt-28">
      <div class="d-block d-md-none negative-margin-top-search">

        <app-accordian></app-accordian>
      </div>

      <div class="d-none d-md-block negative-margin-top" style="margin-bottom: 60px;">
        <app-accordian></app-accordian>
      </div>
    </div>
    <!--    <app-weddingvenueslisting></app-weddingvenueslisting> -->

    <!-- Sakhi service for home page ----------------------->

    <div class="row">
      <div class="d-block d-md-none negative-margin-top-sakhi sakhibanner-container pt-28">
        <!-- <app-searchmobile></app-searchmobile> -->
        <app-sakhibanner></app-sakhibanner>
      </div>

      <div class="d-none d-md-block negative-margin-top-sakhi sakhibanner-container pt-28"
        style="margin-top: -100px; margin-bottom: 20px">
        <app-sakhibanner></app-sakhibanner>
      </div>
    </div>
  </div>

  <!--   <app-vendorslisting></app-vendorslisting> -->
  <div style="margin-top: 60px;"></div>
  <app-footersection></app-footersection>

</div>
</div>
</div>
