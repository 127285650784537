<div class="full-width-wrapper">
   <div class="container-fluid ">
      <app-header2></app-header2>

<!--

   <div class=" d-block d-md-none">
      <div class="row pt-16 pb-1">
         <img mat-card-image src="../../assets/images/PageImages/makeup.jpg">
      </div>
   </div>

   <div class=" d-none d-md-block">
      <div class="row pb-3">
         <img mat-card-image src="../../assets/images/PageImages/makeup.jpg">
      </div>
   </div>

   -->

   <div class="d-block d-md-none">
      <div class="row pt-16 pb-1">
         <video mat-card-image autoplay muted class="custom-video-mobile">
            <source src="../../assets/videos/PageVideos/makeupartists.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
      </div>
   </div>

   <div class="d-none d-md-block">
      <div class="row pt-16 pb-3">
         <video mat-card-image autoplay muted class="custom-video" playsinline>
            <source src="../../assets/videos/PageVideos/makeupartists.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
      </div>
   </div>

   <app-makeupartistslisting></app-makeupartistslisting>

   <app-footersection></app-footersection>
</div></div>
   