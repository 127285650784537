<div id="video-section" class="video-container " style="position: relative; margin-top: -30px;"> 
  <!-- Video --> 
  <video mat-card-image autoplay muted class="custom-video"> 
    <source src="../../../assets/videos/home/homepagevideo.mp4" type="video/mp4"> 
    Your browser does not support the video tag.
  </video> 
</div>



  <!-- Bottom Curve SVGs -->
  <!-- <div>
    <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg">
      <path fill="rgb(245,129,6)" fill-opacity="1" d="M0,30 C500,500 1000,0 1440,70 V380 H0 Z"></path>
    </svg>
  </div>
  
  <div>
    <svg viewBox="0 0 1440 500" preserveAspectRatio="none" class="bottom-curve-svg2">
      <path fill="#FDEFD2" fill-opacity="1" d="M0,30 C500,500 1000,0 1440,70 V380 H0 Z"></path>
    </svg>
  </div> -->

