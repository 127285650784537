// import { ApplicationConfig } from '@angular/core';
// import { provideRouter } from '@angular/router';

// import { provideHttpClient } from "@angular/common/http";
// import { provideFirebaseApp } from '@angular/fire/app';
// import { getAuth, provideAuth } from '@angular/fire/auth';
// import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
// import { CalendarUtils, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// import { initializeApp } from 'firebase/app';
// import { routes } from './app.routes';


// const firebaseConfig = {
//   apiKey: "AIzaSyCbKVmnhLS2i66l3XRNH3WlpukF7zzJcvc",
//   authDomain: "amazing-halls-7304a.firebaseapp.com",
//   projectId: "amazing-halls-7304a",
//   storageBucket: "amazing-halls-7304a.appspot.com",
//   messagingSenderId: "267636862733",
//   appId: "1:267636862733:web:3e6679c67ee541e88e8a44",
//   measurementId: "G-LTMXXF1ERE"
// };



// export const appConfig: ApplicationConfig = {
//   providers: [provideRouter(routes), 
//     {
//       provide: DateAdapter,
//       useFactory: adapterFactory
//     },
//     {
//       provide: CalendarUtils,
//       useClass: CalendarUtils
//     },
//     provideAnimationsAsync(),provideHttpClient(),provideFirebaseApp(() => initializeApp(firebaseConfig)),
//     provideAuth(() => getAuth())]
// };



import { FacebookLoginProvider, SocialAuthService, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, Injector, NgZone } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { CalendarModule, DateAdapter,CalendarDateFormatter,CalendarMomentDateFormatter,CalendarUtils,CalendarA11y } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Google and Facebook OAuth Configurations  
const GOOGLE_CLIENT_ID = '816661634576-her5f91r1ljasp53d4rd6tafr27er2b7.apps.googleusercontent.com'; // Replace with your Google OAuth Client ID  
const FACEBOOK_APP_ID = '1617750552408265';  // Replace with your Facebook App ID  

const config: SocialAuthServiceConfig = {
  autoLogin: false, // Set to true if you want to automatically log in users
  providers: [
    // {
    //   id: GoogleLoginProvider.PROVIDER_ID,
    //   provider: new GoogleLoginProvider(GOOGLE_CLIENT_ID, { oneTapEnabled: true }) // Enables Google One Tap
    // },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(FACEBOOK_APP_ID)
    }
  ]
};

export const appConfig: ApplicationConfig = { 
  providers: [
    provideRouter(routes), 
    {
      provide: DateAdapter,
      useFactory: adapterFactory
    },
    {
      provide: CalendarUtils,
      useClass: CalendarUtils
    },
    provideAnimations(),
    provideHttpClient(),
    {
      provide: SocialAuthService,
      useFactory: (ngZone: NgZone, injector: Injector) => {
        return new SocialAuthService(config, ngZone, injector);
      },
      deps: [NgZone, Injector]  // Declare dependencies
    } 
  ] 
};
