

<app-header2></app-header2>

   
<!-------------------------- For larger screens -------------->

   <div class=" d-none d-md-block">
        <div class="row pt-14 pb-3">
            <img mat-card-image src="../../assets/images/PageImages/planners.jpg">
        </div>

        <div class="row">
            <div class="col-md-1">
                <div class="row pt-1 pl-3">
                    <app-goback></app-goback>
                </div>
            </div>

            <div class="col-md-10 pt-1">
                <span class="flex justify-center soulsWedHeadingFont">{{titleText}}</span>
            </div>

            <div class="col-md-1"></div>
        </div>

        <div class ='row py-5'>
            <div class="col-md-1"></div>
    
            <div class="col-md-2">
                <label for="startDate" class="form-label soulsWedNormalFont">What are the dates for the event?</label>
            </div>
            <div class="col-md-2">
                <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="startDateString"  [min]="minStartDate" (keydown)="disableManualInput($event)"> 
            </div>
            <div class="col-md-2">
                <button class="border button-style-5" id="submitbutton" (click)="onStartDateChange()">Check Availability</button>
            </div>
        </div>

        <div class="row p-2" *ngIf="showCalendar">

            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <div class="row text-center">
                            <div class="col-md-12 ">
                            
                              <span class="soulsWedSubTitleFont">{{ viewDate | date: 'MMMM yyyy' }}</span><br>
                              <button class="button-style-5 mt-4" mwlCalendarPreviousView   [view]="view" [(viewDate)]="viewDate">Previous</button>
                              
                              <button class="button-style-5 ml-12 mt-4" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">&nbsp;&nbsp;Next&nbsp;&nbsp;</button>
                            </div>
                          </div>
        
                        
                          
                          <div class="calendar-container mt-6 ">
                            
                            <mwl-calendar-month-view
                              [viewDate]="viewDate"
                              [events]="events"
                              [activeDayIsOpen]="false"
                              (dayClicked)="dayClicked($event.day)"
                              (beforeMonthViewRender)="beforeMonthViewRender($event)"
                              >
                            </mwl-calendar-month-view>
                            <p>* Blue dots indicate current events on the dates</p>
                            
                          </div>
        
        
        
                          
        
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
            
            <div class="col-md-6">
                <span class="soulsWedSubTitleFont">{{ viewDate | date: 'dd-MMM-yyyy' }} </span>
                <br><br><p>Please select an available slot to proceed</p>
                <mwl-calendar-day-view                  
                [viewDate]="viewDate"
                 [events]="events"
                 (hourSegmentClicked)="hourSegmentClicked($event)"
                 
                 >
            </mwl-calendar-day-view>
        
            </div>
        
        </div>

        <!------- Show selection panel --------------->

        <div class="row" *ngIf="showSelectionPanel">

            <div class="row">
        
                <div class="col-md-1"></div>
        
                <div class="col-md-2 ">
                    <label for="startDate" class="form-label soulsWedNormalFont">Start Date </label>
                    <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="selectedStartDate" (change)="onStartDateChange()" [min]="minStartDate" (keydown)="disableManualInput($event)"> 
                          
                </div>
        
                <div class="col-md-2">
                    <label for="startTime" class="form-label soulsWedNormalFont">Start Time </label>
                    <input type="time" class="form-control me-2" id="startTime" [(ngModel)]="selectedStartTime" (keydown)="disableManualInput($event)">           
                </div>
            
                 <div class="col-md-2 ml-20">
                    <label for="endDate" class="form-label soulsWedNormalFont">End Date</label>
                    <input type="date" class="form-control" id="endDate" [(ngModel)]="selectedEndDate" [min]="selectedStartDate" (keydown)="disableManualInput($event)">
                
                </div>
        
                <div class="col-md-2">
                    <label for="endTime" class="form-label soulsWedNormalFont">End Time</label>
                    <input type="time" class="form-control" id="endTime" [(ngModel)]="selectedEndTime" (keydown)="disableManualInput($event)">
                </div>
        
        
            </div>
        
            <div class="row">
                <div class="col-md-1"></div>
        
                  <!-- Guests -->
             <div class="col-md-1 pt-4">
                <label for="guests" class="form-label soulsWedNormalFont">Guests</label>
                <input type="number" class="form-control text-right" id="guests" [(ngModel)]="guests" min="1">
              </div>
            
            <div class="col-md-4 pt-4 ml-10">
                <label for="description" class="form-label soulsWedNormalFont">Description</label>
                <input type="text" class="form-control" id="description" [(ngModel)]="description" maxlength="100" >
            </div>
        
            <div class="col-md-1 pt-12">
                <button class="border button-style-5 " id="submitbutton" (click)="processPayment()">Submit</button>
            </div>
        
            <div class="col-md-1 pt-12">
                <button class="border button-style-5 " id="cancelbutton" (click)="cancelSelections()">Cancel</button>
            </div>

        </div>


        <div class="row pt-14" *ngIf="showPaymentPanel">

            <div class="col-md-1"></div>
            <div class="col-md-6">
                <div class="row flex justify-center">
                    <span class="soulsWedSubTitleFont">Booking Details </span>
                </div>
    
                <div class="row pt-6">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Start Date &nbsp;&nbsp; </div>
                    <div class="col-md-9 soulsWedNormalFont flex justify-start">{{startDatetoPrint}}</div>
                </div>
    
                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> End Date &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start"> {{endDatetoPrint}}</div>
                </div>
    
                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Days &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start"> {{days}}</div>
                </div>
    
                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Hours &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start"> {{hours}}</div>
                </div>
    
                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Guests &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start"> {{guests}}</div>
                </div>
    
                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Base price &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">{{applicablecurrency}} {{baseprice | number:'1.2-2'}} &nbsp;{{pricebasis}}</div>
                </div>
    
                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Total before tax &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">{{applicablecurrency}} {{totalBeforeTax | number:'1.2-2'}} </div>
                </div>
    
                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Tax &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">{{tax}}&#37;</div>
                </div>
    
                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Total after tax &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">{{applicablecurrency}} {{totalAfterTax | number:'1.2-2'}} </div>
                </div>

                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Number of days to event &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">{{actualDaysPriortoEvent}} </div>
                </div>

                <div class="row pt-3" *ngIf="showPaymentPercentages">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Choose advance percentage &nbsp;&nbsp;</div>

                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">
                        <select [(ngModel)]="selectedPercentage" (change)="onSelectedPercentageChanged()">
                            <option *ngFor="let option of paymentpercentageoptions; let i = index" [value]="option" >
                               {{ option }}%
                            </option>
                        </select>
                    </div>
                    
                   
                </div>

                <div class="row pt-3">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Amount to pay &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">{{applicablecurrency}}{{amounttopay| number:'1.2-2'}} </div>
                </div>

                <div class="row pt-3" *ngIf="showPaymentPercentages">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> Balance amount  &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">{{applicablecurrency}}{{totalAfterTax-amounttopay| number:'1.2-2'}} </div>
                </div>

                <div class="row pt-3" *ngIf="showPaymentPercentages">
                    <div class="col-md-3 soulsWedNormalFont flex justify-start"> To be paid by  &nbsp;&nbsp;</div>
                    <div class="col-md-9 soulsWedNormalFont  flex justify-start">{{balancePaymentDuedatetoPrint}} </div>
                </div>


               
    
                <div class="row pt-6"></div>

                    <div class="col-md-1"></div>

                    <div class="col-md-3 flex justify-center">
                        <button class="border button-style-5" (click)="enablePaymentGateways()">Make payment</button>
                    </div>

                                      
                    <div class="col-md-3 pt-3 clickable" *ngIf="showPaymentGateways">
                        <img src="../../assets/images/Logo/Razorpay.png"   (click)="makePaymentwithCreateOrder()">
                    </div>

                    
                    
            </div>

            
        
       </div>
       
    

            
    </div>

    

    <div #payPalRefMain class="col-md-3 pt-3 pl-20" >                                      
    </div>
        
    </div>


 


<!-- For smaller screens -->

   <div class="d-block d-md-none">
        
        <div class="row pt-14 pb-3">
            <img mat-card-image src="../../assets/images/PageImages/planners.jpg">
        </div>

        <div class="row">
            <div class="col-12">
                    <div class="row pt-1 pl-3">
                            <app-goback></app-goback>
                    </div>
            </div>

            <div class="col-12 pt-2">
                    <span class="flex justify-center soulsWedSubheadingFont3Orange">{{titleText}}</span>
            </div>

        </div>

        <div class ="row pt-4">
               
            <div class="col-10 ml-4">
                <label for="startDate" class="form-label soulsWedNormalFont">What are the dates for the event?</label>
            </div>
            <div class="col-10  ml-4">
                <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="startDateString"  [min]="minStartDate" (keydown)="disableManualInput($event)"> 
            </div>
            <div class="col-10 pt-2 ml-4">
                <button class="border button-style-5" id="submitbutton" (click)="onStartDateChange()">Check Availability</button>
            </div>
        </div>

        <div class="row p-2" *ngIf="showCalendar">

            <div class="col-12">
                <div class="row">
                    
                    <div class="col-11 ml-2">
                        <div class="row text-center">
                            <div class="col-12 ">
                            
                              <span class="soulsWedSubTitleFont">{{ viewDate | date: 'MMMM yyyy' }}</span><br>
                              <button class="button-style-5 mt-4" mwlCalendarPreviousView   [view]="view" [(viewDate)]="viewDate">Previous</button>
                              
                              <button class="button-style-5 ml-12 mt-4" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">&nbsp;&nbsp;Next&nbsp;&nbsp;</button>
                            </div>
                          </div>
        
                        
                          
                          <div class="mt-6">
                            
                            <mwl-calendar-month-view
                              [viewDate]="viewDate"
                              [events]="events"
                              [activeDayIsOpen]="false"
                              (dayClicked)="dayClicked($event.day)"
                              (beforeMonthViewRender)="beforeMonthViewRender($event)"
                              >
                            </mwl-calendar-month-view>
                            <p>* Blue dots indicate current events on the dates</p>
                            
                          </div>
        
        
        
                          
        
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
            
            <div class="col-11 pt-4 text-center ml-2">
                <span class="soulsWedSubTitleFont">{{ viewDate | date: 'dd-MMM-yyyy' }} </span>
                <br><br><p>Please select an available slot to proceed</p>
                <mwl-calendar-day-view                  
                [viewDate]="viewDate"
                 [events]="events"
                 (hourSegmentClicked)="hourSegmentClicked($event)"
                 
                 >
            </mwl-calendar-day-view>
        
            </div>
        
        </div>

        <div class="row pt-4" *ngIf="showSelectionPanel">

            <div class="row">        
                <div class="col-7 ml-4">
                    <label for="startDate" class="form-label soulsWedNormalFont">Start Date </label>
                    <input type="date" class="form-control me-2" id="startDate" [(ngModel)]="selectedStartDate" (change)="onStartDateChange()" [min]="minStartDate" (keydown)="disableManualInput($event)"> 
                </div>
        
                <div class="col-4">
                    <label for="startTime" class="form-label soulsWedNormalFont">Start Time </label>
                    <input type="time" class="form-control me-2" id="startTime" [(ngModel)]="selectedStartTime" (keydown)="disableManualInput($event)">           
                </div>
            </div>


            <div class="row pt-4">  

                 <div class="col-7 ml-4">
                    <label for="endDate" class="form-label soulsWedNormalFont">End Date</label>
                    <input type="date" class="form-control" id="endDate" [(ngModel)]="selectedEndDate" [min]="selectedStartDate" (keydown)="disableManualInput($event)">
                
                </div>
        
                <div class="col-4">
                    <label for="endTime" class="form-label soulsWedNormalFont">End Time</label>
                    <input type="time" class="form-control" id="endTime" [(ngModel)]="selectedEndTime" (keydown)="disableManualInput($event)">
                </div>
        
            </div>
        
            <div class="row">
                <!-- Guests -->
                <div class="col-10 pt-3 ml-4">
                    <label for="guests" class="form-label soulsWedNormalFont">Guests</label>
                    <input type="number" class="form-control text-right" id="guests" [(ngModel)]="guests" min="1">
                </div>
           </div>

           <div class="row">
                <div class="col-10 pt-3 ml-4">
                    <label for="description" class="form-label soulsWedNormalFont">Description</label>
                    <input type="text" class="form-control" id="description" [(ngModel)]="description" maxlength="100" >
                </div>
            </div>

            <div class="row pt-3">
                <div class="col-5 ml-4">
                    <button class="border button-style-5 " id="submitbutton" (click)="processPayment()">Submit</button>
                </div>

                <div class="col-5">
                    <button class="border button-style-5 " id="cancelbutton" (click)="cancelSelections()">Cancel</button>
                </div>
            </div>
              
        
        </div>


        <div class="row pt-14" *ngIf="showPaymentPanel">

            
            <div class="col-10 ml-4 text-center">
                 <span class="soulsWedSubheadingFont3Orange">Booking Details </span>
            </div>
    
            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Start Date &nbsp;&nbsp; </div>
                <div class="col-7 soulsWedNormalFont flex justify-start">{{startDatetoPrint}}</div>
            </div>

            <div class="row pt-4 ">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> End Date &nbsp;&nbsp; </div>
                <div class="col-7 soulsWedNormalFont flex justify-start">{{endDatetoPrint}}</div>
            </div>

            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Days &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start">{{days}}</div>
            </div>

            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Hours &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start">{{hours}}</div>
            </div>

            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Guests &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> {{guests}}</div>
            </div>

            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Base price &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> {{applicablecurrency}} {{baseprice | number:'1.2-2'}} &nbsp;{{pricebasis}}</div>
            </div>

            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Total before tax &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> {{applicablecurrency}} {{totalBeforeTax | number:'1.2-2'}} &nbsp;{{pricebasis}}</div>
            </div>

            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Tax &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> {{tax}}&#37;</div>
            </div>

            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Total after tax &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> {{applicablecurrency}} {{totalAfterTax | number:'1.2-2'}} </div>
            </div>

            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Number of days to event &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> {{actualDaysPriortoEvent}} </div>
            </div>

            <div class="row pt-4" *ngIf="showPaymentPercentages">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Choose advance amount % &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> 
                    <select [(ngModel)]="selectedPercentage" (change)="onSelectedPercentageChanged()">
                        <option *ngFor="let option of paymentpercentageoptions; let i = index" [value]="option" >
                           {{ option }}%
                        </option>
                    </select>
                </div>
            </div>

            
            <div class="row pt-4">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Amount to pay &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> {{applicablecurrency}}{{amounttopay| number:'1.2-2'}} </div>
            </div>

            <div class="row pt-4" *ngIf="showPaymentPercentages">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start"> Balance amount &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start">{{applicablecurrency}}{{totalAfterTax-amounttopay| number:'1.2-2'}} </div>
            </div>

            <div class="row pt-4" *ngIf="showPaymentPercentages">
                <div class="col-4 ml-4 soulsWedNormalFont flex justify-start">To be paid by &nbsp;&nbsp;</div>
                <div class="col-7 soulsWedNormalFont flex justify-start"> {{balancePaymentDuedatetoPrint}} </div>
            </div>

            
            <div class="row pt-4 ">
                
                <div class="col-12 flex justify-center">
                    <button class="border button-style-5" (click)="enablePaymentGateways()">Make payment</button>
                </div>

                                  
                <div class="col-10 pt-3 ml-4 clickable" *ngIf="showPaymentGateways">
                    <img src="../../assets/images/Logo/Razorpay.png"   (click)="makePaymentwithCreateOrder()">
                </div>

                
            </div>
    
 
         </div>
    
        
         <div #payPalRefSmall class="col-7 pt-3 pl-4" >                                      
        </div>

    </div>


    

   

   

   

   <div class="row pt-1">

    <div class="col-md-1"></div>

    <div class="col-md-8 text-red-800 mb-0 pb-0 bg-orange-200">
      {{errorMessage}}
    </div>

    <div class="col-md-2"></div>

  </div>

   <div class="row pt-32">
    <app-footersection></app-footersection>
   </div>

   