import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';


@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [RouterModule], 
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'] 
})
export class CategoriesComponent {
  
  constructor(private router: Router,private envData:EnvironmentDataServiceService) {} 

  goToVenues() {
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    this.router.navigate(['/venues']); 
  }

  goToPlanners(){
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    this.router.navigate(['/planners']);
  }

  goToCharters(){
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    this.router.navigate(['/charters']);
  }

  goToPhotographers(){
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    this.router.navigate(['/photographers']);
  }

  goToDecorators(){
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    this.router.navigate(['/decorators']);
  }

  goToMakeupArtists(){
    this.envData.setSessionData('savedCountry', null);
    this.envData.setSessionData('savedCity', null);
    this.envData.setSessionData('savedVendor', null);
    this.envData.setSessionData('savedSearchQuery', null);
    this.envData.setSessionData('savedShowSearch', null);
    this.router.navigate(['/makeupartists']);
  }
}
