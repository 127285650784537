<div class="full-width-wrapper">
    <div class="container-fluid ">
        <app-header2></app-header2>


        <div class=" d-block d-md-none">
            <div class="row pt-16 pb-3">
                <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
            </div>
        </div>

        <div class=" d-none d-md-block">
            <div class="row pt-28 pl-5 pb-3">
                <img mat-card-image src="../../assets/images/PageImages/venues.jpg">
            </div>
        </div>

        <div class="row">

            <div class="col-md-12 pt-3 pb-4">
                <span class="flex justify-center soulsWedHeadingFont">Reset Password</span>
            </div>
            <div class="col-md-1">
            </div>
        </div>


        <div class="row pl-10 pt-10">




            <div class="col-md-2 soulsWedNormalFont">
                <label>Please enter your new password : </label>
            </div>


            <div class="col-md-3">
                <input [type]="showPassword ? 'text' : 'password'" id="pwd" [(ngModel)]="newPassword"
                    class="form-control" maxlength="99">
                <small id="pwdHelp" class="form-text text-muted"><i> Password must be at least 8 characters long,
                        include a number and a special character, and not include single or double quotes</i></small>
            </div>

            <div class="col-md-1">
                <button type="button" (click)="togglePasswordVisibility()"
                    class="btn btn-outline-secondary toggle-confirm-password">
                    <i [class]="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                </button>
            </div>



            <div class="col-md-2 soulsWedNormalFont ml-16">
                <label>Please re-enter the password : </label>
            </div>
            <div class="col-md-2">
                <input [type]="showConfirmPassword ? 'text' : 'password'" id="cpwd" [(ngModel)]="confirmPassword"
                    class="form-control" maxlength="99">

            </div>

            <div class="col-md-1">
                <button type="button" (click)="toggleConfirmPasswordVisibility()"
                    class="btn btn-outline-secondary toggle-confirm-password">
                    <i [class]="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                </button>
            </div>





        </div>

        <div class="row  pl-10 pt-10">
            <div class="col-md-4 soulsWedNormalFont">
            </div>
            <div class="col-md-2">
                <button class="button-style-2" (click)="resetPassword()">Submit</button>
            </div>

        </div>





        <app-footersection></app-footersection>

    </div>
</div>