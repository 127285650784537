<head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
</head>


<!-- For larger screens-->
<div class="d-none d-md-block">

    <div class="container-fluid negative-margin-top">

        <div class="row">
            <div class="col-md-1">
                <div class="row pt-3 pl-3">
                    <app-goback></app-goback>
                 </div>
            </div>
            <div class="col-md-10 pt-3">
                <span class="flex justify-center soulsWedHeadingFont">{{titleText}}</span>
            </div>
           
        </div>
    
    
        <div class="row" style="margin-top:-20px">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <span class="soulsWedSubheadingFont flex justify-center ">{{subTitleText}}</span>
            </div>
            <div class="col-md-4">
                <button class="button-style-4" (click)="enableSearch()" >{{searchButtonText}}&nbsp;&nbsp;<i class="fas fa-search light-icon"></i></button>
            </div>
        </div>


        <div class="row pt-1" *ngIf="showSearch">
            <div class="col-md-1"></div>
            <div class="col-md-10 ml-3" style="border-radius: 30px;">
                
                <div class="row py-2 pl-8" >
            
                    <!-- Country Dropdown -->
                    <div class="col-md-3">
                        <label for="country" class="form-label soulsWedSearchFont2 " style="text-align: center; display: block; width: 100%;">Country</label>
                        <select class="form-select rounded-select" id="country" [(ngModel)]="selectedCountry" (ngModelChange)="onCountrySelected($event)">
                            <option *ngFor="let country of countriesListFiltered" [ngValue]="country">{{country.countryName}}</option>
                        </select>
                    </div>
        
                    <!-- City Dropdown -->
                    <div class="col-md-2">
                        <label for="city" class="form-label soulsWedSearchFont2 " style="text-align: center; display: block; width: 100%;">City</label>
                        <select class="form-select rounded-select" id="city" [(ngModel)]="selectedCity" (ngModelChange)="onCitySelected($event)">
                            <option value=""></option>
                            <option *ngFor="let city of cityList" [ngValue]="city">{{city}}</option>
                        </select>
                    </div>
        
                    <!-- Name Dropdown -->
                    <div class="col-md-5">
                        <label for="vendorName" class="form-label soulsWedSearchFont2 " style="text-align: center; display: block; width: 100%;">Name</label>
                        <select class="form-select rounded-select" id="vendorName" [(ngModel)]="selectedVendor" (ngModelChange)="onVendorSelected($event)">
                            <option value=""></option>
                            <option *ngFor="let vendor of vendorsList" [ngValue]="vendor">{{vendor.vendorname}}</option>
                        </select>
                    </div>
        
                     <!-- Clear Filters -->
                     <div class="col-md-2">
                        <div class="pt-4 flex justify-items-start">
                            <button class="button-style-2" (click)="clearFilters()">Clear All</button>
                        </div>
                    </div>
                </div>


            </div>
            
        </div>

        <div class="row pt-2" *ngIf="showSearch">
            <div class="search-box">
                <label for="search-input" class="search-label">Search your favourite vendor</label>
                <input 
                    id="search-input"
                    type="text" 
                    [(ngModel)]="searchQuery" 
                    (input)="searchVenuesByName()" 
                />
            </div>
            
            <div class="col-md-1"></div>
        </div>

        <div class="row position-relative" style="margin-top:-20px">        

            <div class=" d-none d-md-block col-md-4" *ngFor="let vendor of vendorsList">
                <div class="flex justify-center"  >
                    <mat-card class="venue-mat-card">
                        <mat-card-content style="border: none;">
                            <img mat-card-image [src]="serverpath + vendor.imagespath" class="clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)" loading="lazy">
                            <div class="fav-icon-container">
                                <div class="col-md-6 text-right" *ngIf="!isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-notfavourite clickable" data-toggle="tooltip" data-placement="top" title="Add to favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
                                <div class="col-md-6 text-right" *ngIf="isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-favourite clickable" data-toggle="tooltip" data-placement="top" title="Remove from favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
                            </div>
                            
                            <div class="pt-2 text-left">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="soulsWedSubTitleFont clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{vendor.vendorname}}</div>
                                    </div>
                                    <div class="col-md-3 text-right">
                                        <div class="soulsWedStarFont pt-2 clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{getStars(vendor.vendorrating)}}</div>
                                    </div>
                                </div>
                               
                                <div class="row clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">
                                    <div class="col-md-12 soulsWedNormalFont pt-1 pb-1">{{vendor.city ? vendor.city + ', ' : ''}}{{vendor.countryname}}</div>
                                </div>
                               
                                <div class="row">
                                    <!-- <div class=" col-md-6 soulsWedSubheadingFont2 pt-2"><b>from {{vendor.currency}} {{vendor.startingprice | number:'1.0-0'}} {{vendor.pricebasis}}</b></div> -->
                                    <div class=" col-md-10 soulsWedSubheadingFont2 pt-2"><b>from {{selectedCurrency?.currencycode}} {{convertCurrency(vendor.currency,selectedCurrency?.currencycode!,vendor.startingprice) | number:'1.0-0'}} {{vendor.pricebasis}}</b></div>
                               </div>
                               
                                
                                                           
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
             </div>
         </div>



        
    </div>

</div>

<!--================================================================================-->

<!-- For smaller screens-->
<div class="d-block d-md-none">

    <div class="container-fluid ">

        <div class="row">
            <div class="col-12">
                <div class="row ">
                    <app-goback></app-goback>
                 </div>
            </div>
        </div>

        <div class="row pt-3">
            <div class="col-12">
                <span class="flex justify-center soulsWedHeadingFont">{{titleText}}</span>
            </div>
        </div>
    
    
        <div class="row">
            <div class="col-md-12 pt-3">
                <span class="soulsWedSubheadingFont flex justify-center ">{{subTitleText}}</span>
            </div>
        </div>

        <div class="row ml-24 pt-3">
            <div class="col-12 flex justify-center">
                <button class="button-style-4" (click)="enableSearch()" >{{searchButtonText}}&nbsp;&nbsp;<i class="fas fa-search light-icon"></i></button>
            </div>
          </div>

        <div *ngIf="showSearch"> <!-- search div-->

            <div class="row pt-1">

                <div class="col-1"></div>
               
                <div class="col-10 ml-1" style="border-radius: 30px;border-color: rgb(252,203,17);">
                    
                    <div class="row py-1">
                
                        <!-- Country Dropdown -->
                        <div class="col-12">
                            <label for="country" class="form-label soulsWedSearchFont2" style="text-align: center; display: block; width: 100%;">Country</label>
                            <select class="form-select rounded-select" id="country" [(ngModel)]="selectedCountry" (ngModelChange)="onCountrySelected($event)">
                                <option *ngFor="let country of countriesListFiltered" [ngValue]="country">{{country.countryName}}</option>
                            </select>
                        </div>
            
                        <!-- City Dropdown -->
                        <div class="col-12 pt-2">
                            <label for="city" class="form-label soulsWedSearchFont2" style="text-align: center; display: block; width: 100%;">City</label>
                            <select class="form-select rounded-select" id="city" [(ngModel)]="selectedCity" (ngModelChange)="onCitySelected($event)">
                                <option value=""></option>
                                <option *ngFor="let city of cityList" [ngValue]="city">{{city}}</option>
                            </select>
                        </div>
            
                        <!-- Name Dropdown -->
                        <div class="col-12 pt-2">
                            <label for="vendorName" class="form-label soulsWedSearchFont2" style="text-align: center; display: block; width: 100%;">Name</label>
                            <select class="form-select rounded-select" id="vendorName" [(ngModel)]="selectedVendor" (ngModelChange)="onVendorSelected($event)">
                                <option value=""></option>
                                <option *ngFor="let vendor of vendorsList" [ngValue]="vendor">{{vendor.vendorname}}</option>
                            </select>
                        </div>

                        <div class="row py-2 position-relative">
                            <div class="search-box " style="margin-left: 15px;">
                                <label for="search-input" class="search-label pb-2">Search your favourite vendor</label>
                                <input 
                                    id="search-input"
                                    type="text" 
                                    [(ngModel)]="searchQuery" 
                                    (input)="searchVenuesByName()" 
                                />
                            </div>
                        </div>
                
            
                         <!-- Clear Filters -->
                         <div class="col-12 pt-3">
                            <div class="flex justify-items-start">
                                <button class="button-style-2" (click)="clearFilters()">Clear All</button>
                            </div>
                        </div>
                    </div>
    
    
                </div>

                <div class="col-1"></div>
            </div>
        </div>
        <div class="row py-3 position-relative" >        

            <div class=" d-block d-md-none col-md-10" *ngFor="let vendor of vendorsList">
                <div class="flex justify-center" >
                    <mat-card class="venue-mat-card">
                        <mat-card-content>
                            <img mat-card-image [src]="serverpath + vendor.imagespath" class="clickable"  routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)" loading="lazy">
                            <div class="fav-icon-container">
                                <div class="col-md-6 text-right" *ngIf="!isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-notfavourite clickable" data-toggle="tooltip" data-placement="top" title="Add to favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
                                <div class="col-md-6 text-right" *ngIf="isFavourite(vendor.idvendor)"><i class="fas fa-heart heart-icon-favourite clickable" data-toggle="tooltip" data-placement="top" title="Remove from favourites" (click)="toggleFavourites(vendor.idvendor)"></i></div>
                            </div>
                            
                            <div class="pt-2 text-left">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="soulsWedSubTitleFont clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{vendor.vendorname}}</div>
                                    </div>
                                    <div class="col-md-3 text-right">
                                        <div class="soulsWedStarFont pt-2 clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{getStars(vendor.vendorrating)}}</div>
                                    </div>
                                </div>
                               
                                <div class="row">
                                    <div class="col-md-12 soulsWedNormalFont pt-1 pb-1 clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)">{{vendor.city ? vendor.city + ', ' : ''}}{{vendor.countryname}}</div>
                                </div>
                               
                                <div class="row">
                                     <div class=" col-md-10 soulsWedSubheadingFont2 pt-2 clickable" routerLink="/vendordetails"  (click)=" onVendorSelect(vendor)"><b>from {{selectedCurrency?.currencycode}} {{convertCurrency(vendor.currency,selectedCurrency?.currencycode!,vendor.startingprice) | number:'1.0-0'}} {{vendor.pricebasis}}</b></div>
                                </div>
                                                           
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
             </div>
         </div>

    </div>

</div>



        

    


    



     

    


       