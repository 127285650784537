<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

<div class="container-fluid ">
    
   

    <div  class="d-none d-md-block row">

        <div class="row">
            <div class="col-md-1">
                <div class="row pt-3 pl-3">
                    <app-goback></app-goback>
                 </div>
            </div>
            <div class="col-md-10 pt-3">
                <span class="flex justify-center soulsWedHeadingFont">{{titleText}}</span>
            </div>
            <div class="col-md-1">
            </div>
        </div>
        

        <div class="row pt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4 ">
                <span class="soulsWedSubheadingFont flex justify-center ">Amazing Halls' Buddy can help out!</span>
            </div>
            <div class="col-md-4">

                <div class="row">
                    <div class="col-md-6 text-center">
                        <a href="mailto:Help@AmazingHalls.com"><i class="fas fa-envelope fa-lg email-icon" style="color: #add7f6;"></i></a>
                        Vendors
                    </div>
                    <div class="col-md-6">
                        <a href="mailto:info@amazinghalls.com"><i class="fas fa-envelope fa-lg email-icon" style="color: #add7f6;"></i></a>
                        Customers
                    </div>
                </div>
                
            </div>
        </div>


        <div class="row py-2 position-relative" >        

            <div class=" d-none d-md-block col-md-4" >
                <div class="flex justify-center"  >
                    <mat-card class="venue-mat-card">
                        <mat-card-content>
                            <img mat-card-image src="../../assets/images/Sakhi/sakhi1.jpg" class="clickable" >
                            
                            <div class="pt-2 text-left">
    
                                <div class="row">
                                    <div class="col-md-12 soulsWedPriceFont">
                                       Get Buddy's Help with Discovery
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-md-12 pt-2">
                                       <p> Easily reserve any single category</p>
                                    </div>
                                </div>
    
                                <div class="row pt-2">
                                    <div class="col-md-12">
                                        <span class="material-icons green-tick">check</span>&nbsp;Find vendors who meet your needs in terms of location, pricing, and date availability.
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Good in a single city and category
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;45-day window for the best price negotiation.
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Continued correspondence and virtual assistance through the booking stage or
                                        maintain contact and provide virtual assistance through the booking stage.   
                                    </div>
                                </div>
    
                                <div class="row pt-2 text-center">
                                    <div class="col-md-12 pt-4 soulsWedPriceFont">
                                        
                                       <b> from {{currency}} <s>{{convertCurrency(defaultCurrency,currency!,svc1OriginalPrice)|number:'1.2-2'}}</s> &nbsp;{{convertCurrency(defaultCurrency,currency!,svc1DiscountPrice)|number:'1.2-2'}} onwards</b>
    
                                    </div>

                                    
                                    <div class="col-md-12 pt-4">
                                        <button class="button-style-2" (click)="enablePayment1()">Pay and book</button>
                                    </div> 


                                   
                                        <div class="col-md-6 pt-3 clickable" *ngIf="showPayment1">
                                            <img src="../../assets/images/Logo/Razorpay.png"   (click)="makePayment(convertCurrency(defaultCurrency,currency!,svc1DiscountPrice))">
                                        </div>
    
                                        <div #payPalRef1 class="col-md-6 pt-3" >                                      
                                        </div>
                                   
                                   
                                    

                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
             </div>
    
             <div class=" d-none d-md-block col-md-4" >
                <div class="flex justify-center"  >
                    <mat-card class="venue-mat-card">
                        <mat-card-content>
                            <img mat-card-image src="../../assets/images/Sakhi/sakhi2.jpg" class="clickable" >
                            
                            <div class="pt-2 text-left">
    
                                <div class="row">
                                    <div class="col-md-12 soulsWedPriceFont">
                                       Buddy's Reservation Support                                   
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-md-12 pt-2">
                                       <p> Assistance in three primary areas</p>
                                    </div>
                                </div>
    
                                <div class="row pt-2">
                                    <div class="col-md-12">
                                        <span class="material-icons green-tick">check</span>&nbsp;Obtain vendor recommendations that fit your location, budget, and available dates and bids.
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Good for two cities for one category OR three categories in one city.
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;One video conference to seek support
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Greatest bargaining position
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;20% off invitations via video
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Continued correspondence and virtual assistance through the booking stage or Maintain contact and provide virtual assistance through the booking stage.
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Good for 60 days  
                                    </div>
                                </div>
    
                                <div class="row pt-2 text-center">
                                    <div class="col-md-12 pt-4 soulsWedPriceFont">
                                       <b> from {{currency}} <s>{{convertCurrency(defaultCurrency,currency!,svc2OriginalPrice)|number:'1.2-2'}}</s> &nbsp;{{convertCurrency(defaultCurrency,currency!,svc2DiscountPrice)|number:'1.2-2'}} onwards</b>
                                    </div>

                                    <!--
                                    <div class="col-md-12 pt-4">
                                        <button class="button-style-2" (click)="makePayment(convertCurrency(defaultCurrency,currency!,svc2DiscountPrice))">Pay and book</button>
                                    </div>
                                    -->
                                    <div class="col-md-12 pt-4">
                                        <button class="button-style-2" (click)="enablePayment2()">Pay and book</button>
                                    </div> 

                                    
                                   
                                    <div class="col-md-6 pt-3 clickable" *ngIf="showPayment2">
                                        <img src="../../assets/images/Logo/Razorpay.png"   (click)="makePayment(convertCurrency(defaultCurrency,currency!,svc2DiscountPrice))">
                                    </div>
    
                                    <div #payPalRef2 class="col-md-6 pt-3" > </div>


                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
             </div>
    
             <div class=" d-none d-md-block col-md-4" >
                <div class="flex justify-center"  >
                    <mat-card>
                        <mat-card-content class="venue-mat-card">
                            <img mat-card-image src="../../assets/images/Sakhi/sakhi3.jpg" class="clickable" >
                            
                            <div class="pt-2 text-left">
    
                                <div class="row">
                                    <div class="col-md-12 soulsWedPriceFont">
                                        Buddy's Planning Support
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-md-12 pt-2">
                                       <p> Comprehensive planning solution with standardized contracts for all categories (from venues to cleaning crew).</p>
                                    </div>
                                </div>
    
                                <div class="row pt-2">
                                    <div class="col-md-12">
                                        <span class="material-icons green-tick">check</span>&nbsp;Find providers who can meet your needs in terms of location, pricing, and date availability. 
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Examination of vendor agreements
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Up to two in-person or video appointments to receive help
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Negotiation of the Best Price
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;50% off of video invites OR a free digital invite
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;For Photographers, Accessories, DJs, Family Makeup, Wedding Pandits, Bartenders, Wedding Entertainment, Pre-Wedding Photographers, Pre-Wedding Shoot Locations, Decorators, Catering Services, Cake, Invitations, Cinema/Video, Mehendi Artist, Trousseau Packers, Sangeet Choreographer, Favors, Wedding Planners, Accessories, DJs, Bridal Wear, Groom Wear, and Jewellery.
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Suggested for Weddings Held in Destination Locations  
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Good for 90 days
                                        <br><span class="material-icons green-tick pt-2">check</span>&nbsp;Continued correspondence and virtual assistance through the booking stage or Maintain contact and provide virtual assistance through the booking stage
                                    </div>
                                </div>
    
                                <div class="row pt-2 text-center">
                                    <div class="col-md-12 pt-4 soulsWedPriceFont">
                                        <b>from {{currency}} <s>{{convertCurrency(defaultCurrency,currency!,svc3OriginalPrice)|number:'1.2-2'}}</s> &nbsp;{{convertCurrency(defaultCurrency,currency!,svc3DiscountPrice)|number:'1.2-2'}} onwards </b>
                                    </div>

                                    <!--
                                    <div class="col-md-12 pt-4">
                                        <button class="button-style-2" (click)="makePayment(convertCurrency(defaultCurrency,currency!,svc3DiscountPrice))">Pay and book</button>
                                    </div>
                                    -->

                                    <div class="col-md-12 pt-4">
                                        <button class="button-style-2" (click)="enablePayment3()">Pay and book</button>
                                    </div> 

                                    
                                   
                                        <div class="col-md-6 pt-3 clickable" *ngIf="showPayment3">
                                            <img src="../../assets/images/Logo/Razorpay.png"   (click)="makePayment(convertCurrency(defaultCurrency,currency!,svc3DiscountPrice))">
                                        </div>
    
                                        <div #payPalRef3 class="col-md-6 pt-3" >                                      
                                        </div>


                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
             </div>
    
         </div>

    </div>


     <!--   For Smaller Screens -->

     <div class="row d-block d-md-none pt-6 negative-margin-top" >     
        
        <div class="col-12">

            <div  class="row">

                <div class="row">
                    <div class="col-12">
                        <div class="row ">
                            <app-goback></app-goback>
                         </div>
                    </div>
                </div>
        
                <div class="row pt-3">
                    <div class="col-12">
                        <span class="flex justify-center soulsWedHeadingFont">{{titleText}}</span>
                    </div>
                </div>
               
                <div class="row">                
                    <div class="col-12 pt-2">
                        <span class="soulsWedSubheadingFont flex justify-center ">Amazing Halls' Buddy can help out!</span>
                    </div>
                </div>
    
                <div class="row pt-3">
                    
                        <div class="col-5">
                            Vendors&nbsp;<a href="mailto:Help@AmazingHalls.com"> <i class="fas fa-envelope fa-lg email-icon" style="color: #add7f6"></i> </a>
                        </div>
    
                        <div class="col-2"></div>
    
                        <div class="col-5 text-right">
                            Customers&nbsp;<a href="mailto:info@amazinghalls.com"><i class="fas fa-envelope fa-lg email-icon" style="color: #add7f6"></i> </a>
                        </div>
                    
                    
                </div>
            </div>

            <div class="row d-block d-md-none py-2" >    
                <div class="col-12" >
    
                    <div>
                        <mat-card class="venue-mat-card">
                            <mat-card-content>
                                <img mat-card-image src="../../assets/images/Sakhi/sakhi1.jpg" class="clickable" >
                                
                                <div class="pt-2 text-left">
        
                                    <div class="row">
                                        <div class="col-12 soulsWedPriceFont">
                                            Get Buddy’s Help with Discovery
                                        </div>
                                    </div>
        
                                    <div class="row">
                                        <div class="col-12 pt-2">
                                           <p> Easily reserve any single category</p>
                                        </div>
                                    </div>
        
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <span class="material-icons green-tick">check</span>&nbsp;Find vendors who meet your needs in terms of location, pricing, and date availability.
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Good in a single city and category
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;45-day window for the best price negotiation.
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Continued correspondence and virtual assistance through the booking stage or
                                            maintain contact and provide virtual assistance through the booking stage.   
                                        </div>
                                    </div>
        
                                    <div class="row pt-2 text-center">
                                        <div class="col-12 pt-2 soulsWedPriceFont">
                                            from {{currency}} <s>{{convertCurrency(defaultCurrency,currency!,svc1OriginalPrice)|number:'1.2-2'}}</s> &nbsp;{{convertCurrency(defaultCurrency,currency!,svc1DiscountPrice)|number:'1.2-2'}} onwards
                                        </div>

                                        <div class="col-12 pt-2">
                                            <button class="button-style-2" (click)="enablePayment1()">Pay and book</button>
                                        </div>
 
                                        <div class="col-12 pt-3 clickable" *ngIf="showPayment1">
                                            <img src="../../assets/images/Logo/Razorpay.png"   (click)="makePayment(convertCurrency(defaultCurrency,currency!,svc1DiscountPrice))">
                                        </div>
        
                                        <div #payPalRef11 class="col-12 pt-3" > </div>


                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                 </div>
        
                 <div class=" d-block d-md-none col-12" >
                    <div class="flex justify-center"  >
                        <mat-card class="venue-mat-card">
                            <mat-card-content>
                                <img mat-card-image src="../../assets/images/Sakhi/sakhi2.jpg" class="clickable" >
                                
                                <div class="pt-2 text-left">
        
                                    <div class="row">
                                        <div class="col-12 soulsWedPriceFont">
                                          Buddy’s Reservation Support                                   
                                        </div>
                                    </div>
        
                                    <div class="row">
                                        <div class="col-12 pt-2">
                                           <p> Assistance in three primary areas</p>
                                        </div>
                                    </div>
        
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <span class="material-icons green-tick">check</span>&nbsp;Obtain vendor recommendations that fit your location, budget, and available dates and bids.
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Good for two cities for one category OR three categories in one city.
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;One video conference to seek support
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Greatest bargaining position
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;20% off invitations via video
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Continued correspondence and virtual assistance through the booking stage or Maintain contact and provide virtual assistance through the booking stage.
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Good for 60 days  
                                        </div>
                                    </div>
        
                                    <div class="row pt-2 text-center">
                                        <div class="col-12 pt-2 soulsWedPriceFont">
                                            from {{currency}} <s>{{convertCurrency(defaultCurrency,currency!,svc2OriginalPrice)|number:'1.2-2'}}</s> &nbsp;{{convertCurrency(defaultCurrency,currency!,svc2DiscountPrice)|number:'1.2-2'}} onwards
                                        </div>

                                        <div class="col-12 pt-2">
                                            <button class="button-style-2" (click)="enablePayment2()">Pay and book</button>
                                        </div>
 
                                        <div class="col-12 pt-3 clickable" *ngIf="showPayment2">
                                            <img src="../../assets/images/Logo/Razorpay.png"   (click)="makePayment(convertCurrency(defaultCurrency,currency!,svc2DiscountPrice))">
                                        </div>
        
                                        <div #payPalRef22 class="col-12 pt-3" > </div>

                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                 </div>
        
                 <div class=" d-block d-md-none col-12" >
                    <div class="flex justify-center"  >
                        <mat-card class="venue-mat-card">
                            <mat-card-content>
                                <img mat-card-image src="../../assets/images/Sakhi/sakhi3.jpg" class="clickable" >
                                
                                <div class="pt-2 text-left">
        
                                    <div class="row">
                                        <div class="col-12 soulsWedPriceFont">
                                          Buddy’s Planning Support
                                        </div>
                                    </div>
        
                                    <div class="row">
                                        <div class="col-12 pt-2">
                                           <p> Comprehensive planning solution with standardized contracts for all categories (from venues to cleaning crew).</p>
                                        </div>
                                    </div>
        
                                    <div class="row pt-2">
                                        <div class="col-12">
                                            <span class="material-icons green-tick">check</span>&nbsp;Find providers who can meet your needs in terms of location, pricing, and date availability. 
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Examination of vendor agreements
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Up to two in-person or video appointments to receive help
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Negotiation of the Best Price
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;50% off of video invites OR a free digital invite
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;For Photographers, Accessories, DJs, Family Makeup, Wedding Pandits, Bartenders, Wedding Entertainment, Pre-Wedding Photographers, Pre-Wedding Shoot Locations, Decorators, Catering Services, Cake, Invitations, Cinema/Video, Mehendi Artist, Trousseau Packers, Sangeet Choreographer, Favors, Wedding Planners, Accessories, DJs, Bridal Wear, Groom Wear, and Jewellery.
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Suggested for Weddings Held in Destination Locations  
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Good for 90 days
                                            <br><span class="material-icons green-tick pt-1">check</span>&nbsp;Continued correspondence and virtual assistance through the booking stage or Maintain contact and provide virtual assistance through the booking stage
                                        </div>
                                    </div>
        
                                    <div class="row pt-2 text-center">
                                        <div class="col-12 pt-2 soulsWedPriceFont">
                                            from {{currency}} <s>{{convertCurrency(defaultCurrency,currency!,svc3OriginalPrice)|number:'1.2-2'}}</s> &nbsp;{{convertCurrency(defaultCurrency,currency!,svc3DiscountPrice)|number:'1.2-2'}} onwards
                                        </div>

                                        <div class="col-12 pt-2">
                                            <button class="button-style-2" (click)="enablePayment3()">Pay and book</button>
                                        </div>
 
                                        <div class="col-12 pt-3 clickable" *ngIf="showPayment3">
                                            <img src="../../assets/images/Logo/Razorpay.png"   (click)="makePayment(convertCurrency(defaultCurrency,currency!,svc3DiscountPrice))">
                                        </div>
        
                                        <div #payPalRef33 class="col-12 pt-3" > </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                 </div>
        
             </div>

        </div>

        

    
        


    

     </div>
