
<div class="full-width-wrapper">
  <div class="container-fluid ">
    <app-header2></app-header2>

    <!--
    <div class="row pt-24 pl-5">
      <app-goback></app-goback>
    </div>
    -->
    
    <div class="container-fluid mt-3 pt-24 text-yellow-900 d-none d-md-block">
    
        <form [formGroup]="vendorForm" (ngSubmit)="onSubmit()">
    
          <div class="row">
    
            <div class="col-md-6">
    
              <div class="row">
                <div class="col-md-4 flex justify-start ">
                    <app-goback></app-goback>
                </div>
    
                <div class="col-md-8 ">
                  <div class="flex justify-start soulsWedSubheadingFont mb-4">
                    <b>Vendor Registration</b>
                  </div>
                </div>
    
              </div>
    
               
              
    
              
                <!-- Select country and category -->
            <div class="row  m-3 p-2 flex items-center custom-border">
    
            <!-- Country Drop Down --------------->
             <div class="col-md-6">
                <label for="country" class="form-label soulsWedHeadingFontGray">Country *</label>
                   <select class="form-select" id="country" formControlName="country" (change)="onCountrySelect($event)">
                    <option *ngFor="let country of countries" [value]="country.idcountry">{{ country.countryName }}</option>
                  </select>
            </div>
    
            <!-- Category Drop Down --------------->
            <div class="col-md-6">
                 <label for="category" class="form-label soulsWedHeadingFontGray">Category *</label>
                   <select class="form-select" id="category" formControlName="category"  (change)="onCategorySelect($event)">
                    <option *ngFor="let category of categories" [value]="category.idcategory">{{ category.categoryName }}</option>
                  </select>
            </div>
    
        </div>
        
                
        <!-- Section 2: Vendor Details -->
        <div class=" row custom-border m-3 p-3 ">
          <div class="pt-1 pb-2 flex justify-center soulsWedSubheadingFont mb-4">
            <b>Vendor Details</b>
          </div>
    
          <div>
            <label for="vendorName" class="form-label">Vendor Name *</label>
            <input type="text" class="form-control" id="vendorName" formControlName="vendorName" maxlength="200"  (blur)="onBlur('vendorName')" [class.is-invalid]="vendorName?.invalid && vendorName?.touched">
            <div *ngIf="vendorName?.invalid && vendorName?.touched" class="invalid-feedback">
              <div *ngIf="vendorName?.errors?.['required']">Vendor name is required.</div>
            </div>
          </div>
    
           <!-- Select email and mobile -->
           <div class="row pt-3">
    
            <!---------- Email --------------->
            <div class="col-md-5">
                <label for="email" class="form-label">Email ID *</label>
                <input type="email" class="form-control" id="email" formControlName="email" (blur)="onBlur('vendorName')" [class.is-invalid]="email?.invalid && email?.touched">
                <small id="emailHelp" class="form-text text-muted"><i>Your email-id will be your user id for Soulswed</i></small>
                <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
                  <div *ngIf="email?.errors?.['required']">Email is required.</div>
                  <div *ngIf="email?.errors?.['email']">Please enter a valid email address.</div>
                </div>
            </div>
    
          
      
    
             <!-- Country Drop Down --------------->
             <div class="col-md-4">
                <label for="countryCode" class="form-label">Phone Country Code </label>
                <select class="form-select" id="countryCode" formControlName="countryCode">
                  <option *ngFor="let phoneCode of phoneCountryCodes" [value]="phoneCode.phoneCode">{{ phoneCode.countryName}}&nbsp;&nbsp;{{phoneCode.phoneCode }}</option>
                </select>
            </div>
    
            <!---------- Phone Number --------------->
            <div class="col-md-3">
                <label for="phone" class="form-label">Phone Number </label>
                <input type="text" class="form-control" id="phone" formControlName="phone" (blur)="onBlur('phone')" [class.is-invalid]="phone?.invalid && phone?.touched">
                <div *ngIf="phone?.invalid && phone?.touched" class="invalid-feedback">
                  <div *ngIf="phone?.errors?.['required']">Mobile number is required.</div>
                  <div *ngIf="phone?.errors?.['pattern']">Please enter a valid mobile number.</div>
                </div>
            </div>
    
          </div>
    
          <!-------------------------- Address Fields------------------->
          
         <div class="row pt-3 ">
    
             <!-- City Drop Down --------------->
             <div class="col-md-3">
              <label for="city" class="form-label">City </label>
              <select class="form-select" id="city" formControlName="city">
                <option value=""> </option>
                <option *ngFor="let city of cities" [value]="city.cityname">{{city.cityname}}</option>
              </select>
            </div>
    
            <!---------- Addess Lines --------------->
            <div class="col-md-9">
              <label for="Addessline" class="form-label">Addess </label>
              <input type="text" class="form-control" id="Addessline1" maxlength="200"   formControlName="addressLine1" (blur)="onBlur('addressLine1')" [class.is-invalid]="addressLine1?.invalid && addressLine1?.touched">
              <div *ngIf="addressLine1?.invalid && addressLine1?.touched" class="invalid-feedback">
                <div *ngIf="addressLine1?.errors?.['required']">Address is required.</div>
              </div>
            </div>
    
         
         </div>
    
        </div>
    
            </div>
    
            <div class="col-md-6 pt-14">
    
                <google-map height="460px"
    
                  width="600px"
                  [center]="center"
                  [zoom]="zoom"
                  (mapClick)="moveMap($event)"
                  (mapMousemove)="move($event)">
    
                  <div *ngIf="locationChosen">
                      <map-marker [position]="center"></map-marker>
                  </div>
    
                </google-map>
    
                <!--
                <div>Latitude: {{display?.lat}}</div>
                <div>Longitude: {{display?.lng}}</div>
                -->
    
              <!--
                <img class="mt-10" src="../../assets/images/VendorImages/img1.jpg">
              -->
            </div>
          </div>
    
          
          
          <!-- Section 3: Service Details -->
          <div class=" row m-3 p-3 custom-border">
            <div class="pt-2 flex justify-center soulsWedSubheadingFont mb-4">
              <b>Service Details</b>
            </div>
            
            <!-------------------------- Service Description -------------------->
            <div class="row">
              <div class="col-md-12">
                <label for="servicedescription" class="form-label">Describe your service (max 5000 characters) </label>
                <textarea class="form-control" id="servicedescription" maxlength="5000" rows="6" formControlName="serviceDescription" (blur)="onBlur('serviceDescription')" [class.is-invalid]="serviceDescription?.invalid && serviceDescription?.touched"></textarea>
                <div *ngIf="serviceDescription?.invalid && serviceDescription?.touched" class="invalid-feedback">
                  <div *ngIf="serviceDescription?.errors?.['required']">Service Description is required.</div>
                </div>
              </div>
            </div> 
    
            <!--
            ------------------------ Service Tagline --------------------
            <div class="row pt-3">
              <div class="col-md-12">
                <label for="tagline" class="form-label">Your Tagline (This will appear in our listings)</label>
                <input type="text" class="form-control" id="tagline" formControlName="tagline" maxlength="100">
              </div>
            </div> 
    
          -->
    
            <!-- Starting price and price basis -->
    
            <div class="row pt-3">
              <div class="col-md-2">
                <div class="dropdown">
                  <label for="dropdownMenuButton" class="form-label">Currency</label><br>
                  <input type="text" class="form-control" id="currency" formControlName="currency" maxlength="100" readonly>
              </div>
              </div>
           
    
            <!-------------------------- Service Starting Price -------------------->
            <div class="col-md-2">
                <label for="Price" class="form-label">Starting Price </label>
                <input type="text" class="form-control" id="price" formControlName="price" (blur)="onBlur('price')" [class.is-invalid]="price?.invalid && price?.touched">
                <div *ngIf="price?.invalid && price?.touched" class="invalid-feedback">
                  <div *ngIf="price?.errors?.['required']">Starting Price is required.</div>
                  <div *ngIf="price?.errors?.['pattern']">Please enter a valid number</div>
                </div>
            </div>
    
            <!-- Starting price and price basis -->
            <div class="col-md-2 pl-2">
              <label for="pricebasis" class="form-label">Price Basis</label>
              <select class="form-select" id="pricebasis" formControlName="pricebasis">
                <option *ngFor="let pricebasis of priceBases" [value]="pricebasis">{{ pricebasis}}</option>
              </select>
          </div>
    
    
            <!-------- Show capacity only if aplplicable for the category --------------------->
            
              <div *ngIf="isCapacityApplicable" class="col-md-2">
                <label for="Capacity" class="form-label">Capacity</label>
                <input type="text" class="form-control" id="capacity" formControlName="capacity" (blur)="onBlur('capacity')" [class.is-invalid]="capacity?.invalid && capacity?.touched">
                <div *ngIf="capacity?.invalid && capacity?.touched" class="invalid-feedback">
                  <div *ngIf="capacity?.errors?.['pattern']">Please enter a valid number</div>
                </div>
            </div>
    
            <!-- show category if applicable  -->
            <div *ngIf="isHotelCategoryApplicable" class="col-md-2">
              <label for="hotelcategory" class="form-label">Category</label>
              <select class="form-select" id="hotelcategory" formControlName="vendorrating">
                <option *ngFor="let cat of hotelcategories" [value]="cat">{{ cat}}</option>
              </select>
          </div>
    
            </div>
    
            <!-- Payment schedule -->
            <div class="row pt-3 pb-3">
                <div class="col-md-2">
                  <label for="advance" class="form-label">Minimum Advance Payment % </label>
                  <input type="text" class="form-control" id="advance" formControlName="advance" (blur)="onBlur('advance')" [class.is-invalid]="advance?.invalid && advance?.touched">
                  <div *ngIf="advance?.invalid && advance?.touched" class="invalid-feedback">
                      <div *ngIf="advance?.errors?.['required']">Advance payment % is required.</div>
                      <div *ngIf="advance?.errors?.['pattern']">Please enter a valid number (0-100 with up to 2 decimal places).</div>
                      <div *ngIf="advance?.errors?.['min']">Value must be between 0 and 100</div>
                      <div *ngIf="advance?.errors?.['max']">Value must be between 0 and 100</div>
                  </div>
                </div>
    
                <div class="col-md-4">
                  <label for="balancedays" class="form-label">Balance payment to be made by (days prior to event) </label>
                  <input type="text" class="form-control" id="balancedays" formControlName="balancedays" (blur)="onBlur('balancedays')" [class.is-invalid]="balancedays?.invalid && balancedays?.touched">
                  <div *ngIf="balancedays?.invalid && balancedays?.touched" class="invalid-feedback">
                      <div *ngIf="balancedays?.errors?.['required']">Please enter days prior event for balance payment</div>
                      <div *ngIf="balancedays?.errors?.['pattern']">Please enter a valid number</div>
                  </div>
                </div>
    
            </div>
    
                 <!----------- Show Amenities if applicable     -->
            
                 <div *ngIf="isAmenitiesApplicable">
    
                      <div class="row pt-4">
                            <div class="col-md-12">
                                <h2> Amenities</h2>
                            </div>
                      </div>
    
                      <div class="row pb-4">
                        <div class="col-md-3 pb-2" *ngFor="let amenity of vendorAmenities; let i = index">    
                          <input type="checkbox" [id]="'amenity-' + i" [checked]="amenity.available" (change)="updateAvailability(i)">
                          <label [for]="'amenity-' + i">&nbsp;{{amenity.amenityName}}</label>
                        </div>       
                      </div>
                  
              
                   </div>
              
      
              <!-- Image and Video Upload -->
    
             <div class="row pt-4 pb-4">
    
                <div class="col-md-5">
                    <label for="images" class="form-label">Upload Images</label>
                    <input type="file" class="form-control" id="images" formControlName="password" multiple accept="image/*" (change)="onImageFileSelected($event)">
                    <small id="imageHelp" class="form-text text-muted"><i>You can select all images at once and upload</i></small>
                </div>
                
    
                <div class="col-md-2">
                </div>
    
                <div class="col-md-5">
                    <label for="video" class="form-label">Upload Video </label>
                    <input type="file" class="form-control" formControlName="confirmPassword" id="video" multiple  accept="video/*" (change)="onVideoSelected($event)">
                </div>
    
             </div>
    
          
      </div>
    
       <!-- Section 4: Vendor FAQ -->
     
      
       <div class=" row custom-border m-3 p-3 ">
        <div class="pt-1 pb-2 flex justify-center soulsWedSubheadingFont mb-4">
          <b>Vendor FAQ</b>
        </div>
    
    
          <div *ngIf="isVenue">
    
            <div class="row pb-2 ">
    
              <div class="col-md-4 ">
                <div >
                  <label class="form-label">1. Describe your primary venue type</label>
                  <div *ngFor="let option of primaryVenueDescriptions; let i = index">
                    <input type="radio" [id]="option" formControlName="venue_primaryDescription" [value]="option" (blur)="onBlur('venue_primaryDescription')">
                    <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
              </div>
    
              <div class="col-md-3">
    
                <div class="row">
    
                  <div class="col-md-12">
                    <label class="form-label">2. Do you have a rental cost along with per plate cost?</label>
                    <div *ngFor="let option of venueTypeOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_venueType" [value]="option" (blur)="onBlur('venue_venueType')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
    
                  <div class="'col-md-12 pt-3">
                    <label for="venue_startyear" class="form-label">3. In which year did you start operations in your venue? </label>
                    <input type="text" class="form-control" id="venue_startyear" maxlength="4"   formControlName="venue_startyear" (blur)="onBlur('venue_startyear')" >
                  </div>
    
                  <div class="col-md-12 pt-3">
                    <label class="form-label">4. Does your venue include on site accommodation?</label>
                    <div *ngFor="let option of onsiteaccomodations; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_onsiteaccomodation" [value]="option" (blur)="onBlur('venue_onsiteaccomodation')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                        
                </div>
              </div>
    
              <div class="col-md-1"></div>
              
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <label for="venue_vegStartPrice" class="form-label">5. What is the starting price for vegetarian menu? (assume 250 pax and standard
                      menu) – if the price is for smaller or larger numbers, please specify the number of
                      persons </label>
                    <input type="text" class="form-control" id="venue_vegStartPrice" maxlength="4"   formControlName="venue_vegStartPrice" (blur)="onBlur('venue_vegStartPrice')" >
                  </div>
    
                  <div class="col-md-12 pt-3">
                    <label for="venue_nonvegStartPrice" class="form-label">6. What is the starting price for non-vegetarian menu? (assume 250 pax and standard
                      menu) – if the price is for smaller or larger numbers, please specify the number of
                      persons </label>
                    <input type="text" class="form-control" id="venue_nonvegStartPrice" maxlength="4"   formControlName="venue_nonvegStartPrice" (blur)="onBlur('venue_nonvegStartPrice')" >
                  </div>
                        
                </div>
              </div>
    
            </div>
            <hr>
            <div class="row pt-2">
    
              <div class="col-md-4">
                <div>
                  <label class="form-label">7. Venue type</label>
                  <div *ngFor="let option of venueTypeCheckOptions; let i = index">
                    <input type="checkbox" [id]="'option' + i" [value]="option.value" (change)="onCheckboxChange($event)">
                    <label [for]="'option' + i">&nbsp;{{ option.value }}</label>
                  </div>
                </div>
              </div>
    
              <div class="col-md-3">
    
                <div class="row">
    
                  <div class="'col-md-12 ">
                    <label for="venue_startyear" class="form-label">8. How many rooms are available in your accommodation? </label>
                    <input type="text" class="form-control" id="venue_roomsavailable" maxlength="4"   formControlName="venue_roomsavailable" (blur)="onBlur('venue_roomsavailable')" >
                  </div>
    
                  <div class="'col-md-12 pt-3">
                    <label for="venue_startyear" class="form-label">9. What is the starting price for a basic room at your hotel? </label>
                    <input type="text" class="form-control" id="venue_roomsprice" maxlength="4"   formControlName="venue_roomsprice" (blur)="onBlur('venue_roomsprice')" >
                  </div>
                        
                </div>
              </div>
    
              <div class="col-md-1"></div>
              
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                      <label class="form-label">10. What is your policy on catering?</label>
                      <div *ngFor="let option of cateringPolicyOptions; let i = index">
                        <input type="radio" [id]="option" formControlName="venue_cateringpolicy" [value]="option" (blur)="onBlur('venue_cateringpolicy')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                      </div>
                    </div>
                  </div>
                        
                </div>
              </div>
    
            </div>
            <hr>
            <div class="row pt-2">
    
              <div class="col-md-4">
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">11. What is your policy on alcohol?</label>
                    <div *ngFor="let option of alchoholPolicyOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_alchoholpolicy" [value]="option" (blur)="onBlur('venue_alchoholpolicy')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                      <label class="form-label">12. What is your policy on DJ's?</label>
                      <div *ngFor="let option of djPolicyOptions; let i = index">
                        <input type="radio" [id]="option" formControlName="venue_djpolicy" [value]="option" (blur)="onBlur('venue_djpolicy')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                      </div>
                    </div>
                  </div>
                        
                </div>
              </div>
    
              
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                      <label class="form-label">13. What is your policy on decor?</label>
                      <div *ngFor="let option of decorPolicyOptions; let i = index">
                        <input type="radio" [id]="option" formControlName="venue_decorpolicy" [value]="option" (blur)="onBlur('venue_decorpolicy')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                      </div>
                    </div>
                  </div>
    
                  <div class="'col-md-12 pt-3">
                    <label for="venue_decorprice" class="form-label">14. What is the minimum starting price to decorate your venue? </label>
                    <input type="text" class="form-control" id="venue_decorprice" maxlength="4"   formControlName="venue_decorprice" (blur)="onBlur('venue_decorprice')" >
                  </div>
                        
                </div>
              </div>
    
            </div>
            <hr>
    
            <div class="row pt-2">
    
              <div class="col-md-4">
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">15. Do you also allow small size gatherings (&lt;50) ?  </label>
                    <div *ngFor="let option of smallGatheringsOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_smallgatherings" [value]="option" (blur)="onBlur('venue_smallgatherings')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                      <label class="form-label">16. Is parking available at the venue?</label>
                      <div *ngFor="let option of venueparkingOptions; let i = index">
                        <input type="radio" [id]="option" formControlName="venue_parking" [value]="option" (blur)="onBlur('venue_parking')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                      </div>
                    </div>
                  </div>
                        
                </div>
              </div>
    
              
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                      <label class="form-label">17. What is your USP?</label>
                      <textarea class="form-control" id="venue_USP" maxlength="1999"   formControlName="venue_USP" (blur)="onBlur('venue_USP')" > </textarea>
                    </div>
                  </div>
    
               
                        
                </div>
              </div>
    
            </div>
    
            <div class="row pt-4">
    
              <div class="col-md-4">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">18. How many weeks in advance should a booking be made?  </label>                
                    <input type="text" class="form-control" id="venue_weeksadvance" maxlength="20"   formControlName="venue_weeksadvance" (blur)="onBlur('venue_weeksadvance')"  >
                    
                    </div>
                  </div>
                
              </div>
    
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                      <label class="form-label">19. Please describe your cancellation policy ( if a user initiates cancellation) including whether you
                        provide refunds of booking amounts , and terms for doing so</label>
                      <div *ngFor="let option of venue_cancellationpolicyuseroptions; let i = index">
                        <input type="radio" [id]="option" formControlName="venue_cancellationpolicyuser" [value]="option" (blur)="onBlur('venue_cancellationpolicyuser')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                      </div>
                    </div>
                  </div>
                        
                </div>
              </div>
    
              
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                      <label class="form-label">20. Please describe your cancellation policy ( if vendor initiates cancellation) including whether you
                        provide refunds of booking amounts , and terms for doing so</label>
                      <div *ngFor="let option of venue_cancellationpolicyvendoroptions; let i = index">
                        <input type="radio" [id]="option" formControlName="venue_cancellationpolicyvendor" [value]="option" (blur)="onBlur('venue_cancellationpolicyvendor')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                      </div>
                    </div>
                  </div>
    
               
                        
                </div>
              </div>
    
            </div>
    
            <div class="row pt-4">
    
              <div class="col-md-3">
    
               
               
                  <div>
                    <label class="form-label" class="wrap-text">21. What are the terms & conditions of your cancellation policy? ( please describe in detail eg No
                      refunds within a month of the wedding day or 50% amount refundable)  </label>                
                    <textarea class="form-control" id="venue_cancellationtnc" maxlength="4500"  rows="5" formControlName="venue_cancellationtnc" (blur)="onBlur('venue_cancellationtnc')" > </textarea>
                    
                    </div>
           
                
              </div>
    
              <div class="col-md-1"></div>
    
              <div class="col-md-4">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                      <label class="form-label">22. Please select whatever is applicable for your venue </label>
                      <div *ngFor="let option of venue_allapplicableoptions; let i = index">
                        <input type="checkbox" [id]="'option' + i" [value]="option" (change)="onAllApplicableCheckboxChange($event)">
                        <label [for]="'option' + i">&nbsp;{{ option }}</label>
                      </div>
                    </div>
                  </div>
                        
                </div>
              </div>
    
              
              <div class="col-md-3">
    
                <div class="row">
    
                  <div class="col-md-12 ">
                    <div>
                     
                    </div>
                  </div>
    
               
                        
                </div>
              </div>
    
            </div>
    
          </div>
            
    
          <div *ngIf="isHotel">
    
            <div class="row pb-2 ">
    
              <div class="col-md-4 ">
                <label for="couple_policy" class="form-label">1. What is your policy on couples?</label>
                <div *ngFor="let option of couplePolicyOptions; let i = index">
                  <input type="radio" [id]="option" formControlName="couple_policy" [value]="option" (blur)="onBlur('couple_policy')">
                  <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="col-md-4 ">
                <label for="smoking_policy" class="form-label">2. What is your policy on smoking?</label>
                <div *ngFor="let option of smokingOptions; let i = index">
                  <input type="radio" [id]="option" formControlName="smoking_policy" [value]="option" (blur)="onBlur('smoking_policy')">
                  <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="col-md-4 ">
                <label for="parking_available" class="form-label">3. Is parking available for guests?</label>
                <div *ngFor="let option of yesNoArray; let i = index">
                  <input type="radio" [id]="option+'par'" formControlName="parking_available" [value]="option" (blur)="onBlur('parking_available')">
                  <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
            </div>
    
            <div class="row pb-2 pt-4 ">
    
              <div class="col-md-4 ">
                <label for="extra_beds" class="form-label">4. Can you provide extra beds in rooms?</label>
                <div *ngFor="let option of yesNoArray; let i = index">
                  <input type="radio" [id]="option+'e'" formControlName="extra_beds" [value]="option" (blur)="onBlur('extra_beds')">
                  <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="col-md-4 ">
                <label for="pets_policy" class="form-label">5. Do you allow pets?</label>
                <div *ngFor="let option of yesNoArray; let i = index">
                  <input type="radio" [id]="option+'p'" formControlName="pets_policy" [value]="option" (blur)="onBlur('pets_policy')">
                  <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="col-md-4">
                <label for="days_cancellation" class="form-label">6. How many days in advance can guests cancel free of charge?</label>
                <input type="text" class="form-control" id="days_cancellation" maxlength="4"   formControlName="days_cancellation" (blur)="onBlur('days_cancellation')" >
              </div>
    
            </div>
    
            <div class="row pb-2 pt-4 ">
    
              <div class="col-md-4 ">
                <label for="creditcards_policy" class="form-label">7. Can you charge credit cards at your property?</label>
                <div *ngFor="let option of yesNoArray; let i = index">
                  <input type="radio" [id]="option+'c'" formControlName="creditcards_policy" [value]="option" (blur)="onBlur('creditcards_policy')">
                  <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>       
    
            <div class="col-md-4 ">
              <label for="otherpayment_methods" class="form-label">8. Specify the other types of payments you accept</label>
              <textarea class="form-control" id="otherpayment_methods" maxlength="2000" rows="4" formControlName="otherpayment_methods" (blur)="onBlur('otherpayment_methods')" [class.is-invalid]="otherpayment_methods?.invalid && otherpayment_methods?.touched"></textarea>
            </div>
    
          </div>
              
          </div>
    
          <div *ngIf="isPlanner">
    
            <div class="row pb-2 ">
    
              <div class="col-md-4 ">
                <div>
                  <label for="planner_USP" class="form-label">1. Describe your USP </label>
                    <textarea class="form-control" id="planner_USP" maxlength="2000" rows="4" formControlName="planner_USP" (blur)="onBlur('planner_USP')" [class.is-invalid]="planner_USP?.invalid && planner_USP?.touched"></textarea>
                </div>
              </div>
    
              <div class="col-md-4 ">
                <div>
                  <label for="planner_hotelslist" class="form-label">2. Please mention any hotels/banquet halls panels you are enlisted on</label>
                    <textarea class="form-control" id="planner_hotelslist" maxlength="2000" rows="4" formControlName="planner_hotelslist" (blur)="onBlur('planner_hotelslist')" [class.is-invalid]="planner_hotelslist?.invalid && planner_hotelslist?.touched"></textarea>
                </div>
              </div>
    
              <div class="col-md-4 ">
                <div>
                  <label for="planner_cities" class="form-label">3. Cities where you have previously planned weddings/parties </label>
                    <textarea class="form-control" id="planner_cities" maxlength="2000" rows="4" formControlName="planner_cities" (blur)="onBlur('planner_cities')" [class.is-invalid]="planner_cities?.invalid && planner_cities?.touched"></textarea>
                </div>
              </div>
    
    
            </div>
    
            <div class="row pt-4 pb-2 ">
    
              <div class="col-md-4 ">
                  <label for="planner_startingPackage" class="form-label">4. What is your starting package for planning a 3-day wedding?. </label>
                  <textarea class="form-control" id="planner_startingPackage" maxlength="2000" rows="2" formControlName="planner_startingPackage" (blur)="onBlur('planner_startingPackage')" [class.is-invalid]="planner_startingPackage?.invalid && planner_startingPackage?.touched"></textarea>      
              </div>
    
              <div class="col-md-4">
                <label for="planner_decorPolicy" class="form-label">5. What is your policy on decor?</label>
                <div *ngFor="let option of plannerDecorPolicyOptions; let i = index">
                  <input type="radio" [id]="option" formControlName="planner_decorPolicy" [value]="option" (blur)="onBlur('planner_decorPolicy')">
                  <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="col-md-4 ">
                <label for="planner_commercialPlan" class="form-label">6. What kind of a commercial plan do you have?</label>
                  
                  <br><input type="radio" [id]="1" formControlName="planner_commercialPlan" value="We charge a Fixed Fee for Planning" (blur)="onBlur('planner_commercialPlan')">
                  <label [for]="1">&nbsp; We charge a Fixed Fee for Planning </label>
    
                 <br> <input type="radio" [id]="2" formControlName="planner_commercialPlan" value="We charge a percentage of the wedding cost" (blur)="onBlur('planner_commercialPlan')">
                  <label [for]="2">&nbsp; We charge a percentage of the wedding cost </label>
    
                 <br> <label for="planner_commercialPlanPerc" class="form-label"> If percentage of wedding cost, then what percentage? </label>
                  <textarea class="form-control" id="planner_commercialPlanPerc" maxlength="500" rows="1" formControlName="planner_commercialPlanPerc" (blur)="onBlur('planner_commercialPlanPerc')" [class.is-invalid]="planner_commercialPlanPerc?.invalid && planner_commercialPlanPerc?.touched"></textarea>
    
              </div>
    
              <div class="row pt-4 pb-2 ">
                <div class="col-md-4 ">
                  <div>
                    <label for="planner_prominentClients" class="form-label">7. Mention the names of your clients if they happen to be prominent people or celebrities. If you don’t want to show this, please leave this blank. </label>
                      <textarea class="form-control" id="planner_prominentClients" maxlength="2000" rows="4" formControlName="planner_prominentClients" (blur)="onBlur('planner_prominentClients')" [class.is-invalid]="planner_prominentClients?.invalid && planner_prominentClients?.touched"></textarea>
                  </div>
                </div>
    
              </div>
              
    
    
          </div>    
    
          </div>
    
          <div *ngIf="isPhotographer">
    
            <div class="row pb-2 ">
    
              <div class="col-md-3">
                
                <div class="row"> 
                      <label for="ph_mostBookedPackageValue" class="form-label">1. What is the value of your most booked package? </label>
                      <textarea class="form-control" id="ph_mostBookedPackageValue" maxlength="2000" rows="1" formControlName="ph_mostBookedPackageValue" (blur)="onBlur('ph_mostBookedPackageValue')" [class.is-invalid]="ph_mostBookedPackageValue?.invalid && ph_mostBookedPackageValue?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ph_mostBookedPackageDays" class="form-label">2. The above package includes services for how many days? </label>          
                  <div *ngFor="let option of ph_daysOptions; let i = index">
                        <input type="radio" [id]="option" formControlName="ph_mostBookedPackageDays" [value]="option" (blur)="onBlur('ph_mostBookedPackageDays')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ph_mostBookedPackageServices" class="form-label">3. The above package includes which all services? </label>          
                  <div *ngFor="let option of ph_packageServices; let i = index">
                        <input type="radio" [id]="option" formControlName="ph_mostBookedPackageServices" [value]="option" (blur)="onBlur('ph_mostBookedPackageServices')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
              </div>
    
              <div class="col-md-1"></div>
    
              <div class="col-md-4">
                
                
                <div class="row "> 
                  <label for="ph_cancellationPolicyUser" class="form-label">4. What is your cancellation policy (if user cancels)? </label>          
                  <div *ngFor="let option of ph_cancellationUserOptions; let i = index">
                        <input type="radio" [id]="option+'clu'" formControlName="ph_cancellationPolicyUser" [value]="option" (blur)="onBlur('ph_cancellationPolicyUser')">
                        <label [for]="option+'clu'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4 "> 
                  <label for="ph_cancellationPolicySelf" class="form-label">5. What is your cancellation policy (if you initiate cancellation)? </label>          
                  <div *ngFor="let option of ph_cancellationSelfOptions; let i = index">
                        <input type="radio" [id]="option+'cls'" formControlName="ph_cancellationPolicySelf" [value]="option" (blur)="onBlur('ph_cancellationPolicySelf')">
                        <label [for]="option+'cls'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ph_cancellationPolicyTermsnConditions" class="form-label">6. What are the terms and conditions of your cancellation policy? Please describe in detail - e.g no refunds within a month of the wedding day or 50% amount refundable </label>
                  <textarea class="form-control" id="ph_cancellationPolicyTermsnConditions" maxlength="2000" rows="4" formControlName="ph_cancellationPolicyTermsnConditions" (blur)="onBlur('ph_cancellationPolicyTermsnConditions')" [class.is-invalid]="ph_cancellationPolicyTermsnConditions?.invalid && ph_cancellationPolicyTermsnConditions?.touched"></textarea>
                </div>
    
    
              </div>
    
              <div class="col-md-1"> </div>
    
              <div class="col-md-3 ">
                
                <div class="row "> 
                  <label for="ph_photographyDescription" class="form-label">7. Describe your photography in 3 words (e.g fun,vibrant and natural) </label>
                  <textarea class="form-control" id="ph_photographyDescription" maxlength="2000" rows="3" formControlName="ph_photographyDescription" (blur)="onBlur('ph_photographyDescription')" [class.is-invalid]="ph_photographyDescription?.invalid && ph_photographyDescription?.touched"></textarea>
                </div>
    
                <div class="row pt-4 ">         
                  <label for="ph_citiesNumber" class="form-label">8. How many cities have you covered weddings in till date? </label>
                  <textarea class="form-control" id="ph_citiesNumber" maxlength="2000" rows="3" formControlName="ph_citiesNumber" (blur)="onBlur('ph_citiesNumber')" [class.is-invalid]="ph_citiesNumber?.invalid && ph_citiesNumber?.touched"></textarea>
    
                </div>
    
                <div class="row pt-4"> 
                  <label for="ph_loveBecause" class="form-label">9. We love wedding photography because? </label>
                  <textarea class="form-control" id="ph_loveBecause" maxlength="2000" rows="4" formControlName="ph_loveBecause" (blur)="onBlur('ph_loveBecause')" [class.is-invalid]="ph_loveBecause?.invalid && ph_loveBecause?.touched"></textarea>
                </div>
    
    
              </div>
              
    
    
            </div>
    
            <hr>
    
            <div class="row pt-2">
    
              <div class="col-md-4">
    
                  <div class="row"> 
                      <label for="ph_smallEventprice" class="form-label">10. Price for covering a small event like an engagement or roka (Assume under 50 pax and 4 hrs of shoot photo and video) </label>
                      <textarea class="form-control" id="ph_smallEventprice" maxlength="2000" rows="1" formControlName="ph_smallEventprice" (blur)="onBlur('ph_smallEventprice')" [class.is-invalid]="ph_smallEventprice?.invalid && ph_smallEventprice?.touched"></textarea>
                  </div>
    
                  <div class="row pt-4"> 
                      <label for="ph_advanceBookingWeeks" class="form-label">11. How many weeks in advance should a booking be made? </label>
                      <textarea class="form-control" id="ph_advanceBookingWeeks" maxlength="2000" rows="1" formControlName="ph_advanceBookingWeeks" (blur)="onBlur('ph_advanceBookingWeeks')" [class.is-invalid]="ph_advanceBookingWeeks?.invalid && ph_advanceBookingWeeks?.touched"></textarea>
                  </div>
    
                  <div class="row pt-4"> 
                      <label for="ph_startYear" class="form-label">12. Which year did you start shooting weddings? </label>
                      <textarea class="form-control" id="ph_startYear" maxlength="2000" rows="1" formControlName="ph_startYear" (blur)="onBlur('ph_startYear')" [class.is-invalid]="ph_startYear?.invalid && ph_startYear?.touched"></textarea>
                  </div>
    
                  <div class="row pt-4"> 
                        <label for="ph_weeksToDeliver" class="form-label">13. How many weeks do you take to deliver the photos (Please respond such as 6 weeks, 7 weeks etc.) </label>
                        <textarea class="form-control" id="ph_weeksToDeliver" maxlength="2000" rows="1" formControlName="ph_weeksToDeliver" (blur)="onBlur('ph_weeksToDeliver')" [class.is-invalid]="ph_weeksToDeliver?.invalid &&ph_weeksToDeliver?.touched"></textarea>
                  </div>
    
    
              </div>
    
              <div class="col-md-1"></div>
    
              <div class="col-md-3">
    
                <div class="row">                  
                  <label for="ph_services" class="form-label">14. Which services do you offer? </label>  
                  
                  <!--
                  <div *ngFor="let option of ph_servicesList; let i = index">
                      <input type="radio" [id]="option+'svc'" formControlName="ph_services" [value]="option" (blur)="onBlur('ph_services')">
                      <label [for]="option+'svc'">&nbsp;{{ option }}</label>
                  </div>
                  -->
    
                  <div *ngFor="let option of ph_servicesList; let i = index">
                    <input type="checkbox" [id]="'option' + i" [value]="option" (change)="onPhCheckboxChange($event)">
                    <label [for]="'option' + i">&nbsp;{{ option}}</label>
                  </div>
    
                </div>
    
                <div class="row pt-4">                  
                  <label for="ph_specialityType" class="form-label">15. What is your speciality? </label>          
                  <div *ngFor="let option of ph_specialityList; let i = index">
                      <input type="radio" [id]="option+'spc'" formControlName="ph_specialityType" [value]="option" (blur)="onBlur('ph_specialityType')">
                      <label [for]="option+'spc'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
              </div>
    
              <div class="col-md-1"></div>
    
              <div class="col-md-3">
    
                <div class="row">                  
                  <label for="ph_specialityNumber" class="form-label">16. What is your preference? </label>          
                  <div *ngFor="let option of ph_specialitynum; let i = index">
                      <input type="radio" [id]="option+'spcn'" formControlName="ph_specialityNumber" [value]="option" (blur)="onBlur('ph_specialityNumber')">
                      <label [for]="option+'spcn'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4">                  
                  <label for="ph_paymentTerms" class="form-label">17. What are your payment terms? </label>          
                  <div *ngFor="let option of ph_paymentTermsList; let i = index">
                      <input type="radio" [id]="option+'pyt'" formControlName="ph_paymentTerms" [value]="option" (blur)="onBlur('ph_paymentTerms')">
                      <label [for]="option+'pyt'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4">                  
                  <label for="ph_travelLodgingCosts" class="form-label">18. Who bears cost of travel and lodging when travelling to a different city? </label>          
                  <div *ngFor="let option of ph_travelLodgingCostsList; let i = index">
                      <input type="radio" [id]="option+'pyt'" formControlName="ph_travelLodgingCosts" [value]="option" (blur)="onBlur('ph_travelLodgingCosts')">
                      <label [for]="option+'pyt'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
              </div>
    
    
    
            </div>
    
            <hr>
    
            <div class="row pb-2 ">
             
              <div class="col-md-5">
    
                <div class="row"> 
                  <label for="ph_candidTraditionalOneday" class="form-label">19. Price for candid photography and traditional photography one day package for wedding day (Assume 300 pax) </label>
                  <textarea class="form-control" id="ph_candidTraditionalOneday" maxlength="2000" rows="1" formControlName="ph_candidTraditionalOneday" (blur)="onBlur('ph_candidTraditionalOneday')" [class.is-invalid]="ph_candidTraditionalOneday?.invalid && ph_candidTraditionalOneday?.touched"></textarea>
              </div>
    
              </div>
    
              <div class="col-md-1"></div>
    
              <div class="col-md-5">
    
                <div class="row"> 
                  <label for="ph_candidTraditionalCinematic" class="form-label">20. Price for candid photography ,traditional photography and cinematic video one day package for wedding day (Assume 300 pax) </label>
                  <textarea class="form-control" id="ph_candidTraditionalCinematic" maxlength="2000" rows="1" formControlName="ph_candidTraditionalCinematic" (blur)="onBlur('ph_candidTraditionalCinematic')" [class.is-invalid]="ph_candidTraditionalCinematic?.invalid && ph_candidTraditionalCinematic?.touched"></textarea>
              </div>
    
              </div>
    
            </div>
    
          </div>
      
          <div *ngIf="isDecorator">
    
            <div class="row pb-2 ">
    
              <div class="col-md-3">
                
                <div class="row"> 
                      <label for="dr_enlistedHotels" class="form-label">1. Are you enlisted on the panel of any banquets/hotels? Please mention a few </label>
                      <textarea class="form-control" id="dr_enlistedHotels" maxlength="2000" rows="3" formControlName="dr_enlistedHotels" (blur)="onBlur('dr_enlistedHotels')" [class.is-invalid]="dr_enlistedHotels?.invalid && dr_enlistedHotels?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_serviceTypes" class="form-label">2. What are the main decor services provided by you? </label>          
                  <div *ngFor="let option of dr_decorServiceTypeList; let i = index">
                        <input type="radio" [id]="option" formControlName="dr_serviceTypes" [value]="option" (blur)="onBlur('dr_serviceTypes')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_indoorPriceRange" class="form-label">3. For an indoor banquet function, of approx 250 pax, what is the price range you are comfortable doing? Please specify min and max values </label>
                  <textarea class="form-control" id="dr_indoorPriceRange" maxlength="2000" rows="1" formControlName="dr_indoorPriceRange" (blur)="onBlur('dr_indoorPriceRange')" [class.is-invalid]="dr_indoorPriceRange?.invalid && dr_indoorPriceRange?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_outdoorPriceRange" class="form-label">4. For an outdoor function, what would the price range be? Please specify min and max values</label>
                  <textarea class="form-control" id="dr_outdoorPriceRange" maxlength="2000" rows="1" formControlName="dr_outdoorPriceRange" (blur)="onBlur('dr_outdoorPriceRange')" [class.is-invalid]="dr_outdoorPriceRange?.invalid && dr_outdoorPriceRange?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_celebrityWeddings" class="form-label">5. Please mention any celebrity weddings you may have done </label>
                  <textarea class="form-control" id="dr_celebrityWeddings" maxlength="2000" rows="3" formControlName="dr_celebrityWeddings" (blur)="onBlur('dr_celebrityWeddings')" [class.is-invalid]="dr_celebrityWeddings?.invalid && dr_celebrityWeddings?.touched"></textarea>
                </div>
    
              </div>
    
              <div class="col-md-1"></div>
    
              <div class="col-md-4">
    
                <div class="row"> 
                  <label for="dr_USP" class="form-label">6. What is your USP? </label>
                  <textarea class="form-control" id="dr_USP" maxlength="2000" rows="3" formControlName="dr_USP" (blur)="onBlur('dr_USP')" [class.is-invalid]="dr_USP?.invalid && dr_USP?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_mostExperiencedIn" class="form-label">7. What are you most experienced in? </label>          
                  <div *ngFor="let option of dr_mostExperiencedList; let i = index">
                        <input type="radio" [id]="option" formControlName="dr_mostExperiencedIn" [value]="option" (blur)="onBlur('dr_mostExperiencedIn')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_cancellationPolicyUser" class="form-label">8. What is your cancellation policy on user initiated cancellations? </label>          
                  <div *ngFor="let option of dr_cancellationUserOptions; let i = index">
                        <input type="radio" [id]="option" formControlName="dr_cancellationPolicyUser" [value]="option" (blur)="onBlur('dr_cancellationPolicyUser')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_cancellationPolicySelf" class="form-label">9. What is your cancellation policy on vendor initiated cancellations? </label>          
                  <div *ngFor="let option of dr_cancellationSelfOptions; let i = index">
                        <input type="radio" [id]="option" formControlName="dr_cancellationPolicySelf" [value]="option" (blur)="onBlur('dr_cancellationPolicySelf')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_cancelationTermsnConsitions" class="form-label">10. What are the terms amd conditions of your cancellation policy? Please describe in detail -e.g - no refund within a month of the wedding day or 50% amount refundable </label>
                  <textarea class="form-control" id="dr_cancelationTermsnConsitions" maxlength="2000" rows="3" formControlName="dr_cancelationTermsnConsitions" (blur)="onBlur('dr_cancelationTermsnConsitions')" [class.is-invalid]="dr_cancelationTermsnConsitions?.invalid && dr_cancelationTermsnConsitions?.touched"></textarea>
                </div>
    
              </div>
    
              <div class="col-md-1"></div>
              
              <div class="col-md-3">
    
                <div class="row"> 
                  <label for="dr_startYear" class="form-label">11. Which year did you start your company? </label>
                  <textarea class="form-control" id="dr_startYear" maxlength="2000" rows="1" formControlName="dr_startYear" (blur)="onBlur('dr_startYear')" [class.is-invalid]="dr_startYear?.invalid && dr_startYear?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_advanceBookingWeeks" class="form-label">12. How many weeks in advance should a booking be made to get a slot? </label>
                  <textarea class="form-control" id="dr_advanceBookingWeeks" maxlength="2000" rows="1" formControlName="dr_advanceBookingWeeks" (blur)="onBlur('dr_advanceBookingWeeks')" [class.is-invalid]="dr_advanceBookingWeeks?.invalid && dr_advanceBookingWeeks?.touched"></textarea>
                </div>
    
                
                <div class="row pt-4"> 
                  <label for="dr_indoorStartingPrice" class="form-label">13. What is the starting price for indoor decor? </label>
                  <textarea class="form-control" id="dr_indoorStartingPrice" maxlength="2000" rows="1" formControlName="dr_indoorStartingPrice" (blur)="onBlur('dr_indoorStartingPrice')" [class.is-invalid]="dr_indoorStartingPrice?.invalid && dr_indoorStartingPrice?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="dr_homefunctionStartingPrice" class="form-label">14. What is the starting package for home function decor?(e.g flowers and lights for haldi, house decoration,dinner party etc) </label>
                  <textarea class="form-control" id="dr_homefunctionStartingPrice" maxlength="2000" rows="1" formControlName="dr_homefunctionStartingPrice" (blur)="onBlur('dr_homefunctionStartingPrice')" [class.is-invalid]="dr_homefunctionStartingPrice?.invalid && dr_homefunctionStartingPrice?.touched"></textarea>
                </div>
    
              </div>
    
            </div>
          </div>
          
          <div *ngIf="isCharter">
    
            <div class="row pb-2 ">
    
              <div class="col-md-3">
                
                <div class="row"> 
                  <label for="ch_primaryServiceType" class="form-label">1. What is your primary service? </label>          
                  <div *ngFor="let option of ch_serviceTypeOptions; let i = index">
                        <input type="radio" [id]="option" formControlName="ch_primaryServiceType" [value]="option" (blur)="onBlur('ch_primaryServiceType')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ch_rentalCostYesNo" class="form-label">2. Do you have rental costs? </label>          
                  <div *ngFor="let option of ch_rentalCostOptions; let i = index">
                        <input type="radio" [id]="option+'ch1'" formControlName="ch_rentalCostYesNo" [value]="option" (blur)="onBlur('ch_rentalCostYesNo')">
                        <label [for]="option+'ch1'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ch_startYear" class="form-label">3. In which year did you start operations? </label>
                  <textarea class="form-control" id="ch_startYear" maxlength="2000" rows="1" formControlName="ch_startYear" (blur)="onBlur('ch_startYear')" [class.is-invalid]="ch_startYear?.invalid && ch_startYear?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ch_aircraftTypes" class="form-label">4. Which all aircraft types do you operate? </label>
                  <textarea class="form-control" id="ch_aircraftTypes" maxlength="2000" rows="3" formControlName="ch_aircraftTypes" (blur)="onBlur('ch_aircraftTypes')" [class.is-invalid]="ch_aircraftTypes?.invalid && ch_aircraftTypes?.touched"></textarea>
                </div>
    
              </div>
    
              <div class="col-md-1"></div>
           
              <div class="col-md-4">
          
                  <div class="row "> 
                    <label for="ch_startingPrice" class="form-label">5. What is the starting price for renting? </label>
                    <textarea class="form-control" id="ch_startingPrice" maxlength="2000" rows="1" formControlName="ch_startingPrice" (blur)="onBlur('ch_startingPrice')" [class.is-invalid]="ch_startingPrice?.invalid &&ch_startingPrice?.touched"></textarea>
                  </div>
    
                  <div class="row pt-4 "> 
                    <label for="ch_startingPriceBookingnTechnician" class="form-label">6. What is the starting price for booking alongwith technician? </label>
                    <textarea class="form-control" id="ch_startingPriceBookingnTechnician" maxlength="2000" rows="1" formControlName="ch_startingPriceBookingnTechnician" (blur)="onBlur('ch_startingPrice')" [class.is-invalid]="ch_startingPriceBookingnTechnician?.invalid &&ch_startingPriceBookingnTechnician?.touched"></textarea>
                  </div>
    
                  <div class="row pt-4"> 
                    <label for="ch_partialRoundsYesNo" class="form-label">7. Does your services include partial rounds? </label>          
                    <div *ngFor="let option of ch_partialRoundsOptions; let i = index">
                          <input type="radio" [id]="option+'ch2'" formControlName="ch_partialRoundsYesNo" [value]="option" (blur)="onBlur('ch_partialRoundsYesNo')">
                          <label [for]="option+'ch2'">&nbsp;{{ option }}</label>
                    </div>
                  </div>
    
                  <div class="row pt-4"> 
                    <label for="ch_alcoholPolicy" class="form-label">8. What is your alcohol policy? </label>          
                    <div *ngFor="let option of ch_alcoholPolicyOptions; let i = index">
                          <input type="radio" [id]="option+'ch3'" formControlName="ch_alcoholPolicy" [value]="option" (blur)="onBlur('ch_alcoholPolicy')">
                          <label [for]="option+'ch3'">&nbsp;{{ option }}</label>
                    </div>
                  </div>
    
            
              </div>
    
              <div class="col-md-1"></div>
    
              <div class="col-md-3">
    
                <div class="row "> 
                  <label for="ch_recognitions" class="form-label">9. What recognitions does your company hold? </label>
                  <textarea class="form-control" id="ch_recognitions" maxlength="2000" rows="3" formControlName="ch_recognitions" (blur)="onBlur('ch_recognitions')" [class.is-invalid]="ch_recognitions?.invalid &&ch_recognitions?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ch_registeredYesNo" class="form-label">10. Are your services registered with the local authorities and government? </label>          
                  <div *ngFor="let option of ch_registeredOptions; let i = index">
                        <input type="radio" [id]="option+'ch4'" formControlName="ch_registeredYesNo" [value]="option" (blur)="onBlur('ch_registeredYesNo')">
                        <label [for]="option+'ch4'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
              </div>
    
            </div>
    
             
    
          </div>
    
          <div *ngIf="isMakeupartist">
    
            <div class="row pb-2 ">
    
              <div class="col-md-3">
    
                <div class="row"> 
                  <label for="mk_hotelspanels" class="form-label">1. Are you listed on the panels of any hotels or venues? Please mention a few </label>
                  <textarea class="form-control" id="mk_hotelspanels" maxlength="2000" rows="3" formControlName="mk_hotelspanels" (blur)="onBlur('mk_hotelspanels')" [class.is-invalid]="mk_hotelspanels?.invalid && mk_hotelspanels?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="mk_usp" class="form-label">2. What is your USP? </label>
                  <textarea class="form-control" id="mk_usp" maxlength="2000" rows="3" formControlName="mk_usp" (blur)="onBlur('mk_usp')" [class.is-invalid]="mk_usp?.invalid && mk_usp?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="mk_startyear" class="form-label">3. In which year did you start practicing makeup professionally? </label>
                  <textarea class="form-control" id="mk_startyear" maxlength="45" rows="1" formControlName="mk_startyear" (blur)="onBlur('mk_startyear')" [class.is-invalid]="mk_startyear?.invalid && mk_startyear?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="mk_cities" class="form-label">4. In how many cities have you done wedding makeups till now? </label>
                  <textarea class="form-control" id="mk_cities" maxlength="45" rows="1" formControlName="mk_cities" (blur)="onBlur('mk_cities')" [class.is-invalid]="mk_cities?.invalid && mk_cities?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="mk_prominentclients" class="form-label">5. Please mention any prominent clients you have worked with </label>
                  <textarea class="form-control" id="mk_prominentclients" maxlength="2000" rows="3" formControlName="mk_prominentclients" (blur)="onBlur('mk_prominentclients')" [class.is-invalid]="mk_prominentclients?.invalid && mk_prominentclients?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="mk_startingrate" class="form-label">6. What is your starting rate for a wedding engagement? </label>
                  <textarea class="form-control" id="mk_startingrate" maxlength="45" rows="1" formControlName="mk_startingrate" (blur)="onBlur('mk_startingrate')" [class.is-invalid]="mk_startingrate?.invalid && mk_startingrate?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="mk_addguests" class="form-label">7. How many additional guests apart from bride and groom are covered in the above rate? </label>
                  <textarea class="form-control" id="mk_addguests" maxlength="45" rows="1" formControlName="mk_addguests" (blur)="onBlur('mk_addguests')" [class.is-invalid]="mk_addguests?.invalid && mk_addguests?.touched"></textarea>
                </div>
                
               
    
              </div>
    
              <div class="col-md-1"></div>
           
              <div class="col-md-3">
    
                            
                <div class="row "> 
                  <label for="mk_costpolicy" class="form-label">8. Who bears cost of travel and lodging when travelling to a different city? </label>          
                  <div *ngFor="let option of mk_travelLodgingCostsList; let i = index">
                        <input type="radio" [id]="option" formControlName="mk_costpolicy" [value]="option" (blur)="onBlur('mk_costpolicy')">
                        <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="mk_daysadvance" class="form-label">9. How many weeks in advance should a booking be done to get a slot? </label>
                  <textarea class="form-control" id="mk_daysadvance" maxlength="45" rows="1" formControlName="mk_daysadvance" (blur)="onBlur('mk_daysadvance')" [class.is-invalid]="mk_daysadvance?.invalid && mk_daysadvance?.touched"></textarea>
                </div>
              
    
              <div class="row pt-4"> 
                <label for="mk_cancellationuser" class="form-label">10. What is your cancellation policy (if customer initiates cancellation)? </label>          
                <div *ngFor="let option of mk_cancellationUserOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="mk_cancellationuser" [value]="option" (blur)="onBlur('mk_cancellationuser')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_cancellationvendor" class="form-label">11. What is your cancellation policy (if vendor initiates cancellation)? </label>          
                <div *ngFor="let option of mk_cancellationSelfOptions; let i = index">
                      <input type="radio" [id]="option+i" formControlName="mk_cancellationvendor" [value]="option" (blur)="onBlur('mk_cancellationvendor')">
                      <label [for]="option+i">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label class="form-label">12. Which of the following do you offer? </label>
                <div *ngFor="let option of mk_servicesofferedOptions; let i = index">
                  <input type="checkbox" [id]="'option' + i" [value]="option" (change)="onmkservicesCheckboxChange($event)">
                  <label [for]="'option' + i">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_products" class="form-label">13. Which Products do you use for bridal makeup? </label>
                <textarea class="form-control" id="mk_products" maxlength="45" rows="1" formControlName="mk_products" (blur)="onBlur('mk_products')" [class.is-invalid]="mk_products?.invalid && mk_products?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_traveltovenue" class="form-label">14. Do you travel to the venue? </label>          
                <div *ngFor="let option of mk_travelOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="mk_traveltovenue" [value]="option" (blur)="onBlur('mk_traveltovenue')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
                
    
              </div>
    
          
              <div class="col-md-1"></div>
    
              <div class="col-md-3">
    
                <div class="row"> 
                  <label for=" mk_travelprice" class="form-label">15. If you travel outside of your hometown for bridal makeup, how much do
                    you charge for one event? </label>
                  <textarea class="form-control" id="mk_travelprice" maxlength="45" rows="1" formControlName="mk_travelprice" (blur)="onBlur('mk_travelprice')" [class.is-invalid]="mk_travelprice?.invalid && mk_travelprice?.touched"></textarea>
                </div>
              
    
              <div class="row pt-4"> 
                <label class="form-label">16.  What does the bridal makeup price include? </label>          
                <div *ngFor="let option of mk_bridalmakeupincludeoptions; let i = index">
                  <input type="checkbox" [id]="'option' + i*10" [value]="option" (change)="onmkpriceinclCheckboxChange($event)">
                  <label [for]="'option' + i*10">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_airbrushservice" class="form-label">17. Do you offer Airbrush Services? </label>          
                <div *ngFor="let option of mk_airbrushservicesOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="mk_airbrushservice" [value]="option" (blur)="onBlur('mk_airbrushservice')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              
              <div class="row pt-4"> 
                <label for="mk_trialspolicy" class="form-label">18. Explain your trials policy </label>          
                <div *ngFor="let option of mk_trialsPolicyOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="mk_trialspolicy" [value]="option" (blur)="onBlur('mk_trialspolicy')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_awards" class="form-label">19. Please mention if you have received any awards </label>          
                <textarea class="form-control" id="mk_awards" maxlength="1000" rows="3" formControlName="mk_awards" (blur)="onBlur('mk_awards')" [class.is-invalid]="mk_awards?.invalid && mk_awards?.touched"></textarea>
              </div>
    
            </div>
    
            </div>
    
          </div>
    
        
    
          <div *ngIf="isCaterer">
            <p>Caterer Details Section</p>
          </div>
    
        </div>
      
    
             
    
          <!----------------------------Buttons--------------------------->
        
          <div class="row m-3 p-3 "> 
            <div class="col-md-4">
            </div>
    
            <div class="col-md-4">
              <button class="border button-style-3 px-5 py-2" type="submit" >Register</button>
            </div>
    
            <div class="col-md-4">
              <button class="border button-style-3 px-5 py-2" type="button"  (click)="resetForm()">Clear Form</button>
            </div>
    
            <div class="col-md-4">
            </div>
          </div>
    
    
        </form>
    
    </div>
    
    <div class="container-fluid mt-3 pt-24 text-yellow-900 d-block d-md-none">
    
      <form [formGroup]="vendorForm" (ngSubmit)="onSubmit()">
    
        <div class="row">
    
          <div class="col-12 pt-2">
    
            <div class="row">
              <div class="col-4 flex justify-start ">
                  <app-goback></app-goback>
              </div>
    
              <div class="col-8">
                <div class="flex justify-start soulsWedSubheadingFont mb-4">
                  <b>Vendor Registration</b>
                </div>
              </div>
    
            </div>
    
             
            
    
            
              <!-- Select country and category -->
          <div class="row  m-3 p-2 flex items-center custom-border">
    
              <!-- Country Drop Down --------------->
              <div class="col-12">
                  <label for="country" class="form-label soulsWedHeadingFontGray">Country *</label>
                  <select class="form-select" id="country" formControlName="country" (change)="onCountrySelect($event)">
                    <option *ngFor="let country of countries" [value]="country.idcountry">{{ country.countryName }}</option>
                  </select>
              </div>
    
              <!-- Category Drop Down --------------->
              <div class="col-12">
                  <label for="category" class="form-label soulsWedHeadingFontGray">Category *</label>
                  <select class="form-select" id="category" formControlName="category"  (change)="onCategorySelect($event)">
                      <option *ngFor="let category of categories" [value]="category.idcategory">{{ category.categoryName }}</option>
                  </select>
              </div>
    
          </div>
      
              
        <!-- Section 2: Vendor Details -->
        <div class=" row custom-border m-3 p-3 ">
          <div class="pt-1 pb-2 flex justify-center soulsWedSubheadingFont mb-4">
            <b>Vendor Details</b>
          </div>
    
        <div class="col-12">
          <label for="vendorName" class="form-label">Vendor Name *</label>
          <input type="text" class="form-control" id="vendorName" formControlName="vendorName" maxlength="200"  (blur)="onBlur('vendorName')" [class.is-invalid]="vendorName?.invalid && vendorName?.touched">
          <div *ngIf="vendorName?.invalid && vendorName?.touched" class="invalid-feedback">
            <div *ngIf="vendorName?.errors?.['required']">Vendor name is required.</div>
          </div>
        </div>
    
         <!-- Select email and mobile -->
         <div class="row pt-3">
    
          <!---------- Email --------------->
          <div class="col-12">
              <label for="email" class="form-label">Email ID *</label>
              <input type="email" class="form-control" id="email" formControlName="email" (blur)="onBlur('vendorName')" [class.is-invalid]="email?.invalid && email?.touched">
              <small id="emailHelp" class="form-text text-muted"><i>Your email-id will be your user id for Soulswed</i></small>
              <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
                <div *ngIf="email?.errors?.['required']">Email is required.</div>
                <div *ngIf="email?.errors?.['email']">Please enter a valid email address.</div>
              </div>
          </div>
    
        
    
    
           <!-- Country Drop Down --------------->
           <div class="col-12">
              <label for="countryCode" class="form-label">Phone Country Code </label>
              <select class="form-select" id="countryCode" formControlName="countryCode">
                <option *ngFor="let phoneCode of phoneCountryCodes" [value]="phoneCode.phoneCode">{{ phoneCode.countryName}}&nbsp;&nbsp;{{phoneCode.phoneCode }}</option>
              </select>
          </div>
    
          <!---------- Phone Number --------------->
          <div class="col-12">
              <label for="phone" class="form-label">Phone Number </label>
              <input type="text" class="form-control" id="phone" formControlName="phone" (blur)="onBlur('phone')" [class.is-invalid]="phone?.invalid && phone?.touched">
              <div *ngIf="phone?.invalid && phone?.touched" class="invalid-feedback">
                <div *ngIf="phone?.errors?.['required']">Mobile number is required.</div>
                <div *ngIf="phone?.errors?.['pattern']">Please enter a valid mobile number.</div>
              </div>
          </div>
    
        </div>
    
        <!-------------------------- Address Fields------------------->
        
       <div class="row pt-3 ">
    
           <!-- City Drop Down --------------->
           <div class="col-12">
            <label for="city" class="form-label">City </label>
            <select class="form-select" id="city" formControlName="city">
              <option value=""> </option>
              <option *ngFor="let city of cities" [value]="city.cityname">{{city.cityname}}</option>
            </select>
          </div>
    
          <!---------- Addess Lines --------------->
          <div class="col-12">
            <label for="Addessline" class="form-label">Addess </label>
            <input type="text" class="form-control" id="Addessline1" maxlength="200"   formControlName="addressLine1" (blur)="onBlur('addressLine1')" [class.is-invalid]="addressLine1?.invalid && addressLine1?.touched">
            <div *ngIf="addressLine1?.invalid && addressLine1?.touched" class="invalid-feedback">
              <div *ngIf="addressLine1?.errors?.['required']">Address is required.</div>
            </div>
          </div>
    
       
       </div>
    
      </div>
    
          </div>
    
          <div class="col-10 px-4">
    
              <google-map height="260px"
    
                width="300px"
                [center]="center"
                [zoom]="zoom"
                (mapClick)="moveMap($event)"
                (mapMousemove)="move($event)">
    
                <div *ngIf="locationChosen">
                    <map-marker [position]="center"></map-marker>
                </div>
    
              </google-map>
    
              <!--
              <div>Latitude: {{display?.lat}}</div>
              <div>Longitude: {{display?.lng}}</div>
              -->
    
            <!--
              <img class="mt-10" src="../../assets/images/VendorImages/img1.jpg">
            -->
          </div>
        </div>
    
        
        
        <!-- Section 3: Service Details -->
        <div class=" row m-3 p-3 custom-border">
          <div class="pt-2 flex justify-center soulsWedSubheadingFont mb-4">
            <b>Service Details</b>
          </div>
          
          <!-------------------------- Service Description -------------------->
          <div class="row">
            <div class="col-12">
              <label for="servicedescription" class="form-label">Describe your service (max 5000 characters) </label>
              <textarea class="form-control" id="servicedescription" maxlength="5000" rows="6" formControlName="serviceDescription" (blur)="onBlur('serviceDescription')" [class.is-invalid]="serviceDescription?.invalid && serviceDescription?.touched"></textarea>
              <div *ngIf="serviceDescription?.invalid && serviceDescription?.touched" class="invalid-feedback">
                <div *ngIf="serviceDescription?.errors?.['required']">Service Description is required.</div>
              </div>
            </div>
          </div> 
    
          <!--
          ------------------------ Service Tagline --------------------
          <div class="row pt-3">
            <div class="col-md-12">
              <label for="tagline" class="form-label">Your Tagline (This will appear in our listings)</label>
              <input type="text" class="form-control" id="tagline" formControlName="tagline" maxlength="100">
            </div>
          </div> 
    
        -->
    
          <!-- Starting price and price basis -->
    
          <div class="row pt-3">
            <div class="col-12">
              <div class="dropdown">
                <label for="dropdownMenuButton" class="form-label">Currency</label><br>
                <input type="text" class="form-control" id="currency" formControlName="currency" maxlength="100" readonly>
            </div>
            </div>
         
    
          <!-------------------------- Service Starting Price -------------------->
          <div class="col-12">
              <label for="Price" class="form-label">Starting Price </label>
              <input type="text" class="form-control" id="price" formControlName="price" (blur)="onBlur('price')" [class.is-invalid]="price?.invalid && price?.touched">
              <div *ngIf="price?.invalid && price?.touched" class="invalid-feedback">
                <div *ngIf="price?.errors?.['required']">Starting Price is required.</div>
                <div *ngIf="price?.errors?.['pattern']">Please enter a valid number</div>
              </div>
          </div>
    
          <!-- Starting price and price basis -->
          <div class="col-12">
            <label for="pricebasis" class="form-label">Price Basis</label>
            <select class="form-select" id="pricebasis" formControlName="pricebasis">
              <option *ngFor="let pricebasis of priceBases" [value]="pricebasis">{{ pricebasis}}</option>
            </select>
        </div>
    
    
          <!-------- Show capacity only if aplplicable for the category --------------------->
          
            <div *ngIf="isCapacityApplicable" class="col-12">
              <label for="Capacity" class="form-label">Capacity</label>
              <input type="text" class="form-control" id="capacity" formControlName="capacity" (blur)="onBlur('capacity')" [class.is-invalid]="capacity?.invalid && capacity?.touched">
              <div *ngIf="capacity?.invalid && capacity?.touched" class="invalid-feedback">
                <div *ngIf="capacity?.errors?.['pattern']">Please enter a valid number</div>
              </div>
          </div>
    
          <!-- show category if applicable  -->
          <div *ngIf="isHotelCategoryApplicable" class="col-12">
            <label for="hotelcategory" class="form-label">Category</label>
            <select class="form-select" id="hotelcategory" formControlName="vendorrating">
              <option *ngFor="let cat of hotelcategories" [value]="cat">{{ cat}}</option>
            </select>
        </div>
    
          </div>
    
          <!-- Payment schedule -->
          <div class="row pt-3 pb-3">
              <div class="col-12">
                <label for="advance" class="form-label">Minimum Advance Payment % </label>
                <input type="text" class="form-control" id="advance" formControlName="advance" (blur)="onBlur('advance')" [class.is-invalid]="advance?.invalid && advance?.touched">
                <div *ngIf="advance?.invalid && advance?.touched" class="invalid-feedback">
                    <div *ngIf="advance?.errors?.['required']">Advance payment % is required.</div>
                    <div *ngIf="advance?.errors?.['pattern']">Please enter a valid number (0-100 with up to 2 decimal places).</div>
                    <div *ngIf="advance?.errors?.['min']">Value must be between 0 and 100</div>
                    <div *ngIf="advance?.errors?.['max']">Value must be between 0 and 100</div>
                </div>
              </div>
    
              <div class="col-12">
                <label for="balancedays" class="form-label">Balance payment to be made by (days prior to event) </label>
                <input type="text" class="form-control" id="balancedays" formControlName="balancedays" (blur)="onBlur('balancedays')" [class.is-invalid]="balancedays?.invalid && balancedays?.touched">
                <div *ngIf="balancedays?.invalid && balancedays?.touched" class="invalid-feedback">
                    <div *ngIf="balancedays?.errors?.['required']">Please enter days prior event for balance payment</div>
                    <div *ngIf="balancedays?.errors?.['pattern']">Please enter a valid number</div>
                </div>
              </div>
    
          </div>
    
               <!----------- Show Amenities if applicable     -->
          
               <div *ngIf="isAmenitiesApplicable">
    
                    <div class="row pt-4">
                          <div class="col-12">
                              <h2> Amenities</h2>
                          </div>
                    </div>
    
                    <div class="row pb-4">
                      <div class="col-12 pb-2" *ngFor="let amenity of vendorAmenities; let i = index">    
                        <input type="checkbox" [id]="'amenity-' + i" [checked]="amenity.available" (change)="updateAvailability(i)">
                        <label [for]="'amenity-' + i">&nbsp;{{amenity.amenityName}}</label>
                      </div>       
                    </div>
                
            
                 </div>
            
    
            <!-- Image and Video Upload -->
    
           <div class="row pt-4 pb-4">
    
              <div class="col-12">
                  <label for="images" class="form-label">Upload Images</label>
                  <input type="file" class="form-control" id="images" formControlName="password" multiple accept="image/*" (change)="onImageFileSelected($event)">
                  <small id="imageHelp" class="form-text text-muted"><i>You can select all images at once and upload</i></small>
              </div>
              
    
            
    
              <div class="col-12">
                  <label for="video" class="form-label">Upload Video </label>
                  <input type="file" class="form-control" formControlName="confirmPassword" id="video" multiple accept="video/*" (change)="onVideoSelected($event)">
              </div>
    
           </div>
    
        
    </div>
    
     <!-- Section 4: Vendor FAQ -->
    
    
     <div class=" row custom-border m-3 p-3 ">
      <div class="pt-1 pb-2 flex justify-center soulsWedSubheadingFont mb-4">
        <b>Vendor FAQ</b>
      </div>
    
    
        <div *ngIf="isVenue">
    
          <div class="row pb-2 ">
    
            <div class="col-md-4 ">
              <div >
                <label class="form-label">1. Describe your primary venue type</label>
                <div *ngFor="let option of primaryVenueDescriptions; let i = index">
                  <input type="radio" [id]="option" formControlName="venue_primaryDescription" [value]="option" (blur)="onBlur('venue_primaryDescription')">
                  <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
            </div>
    
            <div class="col-md-3">
    
              <div class="row">
    
                <div class="col-md-12">
                  <label class="form-label">2. Do you have a rental cost along with per plate cost?</label>
                  <div *ngFor="let option of venueTypeOptions; let i = index">
                    <input type="radio" [id]="option" formControlName="venue_venueType" [value]="option" (blur)="onBlur('venue_venueType')">
                    <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="'col-md-12 pt-3">
                  <label for="venue_startyear" class="form-label">3. In which year did you start operations in your venue? </label>
                  <input type="text" class="form-control" id="venue_startyear" maxlength="4"   formControlName="venue_startyear" (blur)="onBlur('venue_startyear')" >
                </div>
    
                <div class="col-md-12 pt-3">
                  <label class="form-label">4. Does your venue include on site accommodation?</label>
                  <div *ngFor="let option of onsiteaccomodations; let i = index">
                    <input type="radio" [id]="option" formControlName="venue_onsiteaccomodation" [value]="option" (blur)="onBlur('venue_onsiteaccomodation')">
                    <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
                      
              </div>
            </div>
    
            <div class="col-md-1"></div>
            
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <label for="venue_vegStartPrice" class="form-label">5. What is the starting price for vegetarian menu? (assume 250 pax and standard
                    menu) – if the price is for smaller or larger numbers, please specify the number of
                    persons </label>
                  <input type="text" class="form-control" id="venue_vegStartPrice" maxlength="4"   formControlName="venue_vegStartPrice" (blur)="onBlur('venue_vegStartPrice')" >
                </div>
    
                <div class="col-md-12 pt-3">
                  <label for="venue_nonvegStartPrice" class="form-label">6. What is the starting price for non-vegetarian menu? (assume 250 pax and standard
                    menu) – if the price is for smaller or larger numbers, please specify the number of
                    persons </label>
                  <input type="text" class="form-control" id="venue_nonvegStartPrice" maxlength="4"   formControlName="venue_nonvegStartPrice" (blur)="onBlur('venue_nonvegStartPrice')" >
                </div>
                      
              </div>
            </div>
    
          </div>
          <hr>
          <div class="row pt-2">
    
            <div class="col-md-4">
              <div>
                <label class="form-label">7. Venue type</label>
                <div *ngFor="let option of venueTypeCheckOptions; let i = index">
                  <input type="checkbox" [id]="'option' + i" [value]="option.value" (change)="onCheckboxChange($event)">
                  <label [for]="'option' + i">&nbsp;{{ option.value }}</label>
                </div>
              </div>
            </div>
    
            <div class="col-md-3">
    
              <div class="row">
    
                <div class="'col-md-12 ">
                  <label for="venue_startyear" class="form-label">8. How many rooms are available in your accommodation? </label>
                  <input type="text" class="form-control" id="venue_roomsavailable" maxlength="4"   formControlName="venue_roomsavailable" (blur)="onBlur('venue_roomsavailable')" >
                </div>
    
                <div class="'col-md-12 pt-3">
                  <label for="venue_startyear" class="form-label">9. What is the starting price for a basic room at your hotel? </label>
                  <input type="text" class="form-control" id="venue_roomsprice" maxlength="4"   formControlName="venue_roomsprice" (blur)="onBlur('venue_roomsprice')" >
                </div>
                      
              </div>
            </div>
    
            <div class="col-md-1"></div>
            
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">10. What is your policy on catering?</label>
                    <div *ngFor="let option of cateringPolicyOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_cateringpolicy" [value]="option" (blur)="onBlur('venue_cateringpolicy')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
                      
              </div>
            </div>
    
          </div>
          <hr>
          <div class="row pt-2">
    
            <div class="col-md-4">
              <div class="col-md-12 ">
                <div>
                  <label class="form-label">11. What is your policy on alcohol?</label>
                  <div *ngFor="let option of alchoholPolicyOptions; let i = index">
                    <input type="radio" [id]="option" formControlName="venue_alchoholpolicy" [value]="option" (blur)="onBlur('venue_alchoholpolicy')">
                    <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">12. What is your policy on DJ's?</label>
                    <div *ngFor="let option of djPolicyOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_djpolicy" [value]="option" (blur)="onBlur('venue_djpolicy')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
                      
              </div>
            </div>
    
            
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">13. What is your policy on decor?</label>
                    <div *ngFor="let option of decorPolicyOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_decorpolicy" [value]="option" (blur)="onBlur('venue_decorpolicy')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
    
                <div class="'col-md-12 pt-3">
                  <label for="venue_decorprice" class="form-label">14. What is the minimum starting price to decorate your venue? </label>
                  <input type="text" class="form-control" id="venue_decorprice" maxlength="4"   formControlName="venue_decorprice" (blur)="onBlur('venue_decorprice')" >
                </div>
                      
              </div>
            </div>
    
          </div>
          <hr>
    
          <div class="row pt-2">
    
            <div class="col-md-4">
              <div class="col-md-12 ">
                <div>
                  <label class="form-label">15. Do you also allow small size gatherings (&lt;50) ?  </label>
                  <div *ngFor="let option of smallGatheringsOptions; let i = index">
                    <input type="radio" [id]="option" formControlName="venue_smallgatherings" [value]="option" (blur)="onBlur('venue_smallgatherings')">
                    <label [for]="option">&nbsp;{{ option }}</label>
                  </div>
                </div>
              </div>
            </div>
    
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">16. Is parking available at the venue?</label>
                    <div *ngFor="let option of venueparkingOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_parking" [value]="option" (blur)="onBlur('venue_parking')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
                      
              </div>
            </div>
    
            
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">17. What is your USP?</label>
                    <textarea class="form-control" id="venue_USP" maxlength="1999"   formControlName="venue_USP" (blur)="onBlur('venue_USP')" > </textarea>
                  </div>
                </div>
    
             
                      
              </div>
            </div>
    
          </div>
    
          <div class="row pt-4">
    
            <div class="col-md-4">
    
              <div class="col-md-12 ">
                <div>
                  <label class="form-label">18. How many weeks in advance should a booking be made?  </label>                
                  <input type="text" class="form-control" id="venue_weeksadvance" maxlength="20"   formControlName="venue_weeksadvance" (blur)="onBlur('venue_weeksadvance')"  >
                  
                  </div>
                </div>
              
            </div>
    
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">19. Please describe your cancellation policy ( if a user initiates cancellation) including whether you
                      provide refunds of booking amounts , and terms for doing so</label>
                    <div *ngFor="let option of venue_cancellationpolicyuseroptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_cancellationpolicyuser" [value]="option" (blur)="onBlur('venue_cancellationpolicyuser')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
                      
              </div>
            </div>
    
            
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">20. Please describe your cancellation policy ( if vendor initiates cancellation) including whether you
                      provide refunds of booking amounts , and terms for doing so</label>
                    <div *ngFor="let option of venue_cancellationpolicyvendoroptions; let i = index">
                      <input type="radio" [id]="option" formControlName="venue_cancellationpolicyvendor" [value]="option" (blur)="onBlur('venue_cancellationpolicyvendor')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
    
             
                      
              </div>
            </div>
    
          </div>
    
          <div class="row pt-4">
    
            <div class="col-md-3">
    
             
             
                <div>
                  <label class="form-label" class="wrap-text">21. What are the terms & conditions of your cancellation policy? ( please describe in detail eg No
                    refunds within a month of the wedding day or 50% amount refundable)  </label>                
                  <textarea class="form-control" id="venue_cancellationtnc" maxlength="4500"  rows="5" formControlName="venue_cancellationtnc" (blur)="onBlur('venue_cancellationtnc')" > </textarea>
                  
                  </div>
         
              
            </div>
    
            <div class="col-md-1"></div>
    
            <div class="col-md-4">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                    <label class="form-label">22. Please select whatever is applicable for your venue </label>
                    <div *ngFor="let option of venue_allapplicableoptions; let i = index">
                      <input type="checkbox" [id]="'option' + i" [value]="option" (change)="onAllApplicableCheckboxChange($event)">
                      <label [for]="'option' + i">&nbsp;{{ option }}</label>
                    </div>
                  </div>
                </div>
                      
              </div>
            </div>
    
            
            <div class="col-md-3">
    
              <div class="row">
    
                <div class="col-md-12 ">
                  <div>
                   
                  </div>
                </div>
    
             
                      
              </div>
            </div>
    
          </div>
    
        </div>
          
    
        <div *ngIf="isHotel">
    
          <div class="row pb-2 ">
    
            <div class="col-md-4 ">
              <label for="couple_policy" class="form-label">1. What is your policy on couples?</label>
              <div *ngFor="let option of couplePolicyOptions; let i = index">
                <input type="radio" [id]="option" formControlName="couple_policy" [value]="option" (blur)="onBlur('couple_policy')">
                <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="col-md-4 ">
              <label for="smoking_policy" class="form-label">2. What is your policy on smoking?</label>
              <div *ngFor="let option of smokingOptions; let i = index">
                <input type="radio" [id]="option" formControlName="smoking_policy" [value]="option" (blur)="onBlur('smoking_policy')">
                <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="col-md-4 ">
              <label for="parking_available" class="form-label">3. Is parking available for guests?</label>
              <div *ngFor="let option of yesNoArray; let i = index">
                <input type="radio" [id]="option+'par'" formControlName="parking_available" [value]="option" (blur)="onBlur('parking_available')">
                <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
          </div>
    
          <div class="row pb-2 pt-4 ">
    
            <div class="col-md-4 ">
              <label for="extra_beds" class="form-label">4. Can you provide extra beds in rooms?</label>
              <div *ngFor="let option of yesNoArray; let i = index">
                <input type="radio" [id]="option+'e'" formControlName="extra_beds" [value]="option" (blur)="onBlur('extra_beds')">
                <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="col-md-4 ">
              <label for="pets_policy" class="form-label">5. Do you allow pets?</label>
              <div *ngFor="let option of yesNoArray; let i = index">
                <input type="radio" [id]="option+'p'" formControlName="pets_policy" [value]="option" (blur)="onBlur('pets_policy')">
                <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="col-md-4">
              <label for="days_cancellation" class="form-label">6. How many days in advance can guests cancel free of charge?</label>
              <input type="text" class="form-control" id="days_cancellation" maxlength="4"   formControlName="days_cancellation" (blur)="onBlur('days_cancellation')" >
            </div>
    
          </div>
    
          <div class="row pb-2 pt-4 ">
    
            <div class="col-md-4 ">
              <label for="creditcards_policy" class="form-label">7. Can you charge credit cards at your property?</label>
              <div *ngFor="let option of yesNoArray; let i = index">
                <input type="radio" [id]="option+'c'" formControlName="creditcards_policy" [value]="option" (blur)="onBlur('creditcards_policy')">
                <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>       
    
          <div class="col-md-4 ">
            <label for="otherpayment_methods" class="form-label">8. Specify the other types of payments you accept</label>
            <textarea class="form-control" id="otherpayment_methods" maxlength="2000" rows="4" formControlName="otherpayment_methods" (blur)="onBlur('otherpayment_methods')" [class.is-invalid]="otherpayment_methods?.invalid && otherpayment_methods?.touched"></textarea>
          </div>
    
        </div>
            
        </div>
    
        <div *ngIf="isPlanner">
    
          <div class="row pb-2 ">
    
            <div class="col-md-4 ">
              <div>
                <label for="planner_USP" class="form-label">1. Describe your USP </label>
                  <textarea class="form-control" id="planner_USP" maxlength="2000" rows="4" formControlName="planner_USP" (blur)="onBlur('planner_USP')" [class.is-invalid]="planner_USP?.invalid && planner_USP?.touched"></textarea>
              </div>
            </div>
    
            <div class="col-md-4 ">
              <div>
                <label for="planner_hotelslist" class="form-label">2. Please mention any hotels/banquet halls panels you are enlisted on</label>
                  <textarea class="form-control" id="planner_hotelslist" maxlength="2000" rows="4" formControlName="planner_hotelslist" (blur)="onBlur('planner_hotelslist')" [class.is-invalid]="planner_hotelslist?.invalid && planner_hotelslist?.touched"></textarea>
              </div>
            </div>
    
            <div class="col-md-4 ">
              <div>
                <label for="planner_cities" class="form-label">3. Cities where you have previously planned weddings/parties </label>
                  <textarea class="form-control" id="planner_cities" maxlength="2000" rows="4" formControlName="planner_cities" (blur)="onBlur('planner_cities')" [class.is-invalid]="planner_cities?.invalid && planner_cities?.touched"></textarea>
              </div>
            </div>
    
    
          </div>
    
          <div class="row pt-4 pb-2 ">
    
            <div class="col-md-4 ">
                <label for="planner_startingPackage" class="form-label">4. What is your starting package for planning a 3-day wedding?. </label>
                <textarea class="form-control" id="planner_startingPackage" maxlength="2000" rows="2" formControlName="planner_startingPackage" (blur)="onBlur('planner_startingPackage')" [class.is-invalid]="planner_startingPackage?.invalid && planner_startingPackage?.touched"></textarea>      
            </div>
    
            <div class="col-md-4">
              <label for="planner_decorPolicy" class="form-label">5. What is your policy on decor?</label>
              <div *ngFor="let option of plannerDecorPolicyOptions; let i = index">
                <input type="radio" [id]="option" formControlName="planner_decorPolicy" [value]="option" (blur)="onBlur('planner_decorPolicy')">
                <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="col-md-4 ">
              <label for="planner_commercialPlan" class="form-label">6. What kind of a commercial plan do you have?</label>
                
                <br><input type="radio" [id]="1" formControlName="planner_commercialPlan" value="We charge a Fixed Fee for Planning" (blur)="onBlur('planner_commercialPlan')">
                <label [for]="1">&nbsp; We charge a Fixed Fee for Planning </label>
    
               <br> <input type="radio" [id]="2" formControlName="planner_commercialPlan" value="We charge a percentage of the wedding cost" (blur)="onBlur('planner_commercialPlan')">
                <label [for]="2">&nbsp; We charge a percentage of the wedding cost </label>
    
               <br> <label for="planner_commercialPlanPerc" class="form-label"> If percentage of wedding cost, then what percentage? </label>
                <textarea class="form-control" id="planner_commercialPlanPerc" maxlength="500" rows="1" formControlName="planner_commercialPlanPerc" (blur)="onBlur('planner_commercialPlanPerc')" [class.is-invalid]="planner_commercialPlanPerc?.invalid && planner_commercialPlanPerc?.touched"></textarea>
    
            </div>
    
            <div class="row pt-4 pb-2 ">
              <div class="col-md-4 ">
                <div>
                  <label for="planner_prominentClients" class="form-label">7. Mention the names of your clients if they happen to be prominent people or celebrities. If you don’t want to show this, please leave this blank. </label>
                    <textarea class="form-control" id="planner_prominentClients" maxlength="2000" rows="4" formControlName="planner_prominentClients" (blur)="onBlur('planner_prominentClients')" [class.is-invalid]="planner_prominentClients?.invalid && planner_prominentClients?.touched"></textarea>
                </div>
              </div>
    
            </div>
            
    
    
        </div>    
    
        </div>
    
        <div *ngIf="isPhotographer">
    
          <div class="row pb-2 ">
    
            <div class="col-md-3">
              
              <div class="row"> 
                    <label for="ph_mostBookedPackageValue" class="form-label">1. What is the value of your most booked package? </label>
                    <textarea class="form-control" id="ph_mostBookedPackageValue" maxlength="2000" rows="1" formControlName="ph_mostBookedPackageValue" (blur)="onBlur('ph_mostBookedPackageValue')" [class.is-invalid]="ph_mostBookedPackageValue?.invalid && ph_mostBookedPackageValue?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="ph_mostBookedPackageDays" class="form-label">2. The above package includes services for how many days? </label>          
                <div *ngFor="let option of ph_daysOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="ph_mostBookedPackageDays" [value]="option" (blur)="onBlur('ph_mostBookedPackageDays')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="ph_mostBookedPackageServices" class="form-label">3. The above package includes which all services? </label>          
                <div *ngFor="let option of ph_packageServices; let i = index">
                      <input type="radio" [id]="option" formControlName="ph_mostBookedPackageServices" [value]="option" (blur)="onBlur('ph_mostBookedPackageServices')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
            </div>
    
            <div class="col-md-1"></div>
    
            <div class="col-md-4">
              
              
              <div class="row "> 
                <label for="ph_cancellationPolicyUser" class="form-label">4. What is your cancellation policy (if user cancels)? </label>          
                <div *ngFor="let option of ph_cancellationUserOptions; let i = index">
                      <input type="radio" [id]="option+'clu'" formControlName="ph_cancellationPolicyUser" [value]="option" (blur)="onBlur('ph_cancellationPolicyUser')">
                      <label [for]="option+'clu'">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4 "> 
                <label for="ph_cancellationPolicySelf" class="form-label">5. What is your cancellation policy (if you initiate cancellation)? </label>          
                <div *ngFor="let option of ph_cancellationSelfOptions; let i = index">
                      <input type="radio" [id]="option+'cls'" formControlName="ph_cancellationPolicySelf" [value]="option" (blur)="onBlur('ph_cancellationPolicySelf')">
                      <label [for]="option+'cls'">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="ph_cancellationPolicyTermsnConditions" class="form-label">6. What are the terms and conditions of your cancellation policy? Please describe in detail - e.g no refunds within a month of the wedding day or 50% amount refundable </label>
                <textarea class="form-control" id="ph_cancellationPolicyTermsnConditions" maxlength="2000" rows="4" formControlName="ph_cancellationPolicyTermsnConditions" (blur)="onBlur('ph_cancellationPolicyTermsnConditions')" [class.is-invalid]="ph_cancellationPolicyTermsnConditions?.invalid && ph_cancellationPolicyTermsnConditions?.touched"></textarea>
              </div>
    
    
            </div>
    
            <div class="col-md-1"> </div>
    
            <div class="col-md-3 ">
              
              <div class="row "> 
                <label for="ph_photographyDescription" class="form-label">7. Describe your photography in 3 words (e.g fun,vibrant and natural) </label>
                <textarea class="form-control" id="ph_photographyDescription" maxlength="2000" rows="3" formControlName="ph_photographyDescription" (blur)="onBlur('ph_photographyDescription')" [class.is-invalid]="ph_photographyDescription?.invalid && ph_photographyDescription?.touched"></textarea>
              </div>
    
              <div class="row pt-4 ">         
                <label for="ph_citiesNumber" class="form-label">8. How many cities have you covered weddings in till date? </label>
                <textarea class="form-control" id="ph_citiesNumber" maxlength="2000" rows="3" formControlName="ph_citiesNumber" (blur)="onBlur('ph_citiesNumber')" [class.is-invalid]="ph_citiesNumber?.invalid && ph_citiesNumber?.touched"></textarea>
    
              </div>
    
              <div class="row pt-4"> 
                <label for="ph_loveBecause" class="form-label">9. We love wedding photography because? </label>
                <textarea class="form-control" id="ph_loveBecause" maxlength="2000" rows="4" formControlName="ph_loveBecause" (blur)="onBlur('ph_loveBecause')" [class.is-invalid]="ph_loveBecause?.invalid && ph_loveBecause?.touched"></textarea>
              </div>
    
    
            </div>
            
    
    
          </div>
    
          <hr>
    
          <div class="row pt-2">
    
            <div class="col-md-4">
    
                <div class="row"> 
                    <label for="ph_smallEventprice" class="form-label">10. Price for covering a small event like an engagement or roka (Assume under 50 pax and 4 hrs of shoot photo and video) </label>
                    <textarea class="form-control" id="ph_smallEventprice" maxlength="2000" rows="1" formControlName="ph_smallEventprice" (blur)="onBlur('ph_smallEventprice')" [class.is-invalid]="ph_smallEventprice?.invalid && ph_smallEventprice?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                    <label for="ph_advanceBookingWeeks" class="form-label">11. How many weeks in advance should a booking be made? </label>
                    <textarea class="form-control" id="ph_advanceBookingWeeks" maxlength="2000" rows="1" formControlName="ph_advanceBookingWeeks" (blur)="onBlur('ph_advanceBookingWeeks')" [class.is-invalid]="ph_advanceBookingWeeks?.invalid && ph_advanceBookingWeeks?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                    <label for="ph_startYear" class="form-label">12. Which year did you start shooting weddings? </label>
                    <textarea class="form-control" id="ph_startYear" maxlength="2000" rows="1" formControlName="ph_startYear" (blur)="onBlur('ph_startYear')" [class.is-invalid]="ph_startYear?.invalid && ph_startYear?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                      <label for="ph_weeksToDeliver" class="form-label">13. How many weeks do you take to deliver the photos (Please respond such as 6 weeks, 7 weeks etc.) </label>
                      <textarea class="form-control" id="ph_weeksToDeliver" maxlength="2000" rows="1" formControlName="ph_weeksToDeliver" (blur)="onBlur('ph_weeksToDeliver')" [class.is-invalid]="ph_weeksToDeliver?.invalid &&ph_weeksToDeliver?.touched"></textarea>
                </div>
    
    
            </div>
    
            <div class="col-md-1"></div>
    
            <div class="col-md-3">
    
              <div class="row">                  
                <label for="ph_services" class="form-label">14. Which services do you offer? </label>  
                
                <!--
                <div *ngFor="let option of ph_servicesList; let i = index">
                    <input type="radio" [id]="option+'svc'" formControlName="ph_services" [value]="option" (blur)="onBlur('ph_services')">
                    <label [for]="option+'svc'">&nbsp;{{ option }}</label>
                </div>
                -->
    
                <div *ngFor="let option of ph_servicesList; let i = index">
                  <input type="checkbox" [id]="'option' + i" [value]="option" (change)="onPhCheckboxChange($event)">
                  <label [for]="'option' + i">&nbsp;{{ option}}</label>
                </div>
    
              </div>
    
              <div class="row pt-4">                  
                <label for="ph_specialityType" class="form-label">15. What is your speciality? </label>          
                <div *ngFor="let option of ph_specialityList; let i = index">
                    <input type="radio" [id]="option+'spc'" formControlName="ph_specialityType" [value]="option" (blur)="onBlur('ph_specialityType')">
                    <label [for]="option+'spc'">&nbsp;{{ option }}</label>
                </div>
              </div>
    
            </div>
    
            <div class="col-md-1"></div>
    
            <div class="col-md-3">
    
              <div class="row">                  
                <label for="ph_specialityNumber" class="form-label">16. What is your preference? </label>          
                <div *ngFor="let option of ph_specialitynum; let i = index">
                    <input type="radio" [id]="option+'spcn'" formControlName="ph_specialityNumber" [value]="option" (blur)="onBlur('ph_specialityNumber')">
                    <label [for]="option+'spcn'">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4">                  
                <label for="ph_paymentTerms" class="form-label">17. What are your payment terms? </label>          
                <div *ngFor="let option of ph_paymentTermsList; let i = index">
                    <input type="radio" [id]="option+'pyt'" formControlName="ph_paymentTerms" [value]="option" (blur)="onBlur('ph_paymentTerms')">
                    <label [for]="option+'pyt'">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4">                  
                <label for="ph_travelLodgingCosts" class="form-label">18. Who bears cost of travel and lodging when travelling to a different city? </label>          
                <div *ngFor="let option of ph_travelLodgingCostsList; let i = index">
                    <input type="radio" [id]="option+'pyt'" formControlName="ph_travelLodgingCosts" [value]="option" (blur)="onBlur('ph_travelLodgingCosts')">
                    <label [for]="option+'pyt'">&nbsp;{{ option }}</label>
                </div>
              </div>
    
            </div>
    
    
    
          </div>
    
          <hr>
    
          <div class="row pb-2 ">
           
            <div class="col-md-5">
    
              <div class="row"> 
                <label for="ph_candidTraditionalOneday" class="form-label">19. Price for candid photography and traditional photography one day package for wedding day (Assume 300 pax) </label>
                <textarea class="form-control" id="ph_candidTraditionalOneday" maxlength="2000" rows="1" formControlName="ph_candidTraditionalOneday" (blur)="onBlur('ph_candidTraditionalOneday')" [class.is-invalid]="ph_candidTraditionalOneday?.invalid && ph_candidTraditionalOneday?.touched"></textarea>
            </div>
    
            </div>
    
            <div class="col-md-1"></div>
    
            <div class="col-md-5">
    
              <div class="row"> 
                <label for="ph_candidTraditionalCinematic" class="form-label">20. Price for candid photography ,traditional photography and cinematic video one day package for wedding day (Assume 300 pax) </label>
                <textarea class="form-control" id="ph_candidTraditionalCinematic" maxlength="2000" rows="1" formControlName="ph_candidTraditionalCinematic" (blur)="onBlur('ph_candidTraditionalCinematic')" [class.is-invalid]="ph_candidTraditionalCinematic?.invalid && ph_candidTraditionalCinematic?.touched"></textarea>
            </div>
    
            </div>
    
          </div>
    
        </div>
    
        <div *ngIf="isDecorator">
    
          <div class="row pb-2 ">
    
            <div class="col-md-3">
              
              <div class="row"> 
                    <label for="dr_enlistedHotels" class="form-label">1. Are you enlisted on the panel of any banquets/hotels? Please mention a few </label>
                    <textarea class="form-control" id="dr_enlistedHotels" maxlength="2000" rows="3" formControlName="dr_enlistedHotels" (blur)="onBlur('dr_enlistedHotels')" [class.is-invalid]="dr_enlistedHotels?.invalid && dr_enlistedHotels?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_serviceTypes" class="form-label">2. What are the main decor services provided by you? </label>          
                <div *ngFor="let option of dr_decorServiceTypeList; let i = index">
                      <input type="radio" [id]="option" formControlName="dr_serviceTypes" [value]="option" (blur)="onBlur('dr_serviceTypes')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_indoorPriceRange" class="form-label">3. For an indoor banquet function, of approx 250 pax, what is the price range you are comfortable doing? Please specify min and max values </label>
                <textarea class="form-control" id="dr_indoorPriceRange" maxlength="2000" rows="1" formControlName="dr_indoorPriceRange" (blur)="onBlur('dr_indoorPriceRange')" [class.is-invalid]="dr_indoorPriceRange?.invalid && dr_indoorPriceRange?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_outdoorPriceRange" class="form-label">4. For an outdoor function, what would the price range be? Please specify min and max values</label>
                <textarea class="form-control" id="dr_outdoorPriceRange" maxlength="2000" rows="1" formControlName="dr_outdoorPriceRange" (blur)="onBlur('dr_outdoorPriceRange')" [class.is-invalid]="dr_outdoorPriceRange?.invalid && dr_outdoorPriceRange?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_celebrityWeddings" class="form-label">5. Please mention any celebrity weddings you may have done </label>
                <textarea class="form-control" id="dr_celebrityWeddings" maxlength="2000" rows="3" formControlName="dr_celebrityWeddings" (blur)="onBlur('dr_celebrityWeddings')" [class.is-invalid]="dr_celebrityWeddings?.invalid && dr_celebrityWeddings?.touched"></textarea>
              </div>
    
            </div>
    
            <div class="col-md-1"></div>
    
            <div class="col-md-4">
    
              <div class="row"> 
                <label for="dr_USP" class="form-label">6. What is your USP? </label>
                <textarea class="form-control" id="dr_USP" maxlength="2000" rows="3" formControlName="dr_USP" (blur)="onBlur('dr_USP')" [class.is-invalid]="dr_USP?.invalid && dr_USP?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_mostExperiencedIn" class="form-label">7. What are you most experienced in? </label>          
                <div *ngFor="let option of dr_mostExperiencedList; let i = index">
                      <input type="radio" [id]="option" formControlName="dr_mostExperiencedIn" [value]="option" (blur)="onBlur('dr_mostExperiencedIn')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_cancellationPolicyUser" class="form-label">8. What is your cancellation policy on user initiated cancellations? </label>          
                <div *ngFor="let option of dr_cancellationUserOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="dr_cancellationPolicyUser" [value]="option" (blur)="onBlur('dr_cancellationPolicyUser')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_cancellationPolicySelf" class="form-label">9. What is your cancellation policy on vendor initiated cancellations? </label>          
                <div *ngFor="let option of dr_cancellationSelfOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="dr_cancellationPolicySelf" [value]="option" (blur)="onBlur('dr_cancellationPolicySelf')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_cancelationTermsnConsitions" class="form-label">10. What are the terms amd conditions of your cancellation policy? Please describe in detail -e.g - no refund within a month of the wedding day or 50% amount refundable </label>
                <textarea class="form-control" id="dr_cancelationTermsnConsitions" maxlength="2000" rows="3" formControlName="dr_cancelationTermsnConsitions" (blur)="onBlur('dr_cancelationTermsnConsitions')" [class.is-invalid]="dr_cancelationTermsnConsitions?.invalid && dr_cancelationTermsnConsitions?.touched"></textarea>
              </div>
    
            </div>
    
            <div class="col-md-1"></div>
            
            <div class="col-md-3">
    
              <div class="row"> 
                <label for="dr_startYear" class="form-label">11. Which year did you start your company? </label>
                <textarea class="form-control" id="dr_startYear" maxlength="2000" rows="1" formControlName="dr_startYear" (blur)="onBlur('dr_startYear')" [class.is-invalid]="dr_startYear?.invalid && dr_startYear?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_advanceBookingWeeks" class="form-label">12. How many weeks in advance should a booking be made to get a slot? </label>
                <textarea class="form-control" id="dr_advanceBookingWeeks" maxlength="2000" rows="1" formControlName="dr_advanceBookingWeeks" (blur)="onBlur('dr_advanceBookingWeeks')" [class.is-invalid]="dr_advanceBookingWeeks?.invalid && dr_advanceBookingWeeks?.touched"></textarea>
              </div>
    
              
              <div class="row pt-4"> 
                <label for="dr_indoorStartingPrice" class="form-label">13. What is the starting price for indoor decor? </label>
                <textarea class="form-control" id="dr_indoorStartingPrice" maxlength="2000" rows="1" formControlName="dr_indoorStartingPrice" (blur)="onBlur('dr_indoorStartingPrice')" [class.is-invalid]="dr_indoorStartingPrice?.invalid && dr_indoorStartingPrice?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="dr_homefunctionStartingPrice" class="form-label">14. What is the starting package for home function decor?(e.g flowers and lights for haldi, house decoration,dinner party etc) </label>
                <textarea class="form-control" id="dr_homefunctionStartingPrice" maxlength="2000" rows="1" formControlName="dr_homefunctionStartingPrice" (blur)="onBlur('dr_homefunctionStartingPrice')" [class.is-invalid]="dr_homefunctionStartingPrice?.invalid && dr_homefunctionStartingPrice?.touched"></textarea>
              </div>
    
            </div>
    
          </div>
        </div>
        
        <div *ngIf="isCharter">
    
          <div class="row pb-2 ">
    
            <div class="col-md-3">
              
              <div class="row"> 
                <label for="ch_primaryServiceType" class="form-label">1. What is your primary service? </label>          
                <div *ngFor="let option of ch_serviceTypeOptions; let i = index">
                      <input type="radio" [id]="option" formControlName="ch_primaryServiceType" [value]="option" (blur)="onBlur('ch_primaryServiceType')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="ch_rentalCostYesNo" class="form-label">2. Do you have rental costs? </label>          
                <div *ngFor="let option of ch_rentalCostOptions; let i = index">
                      <input type="radio" [id]="option+'ch1'" formControlName="ch_rentalCostYesNo" [value]="option" (blur)="onBlur('ch_rentalCostYesNo')">
                      <label [for]="option+'ch1'">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="ch_startYear" class="form-label">3. In which year did you start operations? </label>
                <textarea class="form-control" id="ch_startYear" maxlength="2000" rows="1" formControlName="ch_startYear" (blur)="onBlur('ch_startYear')" [class.is-invalid]="ch_startYear?.invalid && ch_startYear?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="ch_aircraftTypes" class="form-label">4. Which all aircraft types do you operate? </label>
                <textarea class="form-control" id="ch_aircraftTypes" maxlength="2000" rows="3" formControlName="ch_aircraftTypes" (blur)="onBlur('ch_aircraftTypes')" [class.is-invalid]="ch_aircraftTypes?.invalid && ch_aircraftTypes?.touched"></textarea>
              </div>
    
            </div>
    
            <div class="col-md-1"></div>
         
            <div class="col-md-4">
        
                <div class="row "> 
                  <label for="ch_startingPrice" class="form-label">5. What is the starting price for renting? </label>
                  <textarea class="form-control" id="ch_startingPrice" maxlength="2000" rows="1" formControlName="ch_startingPrice" (blur)="onBlur('ch_startingPrice')" [class.is-invalid]="ch_startingPrice?.invalid &&ch_startingPrice?.touched"></textarea>
                </div>
    
                <div class="row pt-4 "> 
                  <label for="ch_startingPriceBookingnTechnician" class="form-label">6. What is the starting price for booking alongwith technician? </label>
                  <textarea class="form-control" id="ch_startingPriceBookingnTechnician" maxlength="2000" rows="1" formControlName="ch_startingPriceBookingnTechnician" (blur)="onBlur('ch_startingPrice')" [class.is-invalid]="ch_startingPriceBookingnTechnician?.invalid &&ch_startingPriceBookingnTechnician?.touched"></textarea>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ch_partialRoundsYesNo" class="form-label">7. Does your services include partial rounds? </label>          
                  <div *ngFor="let option of ch_partialRoundsOptions; let i = index">
                        <input type="radio" [id]="option+'ch2'" formControlName="ch_partialRoundsYesNo" [value]="option" (blur)="onBlur('ch_partialRoundsYesNo')">
                        <label [for]="option+'ch2'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
                <div class="row pt-4"> 
                  <label for="ch_alcoholPolicy" class="form-label">8. What is your alcohol policy? </label>          
                  <div *ngFor="let option of ch_alcoholPolicyOptions; let i = index">
                        <input type="radio" [id]="option+'ch3'" formControlName="ch_alcoholPolicy" [value]="option" (blur)="onBlur('ch_alcoholPolicy')">
                        <label [for]="option+'ch3'">&nbsp;{{ option }}</label>
                  </div>
                </div>
    
          
            </div>
    
            <div class="col-md-1"></div>
    
            <div class="col-md-3">
    
              <div class="row "> 
                <label for="ch_recognitions" class="form-label">9. What recognitions does your company hold? </label>
                <textarea class="form-control" id="ch_recognitions" maxlength="2000" rows="3" formControlName="ch_recognitions" (blur)="onBlur('ch_recognitions')" [class.is-invalid]="ch_recognitions?.invalid &&ch_recognitions?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="ch_registeredYesNo" class="form-label">10. Are your services registered with the local authorities and government? </label>          
                <div *ngFor="let option of ch_registeredOptions; let i = index">
                      <input type="radio" [id]="option+'ch4'" formControlName="ch_registeredYesNo" [value]="option" (blur)="onBlur('ch_registeredYesNo')">
                      <label [for]="option+'ch4'">&nbsp;{{ option }}</label>
                </div>
              </div>
    
            </div>
    
          </div>
    
           
    
        </div>
    
        <div *ngIf="isMakeupartist">
    
          <div class="row pb-2 ">
    
            <div class="col-md-3">
    
              <div class="row"> 
                <label for="mk_hotelspanels" class="form-label">1. Are you listed on the panels of any hotels or venues? Please mention a few </label>
                <textarea class="form-control" id="mk_hotelspanels" maxlength="2000" rows="3" formControlName="mk_hotelspanels" (blur)="onBlur('mk_hotelspanels')" [class.is-invalid]="mk_hotelspanels?.invalid && mk_hotelspanels?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_usp" class="form-label">2. What is your USP? </label>
                <textarea class="form-control" id="mk_usp" maxlength="2000" rows="3" formControlName="mk_usp" (blur)="onBlur('mk_usp')" [class.is-invalid]="mk_usp?.invalid && mk_usp?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_startyear" class="form-label">3. In which year did you start practicing makeup professionally? </label>
                <textarea class="form-control" id="mk_startyear" maxlength="45" rows="1" formControlName="mk_startyear" (blur)="onBlur('mk_startyear')" [class.is-invalid]="mk_startyear?.invalid && mk_startyear?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_cities" class="form-label">4. In how many cities have you done wedding makeups till now? </label>
                <textarea class="form-control" id="mk_cities" maxlength="45" rows="1" formControlName="mk_cities" (blur)="onBlur('mk_cities')" [class.is-invalid]="mk_cities?.invalid && mk_cities?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_prominentclients" class="form-label">5. Please mention any prominent clients you have worked with </label>
                <textarea class="form-control" id="mk_prominentclients" maxlength="2000" rows="3" formControlName="mk_prominentclients" (blur)="onBlur('mk_prominentclients')" [class.is-invalid]="mk_prominentclients?.invalid && mk_prominentclients?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_startingrate" class="form-label">6. What is your starting rate for a wedding engagement? </label>
                <textarea class="form-control" id="mk_startingrate" maxlength="45" rows="1" formControlName="mk_startingrate" (blur)="onBlur('mk_startingrate')" [class.is-invalid]="mk_startingrate?.invalid && mk_startingrate?.touched"></textarea>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_addguests" class="form-label">7. How many additional guests apart from bride and groom are covered in the above rate? </label>
                <textarea class="form-control" id="mk_addguests" maxlength="45" rows="1" formControlName="mk_addguests" (blur)="onBlur('mk_addguests')" [class.is-invalid]="mk_addguests?.invalid && mk_addguests?.touched"></textarea>
              </div>
              
             
    
            </div>
    
            <div class="col-md-1"></div>
         
            <div class="col-md-3">
    
                          
              <div class="row "> 
                <label for="mk_costpolicy" class="form-label">8. Who bears cost of travel and lodging when travelling to a different city? </label>          
                <div *ngFor="let option of mk_travelLodgingCostsList; let i = index">
                      <input type="radio" [id]="option" formControlName="mk_costpolicy" [value]="option" (blur)="onBlur('mk_costpolicy')">
                      <label [for]="option">&nbsp;{{ option }}</label>
                </div>
              </div>
    
              <div class="row pt-4"> 
                <label for="mk_daysadvance" class="form-label">9. How many weeks in advance should a booking be done to get a slot? </label>
                <textarea class="form-control" id="mk_daysadvance" maxlength="45" rows="1" formControlName="mk_daysadvance" (blur)="onBlur('mk_daysadvance')" [class.is-invalid]="mk_daysadvance?.invalid && mk_daysadvance?.touched"></textarea>
              </div>
            
    
            <div class="row pt-4"> 
              <label for="mk_cancellationuser" class="form-label">10. What is your cancellation policy (if customer initiates cancellation)? </label>          
              <div *ngFor="let option of mk_cancellationUserOptions; let i = index">
                    <input type="radio" [id]="option" formControlName="mk_cancellationuser" [value]="option" (blur)="onBlur('mk_cancellationuser')">
                    <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="row pt-4"> 
              <label for="mk_cancellationvendor" class="form-label">11. What is your cancellation policy (if vendor initiates cancellation)? </label>          
              <div *ngFor="let option of mk_cancellationSelfOptions; let i = index">
                    <input type="radio" [id]="option+i" formControlName="mk_cancellationvendor" [value]="option" (blur)="onBlur('mk_cancellationvendor')">
                    <label [for]="option+i">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="row pt-4"> 
              <label class="form-label">12. Which of the following do you offer? </label>
              <div *ngFor="let option of mk_servicesofferedOptions; let i = index">
                <input type="checkbox" [id]="'option' + i" [value]="option" (change)="onmkservicesCheckboxChange($event)">
                <label [for]="'option' + i">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="row pt-4"> 
              <label for="mk_products" class="form-label">13. Which Products do you use for bridal makeup? </label>
              <textarea class="form-control" id="mk_products" maxlength="45" rows="1" formControlName="mk_products" (blur)="onBlur('mk_products')" [class.is-invalid]="mk_products?.invalid && mk_products?.touched"></textarea>
            </div>
    
            <div class="row pt-4"> 
              <label for="mk_traveltovenue" class="form-label">14. Do you travel to the venue? </label>          
              <div *ngFor="let option of mk_travelOptions; let i = index">
                    <input type="radio" [id]="option" formControlName="mk_traveltovenue" [value]="option" (blur)="onBlur('mk_traveltovenue')">
                    <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
              
    
            </div>
    
        
            <div class="col-md-1"></div>
    
            <div class="col-md-3">
    
              <div class="row"> 
                <label for=" mk_travelprice" class="form-label">15. If you travel outside of your hometown for bridal makeup, how much do
                  you charge for one event? </label>
                <textarea class="form-control" id="mk_travelprice" maxlength="45" rows="1" formControlName="mk_travelprice" (blur)="onBlur('mk_travelprice')" [class.is-invalid]="mk_travelprice?.invalid && mk_travelprice?.touched"></textarea>
              </div>
            
    
            <div class="row pt-4"> 
              <label class="form-label">16.  What does the bridal makeup price include? </label>          
              <div *ngFor="let option of mk_bridalmakeupincludeoptions; let i = index">
                <input type="checkbox" [id]="'option' + i*10" [value]="option" (change)="onmkpriceinclCheckboxChange($event)">
                <label [for]="'option' + i*10">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="row pt-4"> 
              <label for="mk_airbrushservice" class="form-label">17. Do you offer Airbrush Services? </label>          
              <div *ngFor="let option of mk_airbrushservicesOptions; let i = index">
                    <input type="radio" [id]="option" formControlName="mk_airbrushservice" [value]="option" (blur)="onBlur('mk_airbrushservice')">
                    <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            
            <div class="row pt-4"> 
              <label for="mk_trialspolicy" class="form-label">18. Explain your trials policy </label>          
              <div *ngFor="let option of mk_trialsPolicyOptions; let i = index">
                    <input type="radio" [id]="option" formControlName="mk_trialspolicy" [value]="option" (blur)="onBlur('mk_trialspolicy')">
                    <label [for]="option">&nbsp;{{ option }}</label>
              </div>
            </div>
    
            <div class="row pt-4"> 
              <label for="mk_awards" class="form-label">19. Please mention if you have received any awards </label>          
              <textarea class="form-control" id="mk_awards" maxlength="1000" rows="3" formControlName="mk_awards" (blur)="onBlur('mk_awards')" [class.is-invalid]="mk_awards?.invalid && mk_awards?.touched"></textarea>
            </div>
    
          </div>
    
          </div>
    
        </div>
    
      
    
        <div *ngIf="isCaterer">
          <p>Caterer Details Section</p>
        </div>
    
      </div>
    
    
           
    
        <!----------------------------Buttons--------------------------->
      
        <div class="row m-3 p-3 "> 
        
          <div class="col-12">
            <button class="border button-style-3 px-5 py-2" type="submit" >Register</button>
          </div>
    
          <div class="col-12 pt-2">
            <button class="border button-style-3 px-5 py-2" type="button"  (click)="resetForm()">Clear form</button>
          </div>
    
         
        </div>
    
    
      </form>
    
    </div>
    
    
    
    <app-footersection></app-footersection>
      
</div></div>
