// sign-in.component.ts
import { SocialLoginModule, SocialUser } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { AuthServiceService } from '../../../services/auth-service.service';
import { EnvironmentDataServiceService } from '../../../services/environment-data-service.service';
import { UserServiceService } from '../../../services/user-service.service';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';

declare const google: any;

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    RouterModule,
    CommonModule,
    SocialLoginModule
  ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],

})
export class SignInComponent implements OnInit {
  username: string = '';
  password: string = '';
  usertype: string = '';
  errorMessage: string = "";
  showPassword: boolean = false;
  loggedIn: boolean = false;
  usertypeList: string[] = ['Customer', 'Vendor', 'Admin'];
  user: SocialUser | null = null;
  isLoading: boolean = false;

  @ViewChild(AlertDialogComponent) alertDialog: AlertDialogComponent = new AlertDialogComponent();

  constructor(
    private userService: UserServiceService,
    public dialogRef: MatDialogRef<SignInComponent>,
    private environmentDataService: EnvironmentDataServiceService,
    private router: Router,
    private authService: AuthServiceService
  ) {}

  ngOnInit(): void {
    if (this.usertypeList.length > 0) {
      this.usertype = this.usertypeList[0];
    }
    this.loadGooglePlatformLibrary();
  }

  loadGooglePlatformLibrary(): void {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    
    script.onload = () => {
      this.renderGoogleSignInButton();
    };
  }

  renderGoogleSignInButton(): void { 
    const buttonElement = document.getElementById('google-signin-button');
    if (buttonElement) {
      google.accounts.id.initialize({
        client_id: '816661634576-her5f91r1ljasp53d4rd6tafr27er2b7.apps.googleusercontent.com',
        callback: this.onSignIn.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      
      google.accounts.id.renderButton(buttonElement, { 
        theme: 'outline', 
        size: 'large',
        type: 'standard',
        shape: 'rectangular',
        text: 'Sign in with Google',
        logo_alignment: 'left',
        width:'275px',
        height:'35px'
      });
    } else {
      console.error('Google Sign-In button element not found');
    }
  }

  onSignIn(response: any): void {
    this.isLoading = true;
    try {
      const credential = response.credential;
      const payload = this.decodeJwtResponse(credential);
      
      const email = payload.email;
      const displayName = payload.name;
      
      this.signInWithGoogle(credential, email, displayName);
    } catch (error) {
      console.error('Error processing Google Sign-In:', error);
      this.errorMessage = 'Failed to process Google Sign-In. Please try again.';
      this.isLoading = false;
    }
  }

  private decodeJwtResponse(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding JWT:', error);
      throw new Error('Failed to decode Google Sign-In token');
    }
  }

  signInWithGoogle(idToken: string, email: string, displayName: string) {
    this.userService.signInWithGoogle(displayName,email, 'Customer').subscribe({
      next: (response) => {
        if (response.message === 'Login Successful') {
          this.errorMessage = "";
          this.environmentDataService.changeUserData(response.data);
          this.loggedIn = true;
          this.dialogRef.close();

          this.router.navigate(['/home']).then(() => 
           {
             window.location.reload();
             //this.cdr.detectChanges();
           });

          //this.router.navigate(['/home']);

        } else {
          this.errorMessage = 'Login Failed';
          this.clearForm();
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error logging in:', error);
        this.errorMessage = 'An error occurred. Please try again later.';
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  // alert('name: '+displayName+' ,email: '+email)
 }

 signInWithFacebook() 
 {
   alert('Goint to fb sign in');

   this.isLoading = true;
   this.authService.signInWithFacebook().then(user => 
   {
     //this.handleSocialUser(user);
   }).catch(error => {
     console.error('Facebook sign-in failed:', error);
     this.errorMessage = 'Facebook sign-in failed. Please try again.';
     this.isLoading = false;
   });
 }

 
  private handleSocialUser(user: SocialUser) {
    this.user = user;

    if (!this.user || !this.user.name || !this.user.email) {
      this.errorMessage = 'Required user information is missing.';
      this.isLoading = false;
      return;
    }

    this.userService.signInWithGoogle(this.user.email, user.id, 'Customer').subscribe({
      next: (response) => {
        if (response.message === 'Login Successful') {
          this.errorMessage = "";
          this.environmentDataService.changeUserData(response.data);
          this.loggedIn = true;
          this.router.navigate(['/home']);
          this.dialogRef.close();
        } else {
          this.errorMessage = 'Login Failed';
          this.clearForm();
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error logging in:', error);
        this.errorMessage = 'An error occurred. Please try again later.';
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onSubmit() {
    if (this.username.length <= 0 || this.password.length <= 0) {
      this.errorMessage = "Please enter username and password";
      return;
    }

    this.isLoading = true;
    this.userService.login(this.username, this.password, this.usertype).subscribe({
      next: (response) => {
        if (response.message === 'Login Successful') {
          this.errorMessage = "";
          this.environmentDataService.changeUserData(response.data);
          this.loggedIn = true;
          this.dialogRef.close();
        } else {
          this.errorMessage = 'Invalid Credentials';
          this.clearForm();
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error('Error logging in:', error);
        this.errorMessage = 'An error occurred. Please try again later.';
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  clearForm() {
    this.username = "";
    this.password = "";
  }

  closeDialog() {
    this.dialogRef.close();
  }

  registerUser() {
    this.router.navigate(['/register']);
    this.dialogRef.close();
  }

  forgotPassword() { 
    this.router.navigate(['/forgot-password']);  
    this.dialogRef.close();  
  } 
  
  togglePasswordVisibility() {  
    this.showPassword = !this.showPassword;  
  }  
}